import { MenuProps, Tooltip } from 'antd';

import { PostProcessRuleLabel } from 'components/Template/PostProcessRules/PostProcessRuleLabel';
import { useHighlight } from 'components/Template/Selection/Highlight/helpers';
import { useTemplate } from 'components/Template/useTemplate.hook';
import {
    Badge,
    Divider,
    ItemMenu,
    SelectionButton,
    SingleListItemTitle,
    ViewIcon,
} from 'components/atomic';
import { clearGeneralFieldTitleAction, clearValueAction } from 'state/actions';
import { GeneralFieldsItem } from 'state/types';

export const MarkGeneralFieldsItem = ({ field, isActive }: Props) => {
    const { dispatch, selectionInfo: selectionType, id, template } = useTemplate();
    const { text } = selectionType || {};
    const showSecondRow = isActive && !!field.value;
    const extractedValue = template?.extractResult.order_header[field.fieldId];

    const handleClearTitle = () => {
        dispatch(clearGeneralFieldTitleAction(field.id));
    };

    const handleClearValue = () => {
        dispatch(clearValueAction(field.id));
    };

    const items: MenuProps['items'] = [
        {
            key: 'clearTitle',
            label: <div onClick={handleClearTitle}>Clear title</div>,
        },
        {
            key: 'clearValue',
            label: <div onClick={handleClearValue}>Clear value</div>,
        },
        {
            key: 'post-process-rules',
            label: (
                <PostProcessRuleLabel templateId={id} fieldId={field.fieldId} label={field.label} />
            ),
        },
    ];

    const titleHighlight = useHighlight(
        {
            value: [field.titleId],
            type: 'box',
        },
        field.pageNum
    );

    const valueHighligh = useHighlight(
        {
            value: field.contentIds,
            type: 'box',
        },
        field.pageNum
    );

    return (
        <div className="single-item-multiline">
            <div className="single-item-multiline-row first">
                <SingleListItemTitle>
                    {field.label}
                    {field.required ? ' *' : ''}
                </SingleListItemTitle>
                <div className="item-right">
                    {field.titleId !== null ? (
                        <Badge text="Title" {...titleHighlight} />
                    ) : (
                        <SelectionButton text="Title" active={isActive && text === 'title'} />
                    )}
                    {field.contentIds?.length ? (
                        <Badge text="Value" {...valueHighligh} />
                    ) : (
                        <SelectionButton text="Value" active={isActive && text === 'value'} />
                    )}
                    <div className="view-field-value">
                        {!!field.value && (
                            <Tooltip title={field.value}>
                                <ViewIcon />
                            </Tooltip>
                        )}
                    </div>
                    <ItemMenu items={items} />
                </div>
            </div>
            {showSecondRow && (
                <>
                    <Divider />
                    <div className="single-item-multiline-row second">
                        <span className="single-item-multiline-description">Scanned value:</span>
                        <span className="scanned-value">{field.value}</span>
                    </div>
                </>
            )}
            {showSecondRow && !!extractedValue && extractedValue !== field.value && (
                <div className="single-item-multiline-row second">
                    <span className="single-item-multiline-description">Extracted value:</span>
                    <span className="scanned-value">{extractedValue}</span>
                </div>
            )}
        </div>
    );
};

interface Props {
    field: GeneralFieldsItem;
    isActive: boolean;
}
