import { Checkbox, Form, Input, Select } from 'antd';

import { Divider, Loading, ToggleButton } from 'components/atomic';
import { useAppDispatch } from 'hooks/redux.hooks';
import { PostProcessRule, PostProcessRuleType } from './types';
import { deletePostProcessRules, setPostProcessRules } from 'services';
import { getTemplateDataAction } from 'state/actions';
import { getListenerAction, useData } from 'hooks/data.hooks';
import { FormList } from 'components/FormList';
import { getApiPath, getFieldById, getFieldsOptions, getItemTitle } from './helpers';
import { useTemplate } from '../useTemplate.hook';
import { useEffect, useState } from 'react';

export const PostProcessRuleForm = ({ fieldId, templateId, isTable }: Props) => {
    const { template } = useTemplate();
    const { data, loading } = useData<PostProcessRule[]>(getApiPath(templateId));
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<PostProcessRule>();
    const type = Form.useWatch('type', form);
    const fieldsOptions = getFieldsOptions(template, isTable);
    const [selectedField, setSelectedFiled] = useState(getFieldById(template, fieldId));
    const rules =
        data?.filter(rule => rule.fieldId === selectedField?.fieldId || rule.applyToAllFields) ||
        [];
    const initialValues: Partial<PostProcessRule> = {
        applyToAllFields: false,
        applyToAllTemplates: false,
        type: 'replace',
        oldValue: '',
        newValue: '',
        useRegex: false,
    };

    const handleSave = async (values: PostProcessRule, id?: number) => {
        const index = rules.findIndex(item => item.id === id);
        const newRule: PostProcessRule = {
            ...values,
            fieldId: selectedField?.fieldId || fieldId,
            templateId,
        };

        if (~index) {
            newRule.id = rules[index].id;
            rules[index] = newRule;
        } else {
            rules.unshift(newRule);
        }

        await setPostProcessRules(templateId, rules);

        dispatch(getListenerAction(getApiPath(templateId)));
        dispatch(getTemplateDataAction({ disableRefresh: true }));
    };

    const handleDelete = async (values: PostProcessRule) => {
        await deletePostProcessRules(templateId, [values.id]);
        dispatch(getListenerAction(getApiPath(templateId)));
        dispatch(getTemplateDataAction({ disableRefresh: true }));
    };

    const handleFieldChange = (value: number) => {
        setSelectedFiled(getFieldById(template, value));
        form.setFieldsValue(initialValues);
    };

    useEffect(() => {
        setSelectedFiled(getFieldById(template, fieldId));
    }, [fieldId]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="post-process-rules">
            <div className="post-process-rule-field">
                <span>Field</span>
                <Select
                    options={fieldsOptions}
                    className="post-process-rule-field-name"
                    onChange={handleFieldChange}
                    value={selectedField?.fieldId}
                />
            </div>
            <Divider />
            <FormList
                name="postProcessRules"
                data={rules}
                getItemTitle={item => getItemTitle(selectedField?.label || '', item)}
                onSave={handleSave}
                onDelete={handleDelete}
                initialValues={initialValues}
                form={form}>
                <Form.Item<PostProcessRule>
                    label="Select rule type"
                    name="type"
                    required={false}
                    rules={[{ required: true, message: 'Please select rule type' }]}>
                    <Select options={ruleTypeOptions} placeholder="Select rule type" />
                </Form.Item>
                {type !== 'number' && (
                    <div>
                        <Form.Item<PostProcessRule>
                            label="Before"
                            name="oldValue"
                            required={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please insert value that should be replaced',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item<PostProcessRule>
                            label="After"
                            name="newValue"
                            required={false}
                            rules={[
                                { required: false, message: 'Please insert value to replace' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </div>
                )}
                {type === 'replace' && (
                    <Form.Item<PostProcessRule>
                        name="useRegex"
                        required={false}
                        valuePropName="checked">
                        <ToggleButton label="use Regex" />
                    </Form.Item>
                )}
                {type === 'replace' && (
                    <Form.Item<PostProcessRule>
                        name="applyToAllFields"
                        required={false}
                        valuePropName="checked"
                        className="post-process-rule-checkbox">
                        <Checkbox>Apply to all fields</Checkbox>
                    </Form.Item>
                )}
                <Form.Item<PostProcessRule>
                    name="applyToAllTemplates"
                    required={false}
                    valuePropName="checked"
                    className="post-process-rule-checkbox">
                    <Checkbox>Apply to all templates</Checkbox>
                </Form.Item>
            </FormList>
        </div>
    );
};

const ruleTypeOptions: SelectOptions<PostProcessRuleType> = [
    { label: 'Replace', value: 'replace' },
    { label: 'Date', value: 'date' },
    { label: 'Swap', value: 'swap' },
    { label: 'Number', value: 'number' },
];

interface Props {
    templateId: number;
    fieldId: number;
    fieldName: string;
    isTable: boolean;
}
