import { UserRole, UserStatus } from 'components/Users/types';
import { AdminIcon, EditorIcon, ViewerIcon } from 'components/atomic';

const displayRole: Record<UserRole, string> = {
    ROLE_ADMIN: 'Admin',
    ROLE_EDITOR: 'Editor',
    ROLE_VIEWER: 'Viewer',
    GUEST: '',
};

const iconRole: Record<UserRole, React.ReactNode> = {
    ROLE_ADMIN: <AdminIcon />,
    ROLE_EDITOR: <EditorIcon />,
    ROLE_VIEWER: <ViewerIcon />,
    GUEST: null,
};

const displayStatus: Record<UserStatus, string> = {
    ACTIVE: 'Active',
    NOT_ACTIVE: 'Disabled',
    TERMINATED: 'Terminated',
};

export const getDisplayUserRole = (role?: UserRole) => {
    if (!role) {
        return '';
    }

    const text = displayRole[role];

    return text ? text : role;
};

export const getUserRoleIcon = (role: UserRole) => {
    const icon = iconRole[role];

    return icon ? icon : <></>;
};

export const getDisplayUserStatus = (status: UserStatus) => {
    return displayStatus[status];
};
