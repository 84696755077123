import { apiRoutes } from 'services';
import { PostProcessRule } from './types';
import { TemplateData } from '../types';

export const getApiPath = (templateId: number) =>
    `${apiRoutes.templates}/${templateId}/${apiRoutes.postProcessRules}`;

export const getItemTitle = (fieldName: string, item: PostProcessRule) => {
    if (typeof item.oldValue === 'string' && typeof item.newValue === 'string') {
        return `${item.oldValue} → ${item.newValue || '""'}`;
    }

    return `${fieldName} → ${item.type.replace(/^\w/, s => s.toUpperCase())}`;
};

export const getFieldsOptions = (
    template: TemplateData | null,
    isTable: boolean
): SelectOptions => {
    return (
        template?.fields
            .filter(item => item.isTable === isTable)
            .map(item => ({
                label: item.label,
                value: item.fieldId,
            })) || []
    );
};

export const getFieldById = (template: TemplateData | null, id: number) => {
    return template?.fields.find(item => item.fieldId === id) || null;
};
