import { useDrawer } from 'components/DrawerContainer';
import { PlusButton } from 'components/atomic';
import { ImportForm } from './ImportForm';

export const ImportButton = ({ text }: Props) => {
    const { openDrawer } = useDrawer();

    const handleClick = () => {
        openDrawer('Manual import', <ImportForm />);
    };

    return <PlusButton onClick={handleClick}>{text}</PlusButton>;
};

interface Props {
    text: string;
}
