import classNames from 'classnames';

import { DocumentIcon } from 'components/atomic';

export const DocumentTab = ({ id, name, isActive, onClick }: Props) => {
    const className = classNames('document-tab', isActive && 'active');

    return (
        <div className={className} onClick={onClick}>
            <div className="icon">
                <DocumentIcon />
            </div>
            <div className="text">{name || `Import #${id}`}</div>
        </div>
    );
};

interface Props extends WithId {
    name?: string | null;
    isActive: boolean;
    onClick: () => void;
}
