import { MenuProps } from 'antd';

import { Badge, ItemMenu, SingleListItemTitle } from 'components/atomic';
import { useHighlight } from 'components/Template/Selection/Highlight/helpers';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { clearValueAction } from 'state/actions';
import { CommonStepItem } from 'state/types';

export const MarkHeaderAndFooterItem = ({ title, value, id }: Props) => {
    const { dispatch } = useTemplate();

    const handleClearValue = () => {
        dispatch(clearValueAction(id));
    };

    const items: MenuProps['items'] = [
        {
            key: 'clear',
            label: <div onClick={handleClearValue}>Clear value</div>,
        },
    ];

    const highlight = useHighlight({
        value: [value || 0],
        type: id === 0 ? 'header' : 'footer',
    });

    return (
        <>
            <SingleListItemTitle>{title}</SingleListItemTitle>
            <div className="item-right">
                {!!value && <Badge text="Set" {...highlight} />}
                <ItemMenu items={items} />
            </div>
        </>
    );
};

interface Props extends CommonStepItem {}
