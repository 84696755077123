import { Button, Collapse } from 'antd';

import { Divider } from 'components/atomic';
import { MarkTableManualSettings } from './MarkTableManualSettings';
import { MarkTableList } from './MarkTableList';
import { saveTableAction } from 'state/actions';
import { useTemplate } from 'components/Template/useTemplate.hook';

export const MarkTableManual = () => {
    const { dispatch, table } = useTemplate();
    const { loading, valid } = table;

    const handleSave = async () => {
        dispatch(saveTableAction());
    };

    return (
        <div>
            <MarkTableList />
            <Divider className="mark-table-divider" />
            <Collapse
                collapsible="header"
                className="mark-table-settings"
                items={[
                    {
                        key: '1',
                        label: 'Advanced settings',
                        children: <MarkTableManualSettings />,
                    },
                ]}
            />
            <Button
                type="primary"
                disabled={loading || !valid}
                loading={loading}
                onClick={handleSave}>
                Save and next
            </Button>
        </div>
    );
};
