import classNames from 'classnames';

export const SelectionButton = ({ text, active, onClick }: Props) => {
    const className = classNames('selection-button', active && 'active');

    return (
        <div className={className} onClick={onClick}>
            {text}
        </div>
    );
};

interface Props {
    text: string;
    active?: boolean;
    onClick?: React.DOMAttributes<HTMLDivElement>['onClick'];
}
