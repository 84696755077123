import { StepProps, Steps } from 'antd';

import { FailStatusIndexes, FailedImportData } from './types';
import { TooltipIcon } from 'components/atomic';

export const ErrorQueueStatus = ({ data }: Props) => {
    const { failStatus, failStatusMsg, fixMsg } = data;
    const items: StepProps[] = new Array(4).fill({});

    return (
        <div className="error-queue-status">
            <Steps current={FailStatusIndexes[failStatus]} items={items} />
            <div className="with-tooltip">
                <div className="error-queue-status-message">{failStatusMsg}</div>
                <TooltipIcon>{fixMsg}</TooltipIcon>
            </div>
        </div>
    );
};

interface Props {
    data: FailedImportData;
}
