import { api, apiRoutes } from './api.service';
import {
    AddDocumentTemplateData,
    AddDocumentTemplateResponse,
    EditDocumentTemplateData,
    TemplateTypeDataToSave,
    TemplateTypeResponseData,
} from 'components/Templates';
import { DataToSave } from 'components/Template/Steps/types';
import { StepNumber } from 'state/types';
import { TemplateData, PostProcessRule, UpdateRulesResponse } from 'components/Template';
import { MatchData } from '../components/MatchTemplate/types';

export const addTemplateType = async (data: TemplateTypeDataToSave) => {
    return await api.fetch<TemplateTypeResponseData>(apiRoutes.templateTypes, 'post', data);
};

export const editTemplateType = async (data: TemplateTypeDataToSave, id: number) => {
    return await api.fetch<TemplateTypeResponseData>(
        `${apiRoutes.templateTypes}/${id}`,
        'put',
        data
    );
};

export const getTemplateType = async (id: number) => {
    return await api.fetch<TemplateTypeResponseData>(`${apiRoutes.templateTypes}/${id}`);
};

export const addDocumentTemplate = async (data: AddDocumentTemplateData) => {
    const { type, name, businessEntityId, userIds, files } = data;
    const formData = new FormData();
    formData.append('type', type.toString());
    formData.append('name', name);
    formData.append('businessEntityId', businessEntityId?.toString() || '');

    if (userIds?.length) {
        userIds.forEach(userId => {
            formData.append('userIds', userId);
        });
    }

    if (files?.length) {
        formData.append('file', files[0]);
    }

    return await api.fetch<AddDocumentTemplateResponse>(apiRoutes.templates, 'post', formData, {
        'Content-Type': 'multipart/form-data',
    });
};

export const addDocumentTemplateWithPDF = async (data: MatchData) => {
    const { type, name, orderId, businessEntityId, userIds } = data;
    const formData = new FormData();
    formData.append('type', type.toString());
    formData.append('name', name);
    formData.append('orderId', orderId.toString());
    formData.append('businessEntityId', businessEntityId?.toString() || '');

    if (userIds?.length) {
        userIds.forEach(userId => {
            formData.append('userIds', userId);
        });
    }

    return await api.fetch<AddDocumentTemplateResponse>(
        apiRoutes.templateWithPdf,
        'post',
        formData,
        {
            'Content-Type': 'multipart/form-data',
        }
    );
};

export const saveTemplateStep = async (id: number, step: StepNumber, data: DataToSave) => {
    return await api.fetch<UpdateRulesResponse>(
        `${apiRoutes.templates}/${id}/${apiRoutes.updateRules}?step=${step}`,
        'post',
        data
    );
};

export const reUploadPdf = async (id: number, file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await api.fetch<void>(
        `${apiRoutes.templates}/${id}/${apiRoutes.updatePDF}`,
        'post',
        formData,
        {
            'Content-Type': 'multipart/form-data',
        }
    );
};

export const cloneTemplate = async (id: number, data: AddDocumentTemplateData) => {
    const { name, files } = data;
    const formData = new FormData();
    formData.append('name', name);
    if (files?.length) {
        formData.append('file', files[0]);
    }
    return await api.fetch<void>(
        `${apiRoutes.templates}/${id}/${apiRoutes.templateClone}`,
        'post',
        formData,
        {
            'Content-Type': 'multipart/form-data',
        }
    );
};

export const setTemplateSettings = async (id: number, template: Partial<TemplateData>) => {
    return await api.fetch<void>(`${apiRoutes.templateSettings}/${id}`, 'post', template);
};

export const getTemplateData = async (id: number) => {
    return await api.fetch<TemplateData>(`${apiRoutes.templates}/${id}`);
};

export const deleteTemplate = async (id: number) => {
    return await api.fetch<void>(`${apiRoutes.templates}/${id}`, 'delete');
};

export const editTemplate = async (id: number, data: Partial<EditDocumentTemplateData>) => {
    return await api.fetch<void>(`${apiRoutes.templates}/${id}`, 'put', data);
};

export const setPostProcessRules = async (id: number, data: PostProcessRule[]) => {
    return await api.fetch<void>(
        `${apiRoutes.templates}/${id}/${apiRoutes.postProcessRules}`,
        'post',
        data
    );
};

export const deletePostProcessRules = async (id: number, ruleIds: number[]) => {
    return await api.fetch<void>(
        `${apiRoutes.templates}/${id}/${apiRoutes.deletePostProcessRules}`,
        'post',
        ruleIds
    );
};

export const getTemplateDocumentFile = async (id: number) => {
    return await api.getFileResponse(`${apiRoutes.templates}/download/${id}`);
};
