import { Form, FormProps } from 'antd';

export const CommonForm = <T extends any>({
    form,
    layout = 'vertical',
    autoComplete = 'off',
    ...props
}: Props<T>) => {
    const [formInstance] = Form.useForm();

    const handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Form
            form={form || formInstance}
            onKeyUp={handleKeyUp}
            layout={layout}
            autoComplete={autoComplete}
            {...props}
        />
    );
};

interface Props<T> extends FormProps<T> {
    children?: React.ReactNode;
    submitOnEnter?: boolean;
}
