import { useState } from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { FieldsListItem } from './FieldsListItem';
import { TemplateFieldData } from '../../types';
import { FieldsState, SortableItem } from './types';

export const FieldsList = ({ fields, setFields, disabled, onEditClick }: Props) => {
    const [activeItem, setActiveItem] = useState<SortableItem | null>(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const sortableItems: SortableItem[] = fields.map(field => ({ data: field, id: field.fieldId }));

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setFields(items => {
                const oldIndex = items.findIndex(({ fieldId }) => fieldId === active.id);
                const newIndex = items.findIndex(({ fieldId }) => fieldId === over?.id);

                const newArray = arrayMove(items, oldIndex, newIndex);

                return newArray;
            });
        }

        setActiveItem(null);
    }

    function handleDragStart({ active }: DragStartEvent) {
        const item = sortableItems.find(field => field.id === active.id);

        if (item) {
            setActiveItem(item);
        }
    }

    function handleDragCancel() {
        setActiveItem(null);
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}>
            <SortableContext
                items={sortableItems}
                strategy={verticalListSortingStrategy}
                disabled={disabled}>
                {sortableItems.map(item => (
                    <FieldsListItem key={item.id} item={item} onEditClick={onEditClick} />
                ))}
            </SortableContext>

            <DragOverlay>
                {activeItem ? <FieldsListItem item={activeItem} dragging /> : null}
            </DragOverlay>
        </DndContext>
    );
};

interface Props extends FieldsState {
    disabled: boolean;
    onEditClick: (fieldData: TemplateFieldData) => void;
}
