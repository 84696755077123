import { createReducer } from '@reduxjs/toolkit';
import { initializeFinishAction } from 'state/actions';

import { InitState } from 'state/types';

const initialState: InitState = {
    initialized: false,
};

export const initReducer = createReducer(initialState, builder => {
    builder.addCase(initializeFinishAction, state => {
        state.initialized = true;
    });
});
