import { useDrawer } from 'components/DrawerContainer';
import { PlusButton } from 'components/atomic';
import { AddressTableForm } from './AddressTableForm';

export const AddAddressTableButton = () => {
    const { openDrawer } = useDrawer();

    const handleClick = () => {
        openDrawer('New Address', <AddressTableForm />);
    };

    return <PlusButton onClick={handleClick}>Address</PlusButton>;
};
