import { TemplateData } from 'components/Template';
import { TemplateFieldResponseData } from 'components/Templates';

export const getSuggestedFields = (data: TemplateData) => {
    const fields = data.fields
        .filter(item => !item.isTable)
        .filter(item => hasSuggestedProperty(item));

    const suggested: SuggestedField[] = [];

    data.extractedFields.forEach(extracted => {
        const field = fields.find(({ fieldId }) => fieldId === extracted.id);
        let value = data.extractResult.order_header[extracted.id];
        if (field && value) {
            suggested.push({
                label: field.label,
                value: value,
                id: field.fieldId,
            });
        }
    });

    return suggested.slice(0, 2);
};

export const hasSuggestedProperty = (field: TemplateFieldResponseData) => {
    return suggestions.some(item => field.propertyName.toLowerCase().includes(item.toLowerCase()));
};

const suggestions = ['organization', 'date', 'invoice', 'order', 'purchase', 'title', 'number'];

interface SuggestedField {
    label: string;
    value: string;
    id: number;
}
