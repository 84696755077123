import { IntegrationConnectionProperties, SettingsData } from '../types';
import { IntegrationFormValues, IntegrationFromDataToSave } from './types';

export const OUTPUT_FOLDER_SYSTEM_ID = 3;

export const getSystemsSelectOptions = (systems: SettingsData['integrationSystems']) => {
    return systems.map(system => ({ value: system.id, label: system.name }));
};

export const isShowTestConnection = (id: number | null) => {
    return id !== OUTPUT_FOLDER_SYSTEM_ID && id !== null;
};

export const prepareIntegrationForSave = (
    values: IntegrationFormValues,
    properties: IntegrationConnectionProperties[]
): IntegrationFromDataToSave => {
    const { systemId, ...rest } = values;

    properties.forEach(prop => {
        prop.value = rest[prop.key] || '';
    });

    return {
        integrationSystemId: systemId,
        integrationConnectionProperties: properties,
    };
};
