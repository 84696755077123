import { createReducer } from '@reduxjs/toolkit';

import { setLicenseAction } from 'state/actions';
import { LicenseState } from 'state/types/license.types';

const initialState: LicenseState = {
    data: null,
};

export const licenseReducer = createReducer(initialState, builder => {
    builder.addCase(setLicenseAction, (state, { payload }) => {
        state.data = payload;
    });
});
