export const ExtractedError = ({ text, value }: Props) => {
    let errorText = text || 'Field extracted with errors';

    if (errorText.endsWith(NUMBER_VALIDATION_ERROR)) {
        errorText = value ? `${value} - ${NUMBER_VALIDATION_ERROR}` : NUMBER_VALIDATION_ERROR;
    }

    if (errorText.endsWith(REQUIRED_VALIDATION_ERROR)) {
        errorText = REQUIRED_VALIDATION_ERROR;
    }

    return <div className="extracted-error">{errorText}</div>;
};

const NUMBER_VALIDATION_ERROR = 'number validation failed';
const REQUIRED_VALIDATION_ERROR = 'required field is missing';

interface Props {
    text?: string;
    value: string;
}
