import { Button } from 'antd';

import { SingleList } from 'components/atomic';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { saveTableColumnsAction, tableColumnsAction } from 'state/actions';
import { MarkTableColumnsItem } from './MarkTableColumnsItem';

export const MarkTableColumns = () => {
    const { dispatch, tableColumns } = useTemplate();
    const { items, active, loading } = tableColumns;

    const handleItemClick = (index: number) => {
        dispatch(tableColumnsAction({ active: index }));
    };

    const handleSave = async () => {
        dispatch(saveTableColumnsAction());
    };

    return (
        <div>
            <SingleList
                dataSource={items}
                renderItem={item => (
                    <MarkTableColumnsItem {...item} isActive={active === item.id} />
                )}
                onItemClick={handleItemClick}
                activeIndex={active}
            />
            <Button type="primary" disabled={loading} loading={loading} onClick={handleSave}>
                Save
            </Button>
        </div>
    );
};
