import { TemplateData } from 'components/Template';
import { ExtractedPreview } from 'components/ExtractedPreview';

export const DocumentInfo = ({ document, id }: Props) => {
    return (
        <div className="document-preview-info">
            <ExtractedPreview data={document} orderId={id} />
        </div>
    );
};

interface Props {
    document: TemplateData;
    id: number;
}
