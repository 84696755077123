import { DocumentTabs } from './DocumentTabs/DocumentTabs';
import { ResultsModal } from './ResultsModal/ResultsModal';

export const Document = ({ ids }: Props) => {
    return (
        <>
            <DocumentTabs ids={ids} />
            <ResultsModal />
        </>
    );
};

interface Props {
    ids: number[];
}
