import classNames from 'classnames';

export const SingleListItem = ({ children, index, isActive, onClick, importStatus}: Props) => {
    const handleClick = () => {
        if (onClick && typeof index === 'number') {
            onClick(index);
        }
    };

    return (
        <div
            onClick={handleClick}
            className={classNames('single-list-item', isActive && 'single-list-item-selected', importStatus === 'not_imported' && 'single-list-item-selected-not-imported', importStatus === 'import_error' && 'single-list-item-selected-error')}>
            {children}
        </div>
    );
};

interface Props extends WithChildren {
    index?: number;
    isActive?: boolean;
    importStatus?: string;
    onClick?: (index: number) => void;
}
