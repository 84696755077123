import { useEffect, useRef, useState } from 'react';

import { Size } from './types';
import { HeaderSelection } from './SelectionTypes/HeaderSelection';
import { FooterSelection } from './SelectionTypes/FooterSelection';
import { FieldsSelection } from './SelectionTypes/FieldsSelection';
import { AreaSelection } from './SelectionTypes/AreaSelection';
import { ConfirmMenu } from './ConfirmMenu';
import { ConfirmMenuProps } from '../types';
import { SelectionContextProvider } from './context';
import { useTemplate } from '../useTemplate.hook';
import { Highlight } from './Highlight/Highlight';

export const SelectionLayer = () => {
    const { template, selectionInfo: selectionType, currentPage } = useTemplate();
    const [confirmMenu, setConfirmMenu] = useState<ConfirmMenuProps | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [rect, setRect] = useState<DOMRect>();
    const page = template?.map.pages[currentPage];
    const { type } = selectionType || {};
    const scale: Size = {
        width: page?.width ?? 0,
        height: page?.height ?? 0,
    };

    const handleRectChange = () => {
        setRect(containerRef.current?.getBoundingClientRect());
    };

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const elem = containerRef.current;
        const scrollingElem = document.querySelector('.file-view-pdf-area');
        const resizeObserver = new ResizeObserver(handleRectChange);

        handleRectChange();

        resizeObserver.observe(elem);
        scrollingElem?.addEventListener('scroll', handleRectChange);

        return () => {
            resizeObserver.disconnect();
            scrollingElem?.removeEventListener('scroll', handleRectChange);
        };
    }, [containerRef.current]);

    useEffect(() => {
        setConfirmMenu(null);
    }, [selectionType]);

    return (
        <SelectionContextProvider value={{ confirmMenu, setConfirmMenu }}>
            <div className="selection-layer" ref={containerRef}>
                {type === 'header' && rect && <HeaderSelection scale={scale} rect={rect} />}
                {type === 'footer' && rect && <FooterSelection scale={scale} rect={rect} />}
                {type === 'box' && rect && <FieldsSelection scale={scale} rect={rect} />}
                {type === 'area' && rect && <AreaSelection scale={scale} rect={rect} />}
                {rect && <Highlight scale={scale} rect={rect} />}
                <ConfirmMenu />
            </div>
        </SelectionContextProvider>
    );
};
