import classNames from 'classnames';
import { Collapse } from 'antd';

export const CollapsibleBlock = ({
    title,
    children,
    className,
    expanded = true,
    disabled = false,
    onChange,
}: Props) => {
    const containerClassName = classNames('collapsible-block', className);

    return (
        <Collapse
            collapsible={disabled ? 'disabled' : 'header'}
            className={containerClassName}
            activeKey={[Number(expanded)]}
            onChange={onChange}
            items={[
                {
                    key: '1',
                    label: <h2>{title}</h2>,
                    children,
                },
            ]}
        />
    );
};

interface Props extends WithChildren, WithClassName {
    title: React.ReactNode;
    expanded?: boolean;
    onChange?: (key: string | string[]) => void;
    disabled?: boolean;
}
