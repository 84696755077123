import { Container } from 'components/atomic';
import { Header } from 'components/Header/Header';
import { useAppSelector } from 'hooks/redux.hooks';
import { routes } from 'navigator';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
    const isAuth = useAppSelector(state => state.user.isAuth);

    return (
        <div className="error-page">
            <Header />
            <div className="error-page-background" />

            <div className="error-page-container">
                <Container className="error-page-content">
                    <div className="error-page-line" />
                    <div className="error-page-code">404</div>
                    <div className="error-page-text">
                        We wanted to show you what happens when you have too many docs on your mind…
                    </div>
                    <div className="error-page-divider" />
                    <div className="error-page-links">
                        {isAuth ? (
                            <Link to={routes.overview}>Overview</Link>
                        ) : (
                            <Link to={routes.login}>Login</Link>
                        )}
                    </div>
                </Container>
            </div>
        </div>
    );
};
