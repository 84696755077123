import { Progress } from 'antd';
import { EmailLink, emails } from 'components/EmailLink';
import { useAppSelector } from 'hooks/redux.hooks';

export const UsersPlan = ({ usersCount }: Props) => {
    const data = useAppSelector(state => state.license.data);

    if (!data || !usersCount) {
        return null;
    }

    const { usersLimit } = data;
    const percent = (usersCount * 100) / usersLimit;

    return (
        <div className="users-plan">
            <div>
                <span className="users-plan-count">
                    {usersCount} of {usersLimit}
                </span>
                <EmailLink className="users-plan-upgrade" emailContent={emails.upgradePlanForUsers}>
                    Upgrade plan
                </EmailLink>
            </div>
            <Progress percent={percent} showInfo={false} status="normal" />
        </div>
    );
};

interface Props {
    usersCount?: number;
}
