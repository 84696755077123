import classNames from 'classnames';

export const Divider = ({ children, className, vertical = false, ...props }: Props) => {
    const dividerClassName = classNames(vertical ? 'divider-vertical' : 'divider', className);

    return (
        <div className={dividerClassName} {...props}>
            {children}
        </div>
    );
};

interface Props extends DivProps {
    vertical?: boolean;
}
