import { useTemplate } from '../useTemplate.hook';
import { ExtractedPreview } from 'components/ExtractedPreview';

export const Preview = () => {
    const { template } = useTemplate();

    if (!template) {
        return null;
    }

    return (
        <div className="extracted-preview-container">
            <ExtractedPreview data={template} />
        </div>
    );
};
