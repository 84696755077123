import { useDrawer } from 'components/DrawerContainer';
import { PlusButton } from 'components/atomic';
import { DocumentTemplateForm } from './DocumentTemplateForm';

export const AddDocumentTemplateButton = () => {
    const { openDrawer } = useDrawer();

    const handleClick = () => {
        openDrawer('New doc template', <DocumentTemplateForm />);
    };

    return <PlusButton onClick={handleClick}>Doc Template</PlusButton>;
};
