import { createListenerMiddleware } from '@reduxjs/toolkit';

import { setAuthStateAction, setLicenseAction } from 'state/actions';
import { RootState } from 'state/store.types';
import config from 'configs';
import { getLicense } from 'services';

export const licenseMiddleWare = createListenerMiddleware<RootState>();

let pollingInterval: NodeJS.Timer | null = null;

licenseMiddleWare.startListening({
    actionCreator: setAuthStateAction,
    effect: (action, { dispatch }) => {
        if (action.payload) {
            requestLicenseData();
            pollingInterval = setInterval(requestLicenseData, config.licensePollingInterval);
        } else if (pollingInterval) {
            clearInterval(pollingInterval);
        }

        async function requestLicenseData() {
            const data = await getLicense();

            if (data) {
                dispatch(setLicenseAction(data));
            }
        }
    },
});
