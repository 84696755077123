import { useEffect, useState } from 'react';

import { TemplateFieldResponseData } from 'components/Templates';
import { apiRoutes, editDocument } from 'services';
import { FieldEditData } from './types';
import { ExtractedEdit } from './ExtractedEdit';
import { useDocumentHighlight } from 'components/Document/DocumentPreview/helpers';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { useExtractedPreview } from './context';

export const ExtractedField = ({ field, extractedValue, rowIndex }: Props) => {
    const { extractedFields, fieldOptions, orderId, selected } = useExtractedPreview();
    const dispatch = useAppDispatch();
    const currentId = useAppSelector(state => state.document.currentId);
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(extractedValue || '');
    const [loading, setLoading] = useState(false);
    const editable = typeof orderId === 'number';
    const tableField = typeof rowIndex === 'number';
    const options = fieldOptions ? fieldOptions[field.fieldId] : undefined;
    const extractedField = extractedFields.find(
        item => item.id === field.fieldId && (tableField ? item.row === rowIndex : true)
    );
    const mapping = extractedField?.extractionMapping;
    const highlightValue = mapping?.content.map(item => item.index) || null;
    const highlightPage = mapping?.content[0]?.pageNum;
    const highlight = useDocumentHighlight(highlightValue, highlightPage);

    const toggleEditMode = () => {
        setEditMode(prev => !prev);
    };
    const handleCancelPress = () => {
        setValue(extractedValue || '');
        toggleEditMode();
    };

    const handleSavePress = async () => {
        setLoading(true);

        const dataToSave: FieldEditData = {
            id: field.fieldId,
            propertyName: field.propertyName,
            value,
            isTable: false,
        };

        if (tableField) {
            dataToSave.index = rowIndex;
            dataToSave.isTable = true;
        }

        if (orderId && editable) {
            await editDocument(orderId, dataToSave);
        }

        if (orderId && editable && tableField && selected.length) {
            await Promise.all(
                selected
                    .filter(index => index !== rowIndex)
                    .map(index =>
                        editDocument(orderId, {
                            ...dataToSave,
                            isTable: true,
                            index,
                        })
                    )
            );
        }

        setLoading(false);
        toggleEditMode();
        dispatch(getListenerAction(`${apiRoutes.document}/${currentId}`));
    };

    useEffect(() => {
        setValue(extractedValue || '');
    }, [extractedValue]);

    return (
        <div className="extracted-preview-edit" {...highlight}>
            <ExtractedEdit
                editMode={editMode}
                editable={editable}
                loading={loading}
                value={value}
                fieldOptions={options}
                error={extractedField?.error}
                errorReason={extractedField?.errorReason}
                onChange={setValue}
                onSave={handleSavePress}
                onCancel={handleCancelPress}
                toggleEditMode={toggleEditMode}
            />
        </div>
    );
};

interface Props {
    field: TemplateFieldResponseData;
    extractedValue?: string;
    rowIndex?: number;
}
