import { Button } from 'antd';

import { SingleList } from 'components/atomic';
import { MarkHeaderAndFooterItem } from './MarkHeaderAndFooterItem';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { headerAndFooterAction, saveHeaderAndFooterAction } from 'state/actions';

export const MarkHeaderAndFooter = () => {
    const { dispatch, headerAndFooter } = useTemplate();
    const { items, active, valid, loading } = headerAndFooter;

    const handleItemClick = (index: number) => {
        dispatch(headerAndFooterAction({ active: index }));
    };

    const handleSave = async () => {
        dispatch(saveHeaderAndFooterAction());
    };

    return (
        <div>
            <SingleList
                dataSource={items}
                renderItem={item => <MarkHeaderAndFooterItem {...item} />}
                onItemClick={handleItemClick}
                activeIndex={active}
            />
            <Button
                type="primary"
                disabled={loading || !valid}
                loading={loading}
                onClick={handleSave}>
                Save and next
            </Button>
        </div>
    );
};
