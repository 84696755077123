import { Dropdown, MenuProps } from 'antd';
import { useEffect, useState } from 'react';

import { MenuIcon } from 'components/atomic';
import { useDrawer } from 'components/DrawerContainer';
import { ImportForm } from 'components/ImportButton/ImportForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { apiRoutes, getImportResults, reImportWithTemplateId } from 'services';
import { exportResultAction } from 'state/actions';
import { ImportResult } from 'state/types';
import { addListener } from '@reduxjs/toolkit';
import { downloadDocument, downloadImportData } from './helpers';
import { getListenerAction } from 'hooks/data.hooks';
import { routes } from '../../../navigator';
import { useNavigate } from 'react-router-dom';

export const ImportOptions = () => {
    const { openDrawer } = useDrawer();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentId = useAppSelector(state => state.document.currentId);
    const document = useAppSelector(state => state.document.document);
    const [results, setResults] = useState<ImportResult | null>(null);

    const handleViewResultsClick = () => {
        if (results) {
            dispatch(exportResultAction(results));
        }
    };

    const handleImportAgainClick = async () => {
        if (typeof currentId === 'number' && typeof document?.templateId === 'number') {
            const documents = await reImportWithTemplateId(currentId, document?.templateId);
            dispatch(getListenerAction(`${apiRoutes.document}/${currentId}`));
            if (documents && documents.length > 1) {
                navigate(`${routes.documents}/${currentId}?ids=${documents.map(doc => doc.id)}`);
            }
        }
    };

    const handleNewImportClick = () => {
        openDrawer(
            'Manual import',
            <ImportForm data={{ template: document?.templateId, type: document?.templateTypeId }} />
        );
    };

    const handleDownloadImportDataClick = () => {
        if (!currentId) {
            return;
        }

        downloadImportData(currentId);
    };

    const handleDownloadDocumentClick = () => {
        if (!currentId) {
            return;
        }

        downloadDocument(currentId, false);
    };

    const items: MenuProps['items'] = [
        {
            key: 'newImport',
            label: <div onClick={handleNewImportClick}>New import</div>,
        },
        {
            key: 'importAgain',
            label: <div onClick={handleImportAgainClick}>ReImport</div>,
        },
        {
            key: 'download',
            label: <div onClick={handleDownloadImportDataClick}>Download import data</div>,
        },
        {
            key: 'downloadDocument',
            label: <div onClick={handleDownloadDocumentClick}>Download document</div>,
        },
    ];

    if (results) {
        items.unshift({
            key: 'viewResults',
            label: (
                <div
                    onClick={handleViewResultsClick}
                    className={results.success ? '' : 'view-results-error'}>
                    View results
                </div>
            ),
        });
    }

    useEffect(() => {
        if (currentId === null) {
            return;
        }

        getImportResults([currentId]).then(res => {
            if (res && res[0] && res[0].integrationResults[0]) {
                setResults(res[0].integrationResults[0]);
            }
        });
    }, [currentId]);

    useEffect(() => {
        return dispatch(
            addListener({
                actionCreator: exportResultAction,
                effect: ({ payload }) => {
                    setResults(payload);
                },
            })
        ) as unknown as () => void;
    }, []);

    return (
        <div className="import-options">
            <Dropdown menu={{ items }} trigger={['click']} arrow>
                <div className="template-type">
                    <MenuIcon />
                </div>
            </Dropdown>
        </div>
    );
};
