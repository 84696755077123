import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { DocumentData } from './types';
import { ListCelText, ListIconCelText } from 'components/List';
import { DocumentIcon } from 'components/atomic';
import { DocumentsListItemEdit } from './DocumentsListItemEdit';
import { DocumentsListType } from 'components/GenericDocsList';

export const getColumns = (type: DocumentsListType) => {
    const columns: ColumnsType<DocumentData> = [
        {
            key: 'id',
            dataIndex: 'id',
            render: (id: number, item) => (
                <ListIconCelText
                    icon={<DocumentIcon />}
                    text={`${item.typeLabel} #${item.documentId || id}`}
                    subtext={moment(item.createdAt).format('MMM DD, HH:mm')}
                />
            ),
        },
        {
            key: 'templateName',
            dataIndex: 'templateName',
            render: (templateName: string) =>
                templateName && <ListCelText text={templateName} subtext="Organization" />,
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            render: (amount: string) => amount && <ListCelText text={amount} subtext="Amount" />,
        },
    ];

    if (type !== 'documents') {
        columns.push({
            key: 'status',
            render: () => <div />,
        });
    }

    if (type !== 'delete') {
        columns.push({
            key: 'edit',
            render: (value, data) => {
                return <DocumentsListItemEdit data={data} />;
            },
        });
    }

    return columns;
};
