import classNames from 'classnames';

import { Button, ButtonProps } from 'antd';
import { PlusIcon } from '../Icon/Icon';

export const PlusButton = ({ className, ...props }: ButtonProps) => {
    const buttonClassName = classNames('plus-button', className);

    return (
        <Button
            {...props}
            type="primary"
            className={buttonClassName}
            icon={
                <div className="plus-icon">
                    <PlusIcon />
                </div>
            }
        />
    );
};
