import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { TemplateByTypeData } from './types';
import { useAppSelector } from 'hooks/redux.hooks';

export const useTemplates = () => {
    const selectedType = useAppSelector(state => state.ui.selectedType);

    const path = selectedType ? `${apiRoutes.templatesByType}/${selectedType.value}` : null;

    return useData<TemplateByTypeData[]>(path);
};
