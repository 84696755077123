import { Avatar, AvatarProps } from 'antd';
import { ListCelText, ListCelTextProps } from './ListCelText';

export const ListIconCelText = ({ icon, ...props }: Props) => {
    return (
        <div className="list-icon-cel-text">
            <Avatar shape="square" size={40} icon={icon} />
            <ListCelText {...props} />
        </div>
    );
};

interface Props extends ListCelTextProps {
    icon?: AvatarProps['icon'];
}
