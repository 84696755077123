import { UserRole } from 'components/Users/types';

export enum UserActionTypes {
    SET_AUTH_STATE = 'SET_AUTH_STATE',
    SET_USER_DATA = 'SET_USER_DATA',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    UPLOAD_IMAGE = 'UPLOAD_IMAGE',
    SET_IMAGE = 'SET_IMAGE',
}

export interface UserState {
    isAuth: boolean;
    data: UserData | null;
    image: string;
}

export interface UserData {
    email: string;
    name: string;
    role: UserRole;
}
