import { Empty } from 'antd';

import { ContainerCenter } from 'components/atomic';

export const DocumentError = () => {
    return (
        <ContainerCenter>
            <Empty description="Document loaded with errors" />
        </ContainerCenter>
    );
};
