import { LoginErrorIcon } from 'components/atomic';

export const LoginError = ({ error }: Props) => {
    if (!error) {
        return null;
    }

    return (
        <div className="login-error">
            <div className="login-error-icon">
                <LoginErrorIcon />
            </div>
            <div className="login-error-text">Check credentials and try again</div>
        </div>
    );
};

interface Props {
    error: boolean;
}
