import { useEffect, useRef } from 'react';
import { Box, Point, Size, SelectionEventHandler } from './types';
import { calculateEvent, mouseOverConfirmMenu } from './helpers';
import { useTemplate } from '../useTemplate.hook';

const START_POINT: Point = { left: 0, top: 0 };
const START_BOX: Box = { left: 0, top: 0, width: 0, height: 0 };

export const SelectionHandler = ({
    scale,
    rect,
    trackMouseMove = false,
    onSelectionStart,
    onSelectionChange,
    onSelectionEnd,
}: Props) => {
    const { currentPage, template } = useTemplate();
    const view = useRef(START_BOX);
    const start = useRef(START_POINT);
    const pressed = useRef(false);

    const handleMouseDown = (event: MouseEvent) => {
        if (mouseOverConfirmMenu(event)) {
            return;
        }

        const selectionEvent = calculateEvent(view.current, start.current, event, scale);

        if (selectionEvent.hitSpotArea) {
            pressed.current = true;
            start.current = { left: event.pageX, top: event.pageY };
            onSelectionStart &&
                onSelectionStart(calculateEvent(view.current, start.current, event, scale));
        }
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (mouseOverConfirmMenu(event)) {
            return;
        }

        if (pressed.current || trackMouseMove) {
            onSelectionChange &&
                onSelectionChange(calculateEvent(view.current, start.current, event, scale));
        }
    };

    const handleMouseUp = (event: MouseEvent) => {
        if (mouseOverConfirmMenu(event)) {
            return;
        }

        const selectionEvent = calculateEvent(view.current, start.current, event, scale);

        if (selectionEvent.hitSpotArea) {
            onSelectionEnd &&
                onSelectionEnd(calculateEvent(view.current, start.current, event, scale));
        }
        pressed.current = false;
    };

    useEffect(() => {
        if (rect) {
            const { left, top, width, height } = rect;
            view.current = { left, top, width, height };
        }

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [rect.left, rect.top, rect.height, rect.width, currentPage, template?.map]);

    return null;
};

interface Props {
    scale: Size;
    rect: DOMRect;
    trackMouseMove?: boolean;
    onSelectionStart?: SelectionEventHandler;
    onSelectionChange?: SelectionEventHandler;
    onSelectionEnd?: SelectionEventHandler;
}
