import { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { CommonForm, Container } from 'components/atomic';
import {
    getSystemsSelectOptions,
    isShowTestConnection,
    prepareIntegrationForSave,
} from './helpers';
import { useDrawer } from 'components/DrawerContainer';
import { getIntegrationSystemProps } from 'services/settings.service';
import { TestConnection } from './TestConnection';
import { IntegrationFormData, IntegrationFormValues, IntegrationFromDataToSave } from './types';
import { routes } from 'navigator';

export const IntegrationForm = ({ data, onFinish }: Props) => {
    const { integrationConnectionProperties = [], integrationSystemId, integrationSystems } = data;
    const { closeDrawer } = useDrawer();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm<IntegrationFormValues>();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [systemProps, setSystemProps] = useState(integrationConnectionProperties);
    const selectedSystemId: number | null = Form.useWatch('systemId', form) || integrationSystemId;
    const showTestConnection = isShowTestConnection(selectedSystemId);
    const integrationsOptions = getSystemsSelectOptions(integrationSystems);
    const initialValues: IntegrationFormValues = {
        systemId: selectedSystemId,
    };

    systemProps.forEach(prop => {
        initialValues[prop.key] = prop.value;
    });

    const handleFinish = async (values: IntegrationFormValues) => {
        const valuesToSave = prepareIntegrationForSave(values, systemProps);
        onFinish(valuesToSave);
        closeDrawer();
    };

    const handleSystemChange = async (value: number) => {
        setLoading(true);
        const sysProps = await getIntegrationSystemProps(value);
        setSystemProps(sysProps || []);

        setLoading(false);
    };

    const handleValuesChange = (changedValues: any, values: IntegrationFormValues) => {
        validateValues(values);
    };

    const validateValues = (values: IntegrationFormValues) => {
        const anyEmpty = Object.values(values).some(item => !item);
        setDisabled(anyEmpty);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();

        if (state === 'fix') {
            navigate(routes.errorQueue);
        }
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
        validateValues(initialValues);
    }, [JSON.stringify(initialValues)]);

    return (
        <Spin spinning={loading}>
            <CommonForm
                name="integration-form"
                initialValues={initialValues}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                form={form}>
                <Container className="form-container">
                    <Form.Item<typeof initialValues>
                        label="Integration system"
                        name="systemId"
                        required={false}>
                        <Select
                            options={integrationsOptions}
                            placeholder="Select system"
                            onChange={handleSystemChange}
                        />
                    </Form.Item>

                    {systemProps.map(prop => (
                        <Form.Item
                            label={prop.name}
                            name={prop.key}
                            required={false}
                            key={prop.key + prop.id}
                            rules={[{ required: true, message: `Please enter ${prop.name}` }]}>
                            {prop.key === 'password' ? <Input.Password /> : <Input />}
                        </Form.Item>
                    ))}

                    {showTestConnection && (
                        <TestConnection
                            form={form}
                            systemProps={systemProps}
                            disabled={disabled}
                            selectedSystemId={selectedSystemId}
                        />
                    )}
                </Container>

                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading || disabled}
                    loading={loading}>
                    Save
                </Button>
                <Button type="link" disabled={loading} onClick={handleClose}>
                    Cancel
                </Button>
            </CommonForm>
        </Spin>
    );
};

interface Props {
    data: IntegrationFormData;
    onFinish: (values: IntegrationFromDataToSave) => void;
}
