import { Spin, Tabs, TabsProps } from 'antd';

import { useDocumentData } from '../useDocument.hook';
import { DocumentError } from './DocumentError';
import { DocumentView } from './DocumentView';
import { DocumentInfo } from './DocumentInfo';
import { DocumentHeader } from './DocumentHeader';
import { ContainerCenter, Divider } from 'components/atomic';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setCurrentDocumentIdAction } from 'state/actions';
import { routes } from 'navigator';
import { DocumentOutput } from './DocumentOutput';

export const DocumentPreview = ({ id }: WithId) => {
    const dispatch = useAppDispatch();
    const { document, file, loading } = useDocumentData(id);

    useEffect(() => {
        dispatch(setCurrentDocumentIdAction(id));
    }, [id]);

    if (loading) {
        return (
            <ContainerCenter>
                <Spin size="large" />
            </ContainerCenter>
        );
    }

    if (!document || !file) {
        return <DocumentError />;
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Extracted information',
            children: <DocumentInfo document={document} id={id} />,
        },
        {
            key: '2',
            label: 'Output',
            children: <DocumentOutput id={id} />,
        },
    ];

    return (
        <div>
            <DocumentHeader document={document} />
            <Divider />
            <div className="document-preview">
                <div className="document-preview-column document-preview-column-pdf">
                    <div className="document-preview-title">Source file</div>
                    <DocumentView file={file} document={document} />
                </div>
                <div className="document-preview-column document-preview-column-info">
                    <a
                        href={`/#${routes.templates}/${document.templateId}`}
                        className="document-preview-column-edit-link">
                        Edit template
                    </a>
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            </div>
        </div>
    );
};
