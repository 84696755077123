export const TemplatesLayout = ({ left, right }: Props) => {
    return (
        <div className="templates-layout">
            <div className="templates-layout-left">{left}</div>
            <div className="templates-layout-right">{right}</div>
        </div>
    );
};

interface Props {
    left?: React.ReactNode;
    right?: React.ReactNode;
}
