import { addListener } from '@reduxjs/toolkit';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'hooks/redux.hooks';
import { useEffect, useRef, useState } from 'react';
import { saveTemplateAction } from 'state/actions';
import { TemplateActionTypes } from 'state/types';
import { SaveCompleteNotification } from './SaveCompleteNotification';
import { SaveIncompleteModal } from './SaveIncompleteModal';
import { useDrawer } from 'components/DrawerContainer';
import { ImportForm } from 'components/ImportButton/ImportForm';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { TemplateData } from 'components/Template/types';

export const SaveTemplateButton = () => {
    const { template } = useTemplate();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { openDrawer } = useDrawer();
    const [open, setOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const templateRef = useRef<TemplateData | null>(template);

    const handleClick = () => {
        dispatch(saveTemplateAction());
    };

    const showCompleteNotification = () => {
        api.open({
            message: <SaveCompleteNotification />,
            placement: 'top',
            className: 'save-template-notification',
            duration: 3000,
            onClose: handleClose,
        });
    };

    const shopIncompleteModal = () => {
        setOpen(true);
    };

    const handleClose = () => {
        openDrawer(
            'Manual import',
            <ImportForm
                data={{
                    type: templateRef.current?.templateTypeId,
                    template: templateRef.current?.templateId,
                }}
            />
        );
        navigate(-1);
    };

    useEffect(() => {
        const completeListener = dispatch(
            addListener({
                type: TemplateActionTypes.SAVE_TEMPLATE_COMPLETE,
                effect: showCompleteNotification,
            })
        ) as unknown as () => void;

        const incompleteListener = dispatch(
            addListener({
                type: TemplateActionTypes.SAVE_TEMPLATE_INCOMPLETE,
                effect: shopIncompleteModal,
            })
        ) as unknown as () => void;

        return () => {
            completeListener();
            incompleteListener();
        };
    }, []);

    useEffect(() => {
        if (template) {
            templateRef.current = template;
        }
    }, [template]);

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={handleClick}>
                Save template
            </Button>
            <SaveIncompleteModal open={open} setOpen={setOpen} onClose={handleClose} />
        </>
    );
};
