import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, Button, Form } from 'antd';

import { LinkIcon } from 'components/atomic';
import { SettingsData } from '../types';
import { useDrawer } from 'components/DrawerContainer';
import { IntegrationForm } from './IntegrationForm';
import { IntegrationFormData, IntegrationFromDataToSave } from './types';
import { getListenerAction } from 'hooks/data.hooks';
import { useAppDispatch } from 'hooks/redux.hooks';
import { apiRoutes } from 'services';
import { saveSettings } from 'services/settings.service';

export const ConnectedIntegration = () => {
    const { state } = useLocation();
    const { openDrawer } = useDrawer();
    const dispatch = useAppDispatch();
    const form = Form.useFormInstance<SettingsData>();
    const data: IntegrationFormData = form.getFieldsValue([
        'integrationSystemId',
        'integrationSystems',
        'integrationConnectionProperties',
    ]);
    const connection = data.integrationSystems.find(
        system => system.id === data.integrationSystemId
    );

    const handleEditClick = () => {
        const drawerTitle = connection ? `Connection to ${connection?.name}` : 'Add connection';
        openDrawer(drawerTitle, <IntegrationForm data={data} onFinish={handleIntegrationChange} />);
    };

    const handleIntegrationChange = async (values: IntegrationFromDataToSave) => {
        form.setFieldsValue(values);
        saveSettings(values);
        dispatch(getListenerAction(apiRoutes.settings));
    };

    useEffect(() => {
        if (state === 'fix') {
            handleEditClick();
        }
    }, [state]);

    return (
        <div className="connected-integration">
            <div className="connected-integration-connection">
                <Avatar shape="square" size={70} icon={<LinkIcon />} />
                {connection ? (
                    <div>
                        <div className="connected-integration-text">
                            Connected to {connection.name}
                        </div>
                        {/* <div className="connected-integration-sync-date">Last sync: </div> */}
                    </div>
                ) : (
                    <Button type="link" onClick={handleEditClick}>
                        Add connection
                    </Button>
                )}
            </div>
            {!!connection && (
                <div>
                    <Button type="link" onClick={handleEditClick}>
                        Edit connection
                    </Button>
                </div>
            )}
        </div>
    );
};
