import { Avatar, Form } from 'antd';
import classNames from 'classnames';

import { LinkIcon } from 'components/atomic';
import { ListenerIntegrationData, SettingsData } from '../types';
import moment from 'moment';

const SYNC_DAYS_LIMIT = 2;

export const ListenerIntegration = () => {
    const form = Form.useFormInstance<SettingsData>();
    const data: ListenerIntegrationData = form.getFieldValue('listenerStatus');

    if (!data || !data.enabled) {
        return null;
    }

    const now = moment();
    const days = now.diff(data.lastCheck, 'days');
    const syncDate = days < 1 ? moment(data.lastCheck).fromNow() : `${days} days ago`;
    const syncClassName = classNames(
        'connected-integration-details',
        days > SYNC_DAYS_LIMIT && 'connected-integration-error'
    );

    return (
        <div className="connected-integration">
            <div className="connected-integration-connection">
                <Avatar shape="square" size={70} icon={<LinkIcon />} />
                <div>
                    <div className="connected-integration-text">
                        {data.type === 'EMAIL' ? 'Mail' : 'Folder'} Listener
                    </div>
                    <div className="connected-integration-details">Source: {data.source}</div>
                    <div className={syncClassName}>Last sync: {syncDate}</div>
                </div>
            </div>
        </div>
    );
};
