import { useEffect, useState } from 'react';

import { useData } from 'hooks/data.hooks';
import { api, apiRoutes } from 'services';
import { TemplateData } from 'components/Template';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setCurrentDocumentDataAction } from 'state/actions';

export const useDocumentData = (id: number) => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<Blob | null>(null);
    const [fileLoading, setFileLoading] = useState(true);

    const { data: document, loading: documentLoading } = useData<TemplateData>(
        `${apiRoutes.document}/${id}`
    );

    const fetchData = () => {
        setFileLoading(true);
        api.getFile(`${apiRoutes.documentPdf}/${id}`).then(data => {
            setFile(data);
            setFileLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        dispatch(setCurrentDocumentDataAction(document));
    }, [document]);

    return {
        file,
        document,
        loading: documentLoading || fileLoading,
    };
};
