import { DeleteAction } from './DeleteAction';
import { EditAction } from './EditAction';
import { FixAction } from './FixAction';
import { SetAction } from './SetAction';
import { ViewAction } from './ViewAction';
import { CloneAction } from './CloneAction';

export const ListActions = ({
    onViewClick,
    onEditClick,
    onDeleteClick,
    onFixClick,
    onSetClick,
    onCloneClick,
    isView = true,
}: ListActionsProps) => {
    return (
        <div className="list-actions">
            {onFixClick && <FixAction action={onFixClick} />}
            {isView && onViewClick && <ViewAction action={onViewClick} />}
            {onSetClick && <SetAction action={onSetClick} />}
            {onEditClick && <EditAction action={onEditClick} />}
            {onCloneClick && <CloneAction action={onCloneClick} />}
            {onDeleteClick && <DeleteAction action={onDeleteClick} />}
        </div>
    );
};

export interface ListActionsProps {
    onViewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    onFixClick?: () => void;
    onSetClick?: () => void;
    onCloneClick?: () => void;
    isView?: boolean;
}
