import { Button } from 'antd';
import classNames from 'classnames';

import { useSelection } from './context';
import { useTemplate } from '../useTemplate.hook';

export const ConfirmMenu = () => {
    const { selectionInfo: selectionType } = useTemplate();
    const { confirmMenu, setConfirmMenu } = useSelection();
    const { position, onConfirm } = confirmMenu ?? {};
    const className = classNames('confirm-menu', !confirmMenu && 'hidden');

    const handleConfirm: React.MouseEventHandler<HTMLElement> = () => {
        onConfirm && onConfirm();
        setConfirmMenu(null);
    };

    return (
        <div className={className} style={position}>
            <Button type="primary" size="small" onMouseUp={handleConfirm}>
                Set as {selectionType?.text || selectionType?.type}
            </Button>
        </div>
    );
};
