import { createReducer } from '@reduxjs/toolkit';

import { clearSearchAction, setSearchLoadingAction, setSearchQueryAction } from 'state/actions';

import { SearchState } from 'state/types';

const initialState: SearchState = {
    searchQuery: '',
    loading: false,
};

export const searchReducer = createReducer(initialState, builder => {
    builder
        .addCase(setSearchLoadingAction, (state, { payload }) => {
            state.loading = payload;
        })
        .addCase(setSearchQueryAction, (state, { payload }) => {
            state.searchQuery = payload;
        })
        .addCase(clearSearchAction, state => {
            state.searchQuery = '';
        });
});
