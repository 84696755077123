import { Table } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { TablePaginationConfig, TableProps } from 'antd/es/table';

import { Container, Divider } from 'components/atomic';

export const List = <T extends AnyObject>({
    title,
    controls,
    showTitle = true,
    total,
    pageSize,
    currentPage,
    dataSource,
    ...props
}: Props<T>) => {
    const showPagination = dataSource && total && dataSource.length < total;
    const pagination = showPagination
        ? getListPaginationConfig(pageSize, currentPage, total)
        : false;

    return (
        <Container className="list">
            {showTitle && (
                <div className="list-header">
                    <h2>{title}</h2>
                    <div className="list-controls">{controls}</div>
                </div>
            )}
            <Divider className="list" />
            <Table showHeader={false} {...props} dataSource={dataSource} pagination={pagination} />
        </Container>
    );
};

export const getListPaginationConfig = (
    pageSize: number = 10,
    current: number = 1,
    total?: number
): TablePaginationConfig => ({
    pageSize,
    showSizeChanger: false,
    total,
    current,
});

interface Props<T extends AnyObject> extends Omit<TableProps<T>, 'title'> {
    title?: string;
    controls?: React.ReactNode;
    showTitle?: boolean;
    total?: number;
    pageSize?: number;
    currentPage?: number;
}
