import { useTemplate } from 'components/Template/useTemplate.hook';
import { SingleList } from 'components/atomic';
import { tableAction } from 'state/actions';
import { MarkTableListItem } from './MarkTableListItem';

export const MarkTableList = () => {
    const { dispatch, table } = useTemplate();
    const { items, active } = table;

    const handleItemClick = (index: number) => {
        dispatch(tableAction({ active: index }));
    };

    return (
        <SingleList
            dataSource={items}
            renderItem={item => <MarkTableListItem {...item} />}
            onItemClick={handleItemClick}
            activeIndex={active}
        />
    );
};
