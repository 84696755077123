import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    expandStepAction,
    selectionInfoAction,
    tipAction,
    tableColumnsAction,
    saveTableColumnsAction,
    getTemplateDataAction,
    toggleViewAction,
    clearTableColumnTitleAction,
    clearTableColumnContentAction,
} from 'state/actions';
import { RootState } from 'state/store.types';
import { saveTemplateStep } from 'services';
import { ColumnData, StepCommonMethods, TableColumnsItem, TableColumnsState } from 'state/types';
import { stepBase } from './stepBase';
import { TableDataToSave } from 'components/Template/Steps/types';

export const tableColumnsMiddleware = createListenerMiddleware<RootState>();

tableColumnsMiddleware.startListening({
    actionCreator: saveTableColumnsAction,
    effect: async (action, { dispatch, getState }) => {
        const { template } = getState();
        const { id, tableColumns } = template;

        dispatch(tableColumnsAction({ loading: true }));

        const colRules: TableDataToSave['colRules'] = {};
        const fieldRules: TableDataToSave['fieldRules'] = {};

        tableColumns.items.forEach(item => {
            const { startX, endX, endY, startY } = item.value || {};

            if (
                typeof startX !== 'number' ||
                typeof endX !== 'number' ||
                typeof endY !== 'number' ||
                typeof startY !== 'number'
            ) {
                return;
            }

            if (item.contentIds?.length || item.titleId) {
                fieldRules[item.fieldId] = {
                    contentIds: item.contentIds,
                    titleId: item.titleId,
                    label: item.label,
                    pageNum: item.pageNum,
                    propertyName: item.propertyName,
                };
            }

            colRules[item.fieldId] = { startX, endX, endY, startY };
        });

        await saveTemplateStep(id, 'STEP_4', {
            table: {
                colRules,
                fieldRules,
            },
        });

        dispatch(tableColumnsAction({ loading: false }));
        dispatch(getTemplateDataAction());
        dispatch(expandStepAction(-1));
        dispatch(toggleViewAction(false));
    },
});

tableColumnsMiddleware.startListening({
    actionCreator: tableColumnsAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { tableColumns: data } = template;
        const { selection, active } = data;

        if (typeof payload.active !== 'undefined' && data.items.length) {
            if (payload.active !== null && !data.items[payload.active].value) {
                dispatch(tableColumns.action({ selection: 'column' }));
            } else if (!['content', 'title'].includes(selection)) {
                dispatch(selectionInfoAction(null));
                dispatch(tipAction(null));
            }
        }

        if (typeof payload.selection !== 'undefined' && data.items.length && data.active !== null) {
            const activeItem = data.items[data.active];

            if (payload.selection === 'column') {
                dispatch(selectionInfoAction({ type: 'area', text: 'column' }));
                dispatch(tipAction(`Drag to mark **${activeItem.title}** column`));
            }

            if (payload.selection === 'title') {
                dispatch(selectionInfoAction({ type: 'box', text: 'title' }));
                dispatch(tipAction(`Click to mark **${activeItem.title}** column title`));
            }

            if (payload.selection === 'content') {
                dispatch(selectionInfoAction({ type: 'box', text: 'value', multiple: true }));
                dispatch(tipAction(`Click to mark **${activeItem.title}** column value`));
            }
        }

        if (payload.items) {
            const newState: Partial<TableColumnsState> = {
                valid: tableColumns.validate(payload.items),
            };

            if (selection === 'column') {
                newState.active = tableColumns.nextItemIndex(payload.items, active);
            }

            dispatch(tableColumns.action(newState));
        }
    },
});

tableColumnsMiddleware.startListening({
    actionCreator: clearTableColumnTitleAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const items = tableColumns.itemsCopy(template.tableColumns.items);

        items[payload].titleId = null;

        dispatch(tableColumns.action({ items, selection: 'title' }));
    },
});

tableColumnsMiddleware.startListening({
    actionCreator: clearTableColumnContentAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const items = tableColumns.itemsCopy(template.tableColumns.items);

        items[payload].contentIds = [];
        items[payload].extractedContentIds = [];

        dispatch(tableColumns.action({ items, selection: 'content' }));
    },
});

export const tableColumns: StepCommonMethods<TableColumnsState> = {
    ...stepBase,

    stepName: 'tableColumns',
    action: tableColumnsAction,

    initState(dispatch, template) {
        const { extractedFields, table } = template;

        const items: TableColumnsItem[] = this.getExtractedFieldsData(template, true).map(field => {
            const columnData: ColumnData[] = extractedFields
                .filter(({ id }) => id === field.fieldId)
                .sort((a, b) => a.row - b.row)
                .map(item => ({
                    mapping: item.extractionMapping,
                    content: item.content,
                }));

            const contentIds = columnData
                .flatMap(item => item.mapping?.content.map(({ index }) => index))
                .filter(index => typeof index === 'number') as number[];

            const colRule = table.colRules?.find(item => item.field.id === field.fieldId);

            return {
                ...field,
                value: colRule || null,
                columnData,
                contentIds: table.fieldRules?.includes(field.fieldId) ? contentIds : [],
                extractedContentIds: contentIds,
            };
        });

        dispatch(this.action({ items }));
    },
    stepChange(dispatch, state) {
        this.handleStepChange(dispatch, state.tableColumns.items);
    },
    selectionChange(dispatch, state, { area, boxes }) {
        const { active, selection } = state.tableColumns;
        const { currentPage } = state;
        const items = this.itemsCopy(state.tableColumns.items);

        if (typeof active !== 'number') {
            return;
        }

        if (area) {
            items[active].value = area;
        }

        if (boxes?.length && selection === 'title') {
            items[active].titleId = boxes[0].id;
        }

        if (boxes?.length && selection === 'content') {
            items[active].contentIds = boxes.map(({ id }) => id);
        }

        items[active].pageNum = currentPage;

        const newState: Partial<TableColumnsState> = { items };

        if (selection === 'title' && !items[active].contentIds?.length) {
            newState.selection = 'content';
        }

        if (selection === 'content') {
            newState.selection = 'column';
        }

        dispatch(this.action(newState));
    },
    clearValue(dispatch, state, id) {
        const { active } = state.tableColumns;
        const items = this.itemsCopy(state.tableColumns.items);

        if (typeof active === 'number') {
            items[id || active].value = null;
            items[id || active].titleId = null;
            items[id || active].contentIds = [];
        }

        dispatch(this.action({ items }));
    },
    nextItemIndex(items, active) {
        if (typeof active === 'number' && items[active + 1]) {
            if (!this.validateItem(items[active + 1])) {
                return active + 1;
            }
        }

        return stepBase.nextItemIndex.call(this, items);
    },
};
