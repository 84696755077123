import { saveAs } from 'file-saver';

import { downloadTranslationsTable, uploadTranslations } from 'services/settings.service';

export const uploadFile = async (
    file: File | null,
    override: boolean,
    setError: (error: boolean) => void
) => {
    if (!file) {
        return;
    }

    const result = await uploadTranslations({
        file,
        override,
    });

    if (result === null) {
        setError(true);
    }
};

export const downloadFile = async () => {
    const file = await downloadTranslationsTable();

    if (!file) {
        return;
    }
    const blob = new Blob([file as Blob], {
        type: 'text/csv',
    });

    saveAs(blob, 'translation_table.csv');
};
