import { Checkbox, Form, Select } from 'antd';

import { Block } from 'components/atomic';
import { SettingsData } from '../types';

export const OutputOptions = () => {
    return (
        <Block title="Output format options" description="Select format for output">
            <Form.Item<SettingsData> label="Output format" name="outputType" required={false}>
                <Select options={outputOptions} placeholder="Select output type" />
            </Form.Item>
            <Form.Item<SettingsData> name="outputAsTable" required={false} valuePropName="checked">
                <Checkbox>Output as table</Checkbox>
            </Form.Item>
        </Block>
    );
};

const outputOptions = [{ value: 'JSON' }, { value: 'XML' }, { value: 'CSV' }];
