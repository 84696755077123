import { Spin } from 'antd';

import { ContainerCenter } from '../Container/ContainerCenter';

export const Loading = () => {
    return (
        <ContainerCenter className="loading">
            <Spin />
        </ContainerCenter>
    );
};
