import { createReducer } from '@reduxjs/toolkit';

import { setSelectedTypeAction, setShowAddressTableAction } from 'state/actions';

import { UIState } from 'state/types';

const initialState: UIState = {
    selectedType: null,
    showAddressTable: false,
};

export const uiReducer = createReducer(initialState, builder => {
    builder
        .addCase(setSelectedTypeAction, (state, { payload }) => {
            state.selectedType = payload;
        })
        .addCase(setShowAddressTableAction, (state, { payload }) => {
            state.showAddressTable = payload;
        });
});
