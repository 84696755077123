import { AmountFilter, DateFilter, Page, PageHeader } from 'components/Page';
import { DocumentsList } from 'components/Documents/DocumentsList';
import { Divider } from 'components/atomic';
import { ImportButton } from 'components/ImportButton';

export const DocumentsPage = () => {
    return (
        <Page pageId="documents">
            <PageHeader title="Imported Docs">
                <AmountFilter />
                <DateFilter />
                <Divider vertical />
                <ImportButton text="Manual Import" />
            </PageHeader>
            <DocumentsList />
        </Page>
    );
};
