import { Pagination } from 'antd';
import { useTemplate } from '../useTemplate.hook';
import { setPageAction } from 'state/actions';

export const FilePagination = ({ pages }: Props) => {
    const { dispatch, currentPage } = useTemplate();

    const handleChange = (page: number) => {
        dispatch(setPageAction(page - 1));
    };

    return (
        <Pagination
            current={currentPage + 1}
            total={pages}
            pageSize={1}
            hideOnSinglePage
            className="file-pagination"
            onChange={handleChange}
        />
    );
};

interface Props {
    pages: number;
}
