import { Input, InputProps } from 'antd';
import { SearchIcon } from '../Icon/Icon';

export const SearchInput = (props: InputProps) => {
    return (
        <Input
            className="search-input"
            allowClear
            prefix={
                <div className="search-icon">
                    <SearchIcon />
                </div>
            }
            placeholder="Search"
            {...props}
        />
    );
};
