import { ImportButton } from 'components/ImportButton';
import { OverviewLayout } from 'components/Layouts';
import { ExtractionAmount, Imports, Kpi, Performance } from 'components/Overview';
import { DateFilter, Page, PageHeader } from 'components/Page';
import { OverviewDataHelper } from './OverviewDataHelper';

export const OverviewPage = () => {
    return (
        <Page pageId="overview">
            <PageHeader title="Overview">
                <DateFilter />
                <ImportButton text="Manual import" />
            </PageHeader>
            <OverviewDataHelper />
            <OverviewLayout
                kpi={<Kpi />}
                list={<Imports />}
                right={
                    <>
                        <Performance />
                        <ExtractionAmount />
                    </>
                }
            />
        </Page>
    );
};
