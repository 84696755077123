export enum routes {
    login = '/login',
    overview = '/overview',
    singup = '/signup',
    errorQueue = '/error-queue',
    documents = '/documents',
    templates = '/templates',
    users = '/users',
    systemSettings = '/system-settings',
    addressTable = '/address-table',
    error = '/error',
}

export enum routeParams {
    templateId = 'templateId',
    documentId = 'documentId',
}
