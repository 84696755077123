import { apiRoutes } from 'services';
import { usePageData } from 'components/Page';
import { GenericDocsList, GenericDocsListProps } from 'components/GenericDocsList';
import { AddressTableData, AddressTableDataResponse } from './types';
import { getColumns } from './getColumns';

export const AddressTable = () => {
    const { data, loading } = usePageData<AddressTableDataResponse>(apiRoutes.addressTable);

    const listProps: GenericDocsListProps<AddressTableData> = {
        columns: getColumns(),
        data,
        loading,
        title: 'All Addresses',
        type: 'overview',
        showTitle: true,
    };

    return <GenericDocsList {...listProps} />;
};
