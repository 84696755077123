import classNames from 'classnames';

import { LogoImage } from './LogoImage';
import { LogoSize, LogoType } from './types';
import { Link } from 'react-router-dom';

export const Logo = ({ className, type = 'default', size = 'normal' }: Props) => {
    const logoClassName = classNames('logo', `logo-type-${type}`, className);

    return (
        <Link className={logoClassName} to="/">
            <LogoImage size={size} />
            <span className="logo-text">DOConvert</span>
        </Link>
    );
};

interface Props extends WithClassName {
    type?: LogoType;
    size?: LogoSize;
}
