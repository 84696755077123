import { Select } from 'antd';

import { usePageContext } from '../context';

export const AmountFilter = () => {
    const {
        itemsAmount = AMOUNT_FILTER_DEFAULT_VALUE,
        setItemsAmount,
        setDataPage,
    } = usePageContext();

    const handleOnChange = (value: number) => {
        setItemsAmount(value);
        setDataPage(1);
    };

    return (
        <Select
            className="filter"
            options={filterOptions}
            onChange={handleOnChange}
            defaultValue={itemsAmount}
        />
    );
};

export const AMOUNT_FILTER_DEFAULT_VALUE = 10;
export const AMOUNT_FILTER_MAX_VALUE = 50;

const filterOptions = [
    { value: AMOUNT_FILTER_MAX_VALUE, label: 'Show All' },
    { value: 5, label: 'Show 5' },
    { value: AMOUNT_FILTER_DEFAULT_VALUE, label: 'Show 10' },
    { value: 25, label: 'Show 25' },
];
