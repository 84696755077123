import { UserRole } from 'components/Users/types';
import { useUserData } from 'hooks/user.hooks';

export const Guard = ({ children, roles }: Props): JSX.Element => {
    const { role } = useUserData();

    if (!roles.includes(role)) {
        return <></>;
    }

    return <>{children}</>;
};

interface Props extends WithChildren {
    roles: UserRole[] | UserRole;
}
