import { Button } from 'antd';

export const FormListItem = ({ title, onEditClick }: Props) => {
    return (
        <div className="form-list-item" onClick={onEditClick}>
            <div className="form-list-item-title">{title}</div>
            <Button type="link" size="small" className="form-list-item-edit">
                Edit
            </Button>
        </div>
    );
};

interface Props {
    title: string;
    onEditClick: () => void;
}
