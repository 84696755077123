import { useParams } from 'react-router-dom';

import { Page } from 'components/Page';
import { EmptyTemplate, Template } from 'components/Template';
import { routeParams } from 'navigator';

export const TemplatePage = () => {
    const { templateId } = useParams<routeParams>();

    if (!templateId || isNaN(parseInt(templateId))) {
        return <EmptyTemplate />;
    }

    const id = parseInt(templateId);

    return (
        <Page pageId="template">
            <Template id={id} />
        </Page>
    );
};
