import classNames from 'classnames';

import { Box } from './types';

export const SelectionArea = ({ left, top, width, height, className }: Props) => {
    return (
        <div
            className={classNames('selection-area', className)}
            style={{
                left,
                top,
                paddingTop: '2px',
                paddingRight: '1px',
                paddingLeft: '1px',
                width: width + 'px',
                height: height + 'px',
            }}
        />
    );
};

interface Props extends Box, WithClassName {}
