import { useState } from 'react';

import { DocumentData } from './types';
import { ListActions } from 'components/List';
import { DeleteDocumentModal } from './DeleteDocumentModal';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigator';

export const DocumentsListItemEdit = ({ data }: Props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleViewClick = () => {
        navigate(`${routes.documents}/${data.id}`);
    };

    return (
        <>
            <ListActions onDeleteClick={handleDeleteClick} onViewClick={handleViewClick} />
            <DeleteDocumentModal open={open} setOpen={setOpen} selected={[data]} />
        </>
    );
};

interface Props {
    data: DocumentData;
}
