import { Button, FormInstance } from 'antd';
import { useEffect, useState } from 'react';

import { useDrawer } from 'components/DrawerContainer';
import { FormListItem } from './FormListItem';
import { BaseDataType } from './types';
import { EditListItemForm } from './EditListItemForm';

export const FormList = <T extends BaseDataType>({
    name,
    data,
    children,
    form,
    initialValues,
    getItemTitle,
    onSave,
    onDelete,
}: Props<T>) => {
    const { closeDrawer } = useDrawer();
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    const [showForm, setShowForm] = useState(true);

    const handleAddClick = async () => {
        form.resetFields();
        setSelectedItem(null);
        setShowForm(true);
    };

    const handleEditClick = (item: T) => {
        setShowForm(true);
        form.setFieldsValue(item);
        setSelectedItem(item);
    };

    const handleItemFinishEdit = async (item: T) => {
        setLoading(true);

        await onSave(item, selectedItem?.id);

        form.resetFields();
        setSelectedItem(null);
        setShowForm(false);
        setLoading(false);
    };

    const handleItemDelete = async () => {
        if (selectedItem) {
            setLoading(true);

            await onDelete(selectedItem, selectedItem.id);

            form.resetFields();
            setShowForm(false);
            setLoading(false);
        }
    };

    const handleItemCancel = () => {
        setSelectedItem(null);
        setShowForm(false);
        form.resetFields();
    };

    useEffect(() => {
        setSelectedItem(null);
    }, [initialValues]);

    return (
        <div className="form-list">
            {showForm && (
                <EditListItemForm
                    name={name}
                    form={form}
                    selectedItem={selectedItem}
                    initialValues={initialValues}
                    onFinish={handleItemFinishEdit}
                    onCancel={handleItemCancel}
                    onDelete={handleItemDelete}>
                    {children}
                </EditListItemForm>
            )}
            <div className="form-list-items">
                {data.map((item, index) => (
                    <FormListItem
                        title={getItemTitle(item, index)}
                        onEditClick={() => handleEditClick(item)}
                        key={index.toString()}
                    />
                ))}
            </div>
            {!showForm && (
                <Button
                    type="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={handleAddClick}>
                    Add more
                </Button>
            )}
            <Button type="link" onClick={closeDrawer}>
                Cancel
            </Button>
        </div>
    );
};

interface Props<T> extends WithChildren {
    form: FormInstance<T>;
    data: T[];
    name: string;
    initialValues?: Record<string, any>;
    getItemTitle: (item: T, index: number) => string;
    onSave: (item: T, id?: number) => Promise<void>;
    onDelete: (item: T, id: number) => Promise<void>;
}
