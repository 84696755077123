import { ColumnsType } from 'antd/es/table';

import { AddressTableData } from './types';
import { AddressTableListItemEdit } from './AddressTableListItemEdit';
import { ListCelText, ListIconCelText, ListCelLink } from 'components/List';
import moment from 'moment';
import { AvatarIcon } from '../atomic';

export const getColumns = (): ColumnsType<AddressTableData> => [
    {
        key: 'customerNumber',
        dataIndex: 'customerNumber',
        render: (value: string) => (
            <ListIconCelText text={value} subtext="Customer number" icon={<AvatarIcon />} />
        ),
    },
    {
        key: 'address',
        dataIndex: 'address',
        render: (value: string, item) => (
            <ListCelText
                text={value}
                subtext={`Created ${moment(item.createdAt).format('MMM DD, YYYY')}`}
            />
        ),
    },
    {
        key: 'code',
        dataIndex: 'code',
        render: value => <ListCelText text={value} subtext="Code" />,
    },
    {
        key: 'originTemplate',
        dataIndex: 'originTemplate',
        render: (value, item) => (
            <ListCelLink
                text={value}
                subtext="Template Origin"
                path={`/templates/${item.originTemplateId}`}
            />
        ),
    },
    {
        key: 'edit',
        render: (value, data) => {
            return <AddressTableListItemEdit data={data} />;
        },
    },
];
