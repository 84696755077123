export enum LicenseActionTypes {
    SET_LICENSE = 'SET_LICENSE',
}

export interface LicenseState {
    data: LicenseResponseData | null;
}

export interface LicenseResponseData {
    licenseExpired: boolean;
    expirationDate: string;
    remainingDays: number;
    extractionCount: number;
    extractionRemaining: number;
    extractionLimit: number;
    countType: 'PER_DOC';
    usersLimit: number;
    company: string;
}
