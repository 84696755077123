import { Button, Modal, Table } from 'antd';
import { useState } from 'react';

import { SelectedFailedImports } from './types';
import { getColumns } from './getColumns';
import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { apiRoutes, deleteDocumentsBulk } from 'services';

export const DeleteErrorQueueModal = ({ open, setOpen, selected, clearSelection }: Props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        setOpen(false);
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);

        await deleteDocumentsBulk(selected.map(item => item.id));
        dispatch(getListenerAction(apiRoutes.documentsErrors));
        clearSelection && clearSelection();

        setLoading(false);
        setOpen(false);
    };

    return (
        <Modal
            title="Delete failed imports"
            open={open}
            centered
            width={580}
            onCancel={handleCancel}
            className="delete-error-queue-modal"
            footer={[
                <Button key="cancel" onClick={handleCancel} type="link">
                    Cancel
                </Button>,
                <Button
                    key="delete"
                    type="primary"
                    loading={loading}
                    onClick={handleDelete}
                    className="document-delete-button">
                    Delete document
                </Button>,
            ]}>
            <Table
                columns={getColumns('delete')}
                dataSource={selected}
                rowKey={item => item.id.toString()}
                className="documents-list-delete"
                pagination={false}
                showHeader={false}
            />
        </Modal>
    );
};

interface Props extends SelectedFailedImports {
    open: boolean;
    setOpen: (value: boolean) => void;
}
