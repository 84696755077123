import { useTemplate } from 'components/Template/useTemplate.hook';
import { HighlightValue } from 'state/types';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setPageAction } from 'state/actions';

export const useHighlight = (
    value: HighlightValue | null,
    pageNum?: number
): Pick<React.DOMAttributes<HTMLDivElement>, 'onMouseOver' | 'onMouseOut'> => {
    const { setHighlight, currentPage } = useTemplate();
    const dispatch = useAppDispatch();

    return {
        onMouseOver: () => {
            setHighlight(value);
            if (typeof pageNum === 'number' && pageNum !== currentPage) {
                dispatch(setPageAction(pageNum));
            }
        },
        onMouseOut: () => {
            setHighlight(null);
        },
    };
};
