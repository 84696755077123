import { Button } from 'antd';

import { SyncIcon } from 'components/atomic';
import { syncTranslationsTable } from '../../../services';
import { useState } from 'react';

export const SyncButton = () => {
    const [loading, setLoading] = useState(false);

    const handleSyncTranslationTable = async () => {
        setLoading(true);
        await syncTranslationsTable();
        setLoading(false);
    };

    return (
        <Button
            className="translations-sync"
            size="middle"
            onClick={handleSyncTranslationTable}
            disabled={loading}
            loading={loading}
            icon={
                <div className="icon">
                    <SyncIcon />
                </div>
            }>
            Sync table
        </Button>
    );
};
