import { useEffect, useRef, useState } from 'react';

import { TextBoxData, SelectionEventHandler, SelectionTypeProps } from '../types';
import { SelectionHandler } from '../SelectionHandler';
import { findHoverBox } from '../helpers';
import { SelectionArea } from '../SelectionArea';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { useSelection } from '../context';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setSelectionValue } from 'state/actions';
import { useBoxes } from '../useBoxes.hook';

export const FieldsSelection = ({ rect, scale }: SelectionTypeProps) => {
    const dispatch = useAppDispatch();
    const { template, selectionInfo, currentPage } = useTemplate();
    const { setConfirmMenu } = useSelection();
    const [selected, setSelected] = useState<TextBoxData[]>([]);
    const [hover, setHover] = useState<TextBoxData | null>(null);
    const shiftPressed = useRef(false);
    const selectedRef = useRef<TextBoxData[]>([]);
    const clipboardHelper = useRef<string>('');
    const boxes = useBoxes(template, currentPage, rect, scale);
    const multiple = useRef(!!selectionInfo?.multiple);
    multiple.current = !!selectionInfo?.multiple;

    const handleSelectionChange: SelectionEventHandler = event => {
        if (!event.hitSpotArea) {
            return;
        }

        const box = findHoverBox(boxes, event.mouse);

        if (box) {
            clipboardHelper.current = box.value.text;
        }

        setHover(box);
    };

    const handleSelectionEnd: SelectionEventHandler = event => {
        const box = findHoverBox(boxes, event.mouse);

        if (shiftPressed.current && multiple.current && box) {
            setHover(null);
            setSelected(prev => [...prev, box]);
            selectedRef.current = [...selectedRef.current, box];
            return;
        }

        if (box) {
            setHover(null);
            setSelected([box]);

            setConfirmMenu({
                onConfirm: () => {
                    let selectionValue = [box.value];
                    if (selectedRef.current.length > 0) {
                        selectedRef.current = [...selectedRef.current, box];
                        selectionValue = selectedRef.current.map(item => item.value);
                    }

                    dispatch(setSelectionValue({ boxes: selectionValue }));
                    setSelected([]);
                },
                position: {
                    left: event.mouse.left,
                    top: box.scaleBox.top + box.scaleBox.height,
                },
            });
        } else {
            setSelected([]);
            setConfirmMenu(null);
            selectedRef.current = [];
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Shift') {
            event.preventDefault();
            shiftPressed.current = true;
        }

        if (event.key === 'c' && (event.metaKey || event.ctrlKey) && clipboardHelper.current) {
            navigator.clipboard.writeText(clipboardHelper.current);
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Shift') {
            shiftPressed.current = false;

            if (!selectionInfo?.multiple || !selectedRef.current.length) {
                return;
            }

            const lastBox = selectedRef.current[selectedRef.current.length - 1];

            setConfirmMenu({
                onConfirm: () => {
                    dispatch(
                        setSelectionValue({ boxes: selectedRef.current.map(item => item.value) })
                    );
                    setSelected([]);
                    selectedRef.current = [];
                },
                position: {
                    left: lastBox.scaleBox.left,
                    top: lastBox.scaleBox.top + lastBox.scaleBox.height,
                },
            });
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [selectionInfo]);

    return (
        <>
            <SelectionHandler
                trackMouseMove
                scale={scale}
                rect={rect}
                onSelectionChange={handleSelectionChange}
                onSelectionEnd={handleSelectionEnd}
            />
            {hover && <SelectionArea {...hover.scaleBox} />}
            {selected.map(item => (
                <SelectionArea key={item.value.id.toString()} {...item.scaleBox} />
            ))}
        </>
    );
};
