import { Progress, Spin } from 'antd';

import { Block } from 'components/atomic';
import { EmailLink, emails } from 'components/EmailLink';
import { useOverview } from '../useOverview.hook';

export const ExtractionAmount = () => {
    const { data, loading } = useOverview();

    if (loading) {
        return <Spin />;
    }

    const { extractionCount, extractionLimit, extractionOcrCount } = data;
    const percent = Math.round((extractionCount * 100) / extractionLimit);

    return (
        <Block
            title={`${percent}% Credits used`}
            className="overview-block"
            description={
                extractionCount +
                ' pages were loaded. ' +
                (extractionOcrCount > 0 ? extractionOcrCount + ' were scanned with OCR.' : '')
            }>
            <div>
                {extractionCount} of {extractionLimit} loaded{' '}
            </div>
            <Progress percent={percent} status="normal" showInfo={false} strokeColor="#FF7474" />
            <EmailLink emailContent={emails.upgradeAccount}>Upgrade Account</EmailLink>
        </Block>
    );
};
