import { useState } from 'react';
import { Input, MenuProps, Select } from 'antd';

import { useTemplate } from 'components/Template/useTemplate.hook';
import { ItemMenu, SingleListItemTitle } from 'components/atomic';
import { clearValueAction, setColumnsToTemplateValueAction } from 'state/actions';
import { ColumnsToTemplateItem, CommonStepItem } from 'state/types';
import { PostProcessRuleLabel } from '../../PostProcessRules/PostProcessRuleLabel';

export const MarkColumnsToTemplateItem = ({ id, title, value }: Props) => {
    const { dispatch, columnsToTemplate, id: templateId, template } = useTemplate();
    const { selectedValues } = columnsToTemplate;
    const [showFreeText, setShowFreeText] = useState(!!value?.freeText);

    const handleClearValue = () => {
        dispatch(clearValueAction(id));
    };

    const handleSelectChange = (newValue: string) => {
        dispatch(setColumnsToTemplateValueAction({ id, value: { ...value, selected: newValue } }));
    };

    const handleToggleFreeTextClick = () => {
        setShowFreeText(prev => {
            if (prev) {
                dispatch(
                    setColumnsToTemplateValueAction({
                        id,
                        value: { ...value, freeText: undefined },
                    })
                );
            }

            return !prev;
        });
    };

    const handleFreeTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        dispatch(
            setColumnsToTemplateValueAction({ id, value: { ...value, freeText: e.target.value } })
        );
    };

    const items: MenuProps['items'] = [
        {
            key: 'clear',
            label: <div onClick={handleClearValue}>Clear value</div>,
        },
        {
            key: 'free-text',
            label: (
                <div onClick={handleToggleFreeTextClick}>
                    {showFreeText ? 'Remove' : 'Add'} free text
                </div>
            ),
        },
        {
            key: 'post-process-rules',
            label: (
                <PostProcessRuleLabel templateId={templateId} fieldId={id} label={title} isTable />
            ),
        },
    ];

    const selectOptions: SelectOptions<string> =
        Array.from(new Set(template?.table.dynamicOptions))
            ?.filter(item => {
                return !(item !== value?.selected && selectedValues.find(v => v.selected === item));
            })
            .map((item, index) => ({
                label: item,
                value: item,
                key: item + index,
            })) || [];

    return (
        <>
            <SingleListItemTitle>{title}</SingleListItemTitle>
            <div className="item-right">
                <div className="columns-to-template-item-right">
                    <Select
                        className="columns-to-template-select"
                        options={selectOptions}
                        placeholder="Field in template"
                        value={value?.selected}
                        onChange={handleSelectChange}
                    />
                    {showFreeText && (
                        <Input
                            placeholder="Free text"
                            className="columns-to-template-free-text"
                            value={value?.freeText}
                            onChange={handleFreeTextChange}
                        />
                    )}
                </div>
                <ItemMenu items={items} />
            </div>
        </>
    );
};

interface Props extends CommonStepItem<ColumnsToTemplateItem> {}
