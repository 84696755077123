import { Button, DatePicker, Form } from 'antd';
import { Moment } from 'moment';

import { CommonForm, Divider } from 'components/atomic';
import { DateRange } from './helpers';

export const CustomDateRange = ({ onApplyChanges }: Props) => {
    const [form] = Form.useForm<FormValues>();
    const from = Form.useWatch('from', form);
    const to = Form.useWatch('to', form);

    const handleSubmit = (values: FormValues) => {
        onApplyChanges({
            from: values.from.startOf('day').toISOString(),
            to: values.to.endOf('day').toISOString(),
        });
    };

    const disableTo = (current: any) => {
        return from && current < from;
    };

    const disableFrom = (current: any) => {
        return to && current > to;
    };

    return (
        <>
            <Divider />

            <CommonForm
                form={form}
                name="date-range"
                className="custom-date-range"
                onFinish={handleSubmit}>
                <Form.Item<FormValues> label="From date" name="from" required={false} rules={rules}>
                    <DatePicker format={format} disabledDate={disableFrom} />
                </Form.Item>

                <Form.Item<FormValues> label="To date" name="to" required={false} rules={rules}>
                    <DatePicker format={format} disabledDate={disableTo} />
                </Form.Item>

                <Button type="primary" size="middle" htmlType="submit">
                    Apply
                </Button>
            </CommonForm>
        </>
    );
};

const rules = [{ required: true, message: 'Please enter date' }];

const format = 'DD/MM/YYYY';

interface Props {
    onApplyChanges: (values: DateRange) => void;
}

interface FormValues {
    from: Moment;
    to: Moment;
}
