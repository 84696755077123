import { Button, Form } from 'antd';
import { useState } from 'react';

import { useDrawer } from 'components/DrawerContainer';
import { CommonForm, Container } from 'components/atomic';
import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { reUploadPdf } from 'services';
import { UploadDocument, UploadFormData } from 'components/Templates/FormItems';
import { getPath } from '../../useTemplate.hook';
import { disableStepChangeAction } from 'state/actions';

export const ReloadTemplateForm = ({ id }: WithId) => {
    const dispatch = useAppDispatch();
    const { closeDrawer } = useDrawer();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<UploadFormData>();

    const handleFinish = async (values: UploadFormData) => {
        setLoading(true);

        if (values.files?.length) {
            await reUploadPdf(id, values.files[0]);
        }

        dispatch(disableStepChangeAction(true));
        dispatch(getListenerAction(getPath(id)));
        handleClose();
        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    return (
        <CommonForm name="importDocumentForm" onFinish={handleFinish} form={form}>
            <Container className="form-container">
                <UploadDocument />
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Upload new document
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};
