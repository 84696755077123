import { Radio, RadioChangeEvent } from 'antd';

import { MarkTableAuto } from './MarkTableAuto';
import { TableMode } from 'state/types';
import { MarkTableManual } from './MarkTableManual';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { tableModeAction } from 'state/actions';

export const MarkTable = () => {
    const { dispatch, tableMode } = useTemplate();

    const handleModeChange = (event: RadioChangeEvent) => {
        dispatch(tableModeAction(event.target.value));
    };

    return (
        <div>
            <Radio.Group
                options={markTableOptions}
                defaultValue={tableMode}
                optionType="button"
                onChange={handleModeChange}
            />
            <div className="mark-table-content">
                {tableMode === 'auto' ? <MarkTableAuto /> : <MarkTableManual />}
            </div>
        </div>
    );
};

const markTableOptions: MarkTableOption[] = [
    { value: 'auto', label: 'Auto identify' },
    { value: 'manual', label: 'Manual setup' },
];

interface MarkTableOption {
    value: TableMode;
    label: string;
}
