import classNames from 'classnames';

import { PasswordValidationRule, validateByRule } from './helpers';
import { CheckIcon } from 'components/atomic';

export const PasswordRule = ({ rule, value }: Props) => {
    const isValid = validateByRule(rule, value);
    const iconClassName = classNames('password-rule-icon', isValid && 'valid');

    return (
        <div className="password-rule">
            <div className={iconClassName}>
                <CheckIcon />
            </div>
            <div className="password-rule-text">{rule.text}</div>
        </div>
    );
};

interface Props {
    value?: string;
    rule: PasswordValidationRule;
}
