import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    clearValueAction,
    disabledStepsAction,
    disableStepChangeAction,
    expandStepAction,
    getTemplateDataAction,
    saveTemplateAction,
    saveTemplateCompleteAction,
    saveTemplateIncompleteAction,
    selectionInfoAction,
    setSelectionValue,
    setTemplateDataAction,
    tableModeAction,
    tipAction,
} from 'state/actions';
import { AppDispatch, RootState } from 'state/store.types';
import { headerAndFooter } from './templateSteps/headerAndFooter.middleware';
import { generalFields } from './templateSteps/generalFields.middleware';
import { table } from './templateSteps/table.middleware';
import { columnsToTemplate } from './templateSteps/columnsToTemplate.middleware';
import { tableColumns } from './templateSteps/tableColumns.middleware';
import { StepNumber, TemplateState } from 'state/types';
import { getTemplateData } from 'services';

export const templateMiddleWare = createListenerMiddleware<RootState>();

templateMiddleWare.startListening({
    actionCreator: getTemplateDataAction,
    effect: async ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const newData = await getTemplateData(template.id);

        if (!newData) {
            return;
        }

        dispatch(setTemplateDataAction({ ...newData, ...payload }));
    },
});

templateMiddleWare.startListening({
    actionCreator: setTemplateDataAction,
    effect: ({ payload }, { dispatch, getState }) => {
        if (!payload || payload.disableRefresh) {
            return;
        }

        const tableStepCompleted =
            payload.currentStep === 'COMPLETE' || payload.currentStep === 'STEP_4';

        headerAndFooter.initState(dispatch, payload);
        generalFields.initState(dispatch, payload, getState().template);
        table.initState(dispatch, payload);
        columnsToTemplate.initState(dispatch, payload);
        tableColumns.initState(dispatch, payload);

        const { template } = getState();

        if (tableStepCompleted && payload.dynamicTable === false) {
            dispatch(tableModeAction('manual'));
        }

        const disabledSteps: TemplateState['disabled'] = [false, false, false, true];

        if (!payload.table.position) {
            dispatch(disabledStepsAction(disabledSteps));
        } else {
            disabledSteps[3] = false;
            dispatch(disabledStepsAction(disabledSteps));
        }

        if (!template.disableStepChange) {
            getInitialStep(dispatch, template);
        }

        dispatch(disableStepChangeAction(false));
    },
});

templateMiddleWare.startListening({
    actionCreator: saveTemplateAction,
    effect: (action, { dispatch, getState }) => {
        const { template } = getState();

        const validation = validateAllSteps(template);
        const invalidStep = validation.indexOf(false);

        if (invalidStep === -1) {
            dispatch(saveTemplateCompleteAction());
        } else {
            dispatch(saveTemplateIncompleteAction());
        }
    },
});

templateMiddleWare.startListening({
    actionCreator: expandStepAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { tableMode } = template;

        switch (payload) {
            case 0:
                headerAndFooter.stepChange(dispatch, template);
                break;

            case 1:
                generalFields.stepChange(dispatch, template);
                break;

            case 2:
                table.stepChange(dispatch, template);
                break;

            case 3:
                if (tableMode === 'auto') {
                    columnsToTemplate.stepChange(dispatch, template);
                } else {
                    tableColumns.stepChange(dispatch, template);
                }
                break;

            default:
                dispatch(selectionInfoAction(null));
                dispatch(tipAction(null));
                break;
        }
    },
});

templateMiddleWare.startListening({
    actionCreator: setSelectionValue,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();

        switch (template.currentStep) {
            case 'headerAndFooter':
                headerAndFooter.selectionChange(dispatch, template, payload);
                break;
            case 'generalFields':
                generalFields.selectionChange(dispatch, template, payload);
                break;
            case 'table':
                table.selectionChange(dispatch, template, payload);
                break;
            case 'columnsToTemplate':
                columnsToTemplate.selectionChange(dispatch, template, payload);
                break;
            case 'tableColumns':
                tableColumns.selectionChange(dispatch, template, payload);
                break;
            default:
                break;
        }
    },
});

templateMiddleWare.startListening({
    actionCreator: clearValueAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();

        switch (template.currentStep) {
            case 'headerAndFooter':
                headerAndFooter.clearValue(dispatch, template, payload);
                break;
            case 'generalFields':
                generalFields.clearValue(dispatch, template, payload);
                break;
            case 'table':
                table.clearValue(dispatch, template, payload);
                break;
            case 'columnsToTemplate':
                columnsToTemplate.clearValue(dispatch, template, payload);
                break;
            case 'tableColumns':
                tableColumns.clearValue(dispatch, template, payload);
                break;
            default:
                break;
        }
    },
});

const validateAllSteps = (state: TemplateState) => {
    return [
        true, // skip validation for header and footer step
        generalFields.validate(state.generalFields.items),
        table.validate(state.table.items),
        state.tableMode === 'auto'
            ? columnsToTemplate.validate(state.columnsToTemplate.items)
            : tableColumns.validate(state.tableColumns.items),
    ];
};

const getInitialStep = (dispatch: AppDispatch, state: TemplateState) => {
    const currentStep = state.template?.currentStep || 'STEP_1';
    let step = stepToNumberMap[currentStep];

    dispatch(expandStepAction(step));
};

const stepToNumberMap: Record<StepNumber, number> = {
    STEP_1: 1, // Skip first step on template creation
    STEP_2: 1,
    STEP_3: 2,
    STEP_4: 3,
    COMPLETE: -1,
};
