import { createContext, useContext } from 'react';

export interface GenericDocsListContext<T = any> {
    selected: T[];
    clearSelection?: () => void;
}

export const genericDocsListContext = createContext({} as GenericDocsListContext);
export const GenericDocsListContextProvider = genericDocsListContext.Provider;

export const useGenericDocsListContext = <T>() =>
    useContext<GenericDocsListContext<T>>(genericDocsListContext);
