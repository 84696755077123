import { Radio, RadioChangeEvent } from 'antd';

import { FileIcon, OverviewIcon } from 'components/atomic';
import { ViewToggleOption } from './ViewToggleOption';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { toggleViewAction } from 'state/actions';

export const ViewToggle = () => {
    const { dispatch, fileView } = useTemplate();

    const handleChange = (event: RadioChangeEvent) => {
        dispatch(toggleViewAction(event.target.value));
    };

    return (
        <Radio.Group
            className="view-toggle"
            value={fileView}
            options={options}
            optionType="button"
            onChange={handleChange}
        />
    );
};

const options: SelectOptions<boolean, React.ReactNode> = [
    {
        label: <ViewToggleOption text="Sample doc" icon={<FileIcon />} />,
        value: true,
    },
    {
        label: <ViewToggleOption text="Preview" icon={<OverviewIcon />} />,
        value: false,
    },
];
