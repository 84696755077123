import { Button, Modal } from 'antd';

import { useTemplate } from 'components/Template/useTemplate.hook';
import { SaveIcon } from 'components/atomic';

export const SaveIncompleteModal = ({ open, setOpen, onClose }: Props) => {
    const { requiredFieldsFulfilled } = useTemplate();

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            centered
            width={580}
            onCancel={handleCancel}
            className="save-template-modal"
            footer={[
                <Button key="fix" type="primary" size="small" onClick={handleCancel}>
                    Fix template
                </Button>,
                <Button key="leave" type="primary" size="small" onClick={onClose}>
                    Leave it like this
                </Button>,
            ]}>
            <div className="save-template-modal-content">
                <div className="save-template-icon-big">
                    <SaveIcon />
                </div>
                <div className="save-template-title">Template saved successfully!</div>
                {!requiredFieldsFulfilled && (
                    <div className="save-template-description">
                        Notice that some of the required fields weren't set in this template!
                    </div>
                )}
            </div>
        </Modal>
    );
};

interface Props {
    open: boolean;
    setOpen: (value: boolean) => void;
    onClose: () => void;
}
