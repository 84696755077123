import { Spin } from 'antd';

import { OutputOptions } from './OutputOptions/OutputOptions';
import { Integration } from './Integration/Integration';
import { TranslationTable } from './TranslationTable/TranslationTable';
import { Notifications } from './Notifications/Notifications';
import { SettingsData } from './types';
import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { saveData } from './helpers';
import { CommonForm } from 'components/atomic';

export const SystemSettingsForm = () => {
    const { data, loading } = useData<SettingsData>(apiRoutes.settings);

    const handleValuesChange = async (values: Partial<SettingsData>) => {
        saveData(values);
    };

    return (
        <Spin spinning={loading}>
            {!!data && (
                <CommonForm
                    name="system-settings"
                    className="system-settings-form"
                    initialValues={{ ...defaultValues, ...data }}
                    onValuesChange={handleValuesChange}
                    submitOnEnter={false}>
                    <OutputOptions />
                    <Integration />
                    <TranslationTable />
                    <Notifications />
                </CommonForm>
            )}
        </Spin>
    );
};

const defaultValues: Partial<SettingsData> = {
    outputType: 'JSON',
    outputAsTable: true,
    outputLocation: null,
    outputNameSeparator: null,
    notificationEmail: '',
    integrationSystemId: null,
    integrationSystems: [],
    integrationConnectionProperties: [],
};
