import { useEffect, useState } from 'react';

import { SearchInput } from '../atomic';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { setSearchQueryAction } from 'state/actions';

export const Search = () => {
    const [value, setValue] = useState<string>('');
    const dispatch = useAppDispatch();
    const searchQuery = useAppSelector(state => state.search.searchQuery);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setValue(e.target.value);
        dispatch(setSearchQueryAction(e.target.value));
    };

    useEffect(() => {
        if (!searchQuery) {
            setValue('');
        }
    }, [searchQuery]);

    return (
        <div className="search">
            <SearchInput value={value} onChange={handleChange} />
        </div>
    );
};
