import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

import { apiRoutes } from './api.routes';
import { socketRoutes } from './socket.routes';
import config from 'configs';

export class SocketService {
    private client: Client | null = null;
    private listeners: Listener[] = [];

    connect() {
        const url = `${config.apiUrl}/${apiRoutes.websocket}`;
        this.client = new Client({
            brokerURL: url,
            connectHeaders: {},
            reconnectDelay: 500,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            logRawCommunication: false,
            webSocketFactory: () => {
                return SockJS(url);
            },
            onConnect: () => {
                if (this.client?.connected) {
                    this.client.subscribe(socketRoutes.messages, message => {
                        try {
                            const response = JSON.parse(message.body);
                            this.listeners.forEach(listener => listener(response));
                        } catch (error) {}
                    });
                }
            },
        });

        this.client.activate();
    }

    subscribe(listener: Listener) {
        this.listeners.push(listener);

        return () => {
            this.listeners = this.listeners.filter(item => item !== listener);
        };
    }
}

const socket = new SocketService();

type Listener = (message: SocketMessage) => void;

export interface SocketMessage {
    entityId: number;
    status: SocketMessageStatus;
    message: string;
    details: string;
    date: string;
}

export type SocketMessageStatus = 'IMPORT_FAIL' | 'IMPORT_SUCCESS';

export { socket };
