import { api, apiRoutes } from './api.service';
import { IntegrationConnectionProperties, SettingsData } from 'components/SystemSettings/types';
import { UploadTranslationsData } from 'components/SystemSettings/TranslationTable/types';
import { AddressTableData } from 'components/AddressTable';

export const getSystemSettings = async () => {
    return await api.fetch<SettingsData>(apiRoutes.settings);
};

export const getIntegrationSystemProps = async (id: number) => {
    return await api.fetch<IntegrationConnectionProperties[]>(`${apiRoutes.settings}/${id}`);
};

export const testConnection = async (
    params?: Record<string, IntegrationConnectionProperties['value']>
) => {
    return await api.fetch<string>(apiRoutes.testConnection, 'post', params);
};

export const saveSettings = async (data: Partial<SettingsData>) => {
    return await api.fetch<SettingsData>(apiRoutes.settings, 'put', data);
};

export const uploadTranslations = async (data: UploadTranslationsData) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('override', Boolean(data.override).toString());

    return await api.fetch<SettingsData>(apiRoutes.translationsTable, 'post', formData, {
        'Content-Type': 'multipart/form-data',
    });
};

export const downloadTranslationsTable = async () => {
    return await api.fetch<File>(apiRoutes.translationsTableDownload, 'post');
};

export const syncTranslationsTable = async () => {
    return await api.fetch<File>(apiRoutes.translationsTableSync, 'post');
};

export const addAddressTable = async (data: AddressTableData) => {
    return await api.fetch<void>(apiRoutes.addressTable, 'post', [data]);
};

export const editAddressTable = async (id: number, data: AddressTableData) => {
    return await api.fetch<void>(apiRoutes.addressTable, 'post', [{ ...data, id }]);
};

export const deleteAddressTable = async (id: number) => {
    return await api.fetch<void>(`${apiRoutes.addressTable}/${id}`, 'delete');
};

export const getUserImage = async (): Promise<string> => {
    const image = await api.getFile(apiRoutes.userImage);

    if (!image || !image.size) {
        return '';
    }

    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string));
        reader.readAsDataURL(image);
    });
};

export const uploadUserImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await api.fetch<void>(apiRoutes.userImage, 'post', formData, {
        'Content-Type': 'multipart/form-data',
    });
};
