import { useState } from 'react';
import { Button, Checkbox, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Block } from 'components/atomic';
import { UploadContent } from './UploadContent';
import { uploadFile } from './helpers';
import { DownloadButton } from './DownloadButton';
import { SyncButton } from './SyncButton';

const { Dragger } = Upload;

export const TranslationTable = () => {
    const [file, setFile] = useState<File | null>(null);
    const [override, setOverride] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.originFileObj) {
            setFile(info.file.originFileObj);
            setError(false);
        }
    };

    const handleOverrideChange = (event: CheckboxChangeEvent) => {
        setOverride(event.target.checked);
    };

    const handleUpload = async () => {
        await uploadFile(file, override, setError);
        setFile(null);
    };

    return (
        <Block title="Translation table" description={tooltip}>
            <p>Upload transaction table</p>
            <div>
                <DownloadButton />
                <SyncButton />
            </div>
            <Dragger
                name="file"
                multiple={false}
                onChange={handleChange}
                className="translations-upload"
                showUploadList={false}
                accept=".csv,.xls"
                customRequest={() => {}}>
                <UploadContent fileName={file?.name} error={error} />
            </Dragger>
            <p>
                <Checkbox checked={override} onChange={handleOverrideChange}>
                    Override existing data
                </Checkbox>
            </p>
            <Button type="primary" disabled={!file} onClick={handleUpload}>
                Save
            </Button>
        </Block>
    );
};

const tooltip = `csv of the format: (term,translation,entityId)\n
selecting override will delete all existing entries`;
