import { HighlightValue } from 'state/types';
import { PageDataTextBox } from '../types';
import { Box, TextBoxData, Point, SelectionEvent, Size } from './types';

const MAX_SPOT_DISTANCE = 30;

export const calculateEvent = (
    view: Box,
    viewportStart: Point,
    event: MouseEvent,
    scale: Size
): SelectionEvent => {
    const eventPoint: Point = { left: event.clientX, top: event.clientY };

    const start: Point = {
        left: Math.min(Math.max(viewportStart.left - view.left, 0), view.width),
        top: Math.min(Math.max(viewportStart.top - view.top, 0), view.height),
    };

    const end: Point = {
        left: Math.min(Math.max(eventPoint.left - view.left, 0), view.width),
        top: Math.min(Math.max(eventPoint.top - view.top, 0), view.height),
    };

    const mouse: Point = { left: end.left, top: end.top };

    const mouseScale: Point = {
        left: getScaleLeft(view, scale, mouse.left),
        top: getScaleTop(view, scale, mouse.top),
    };

    if (start.left > end.left) {
        const { left: startLeft } = start;
        const { left: endLeft } = end;

        start.left = endLeft;
        end.left = startLeft;
    }

    if (start.top > end.top) {
        const { top: startTop } = start;
        const { top: endTop } = end;

        start.top = endTop;
        end.top = startTop;
    }

    const size: Size = {
        width: end.left - start.left,
        height: end.top - start.top,
    };

    const scaleStart: Point = {
        left: getScaleLeft(view, scale, start.left),
        top: getScaleTop(view, scale, start.top),
    };

    const scaleEnd: Point = {
        left: getScaleLeft(view, scale, end.left),
        top: getScaleTop(view, scale, end.top),
    };

    const scaleSize: Size = {
        width: getScaleLeft(view, scale, size.width),
        height: getScaleTop(view, scale, size.height),
    };

    const hitSpotArea = pointHitSpotArea(view, eventPoint);

    return {
        start,
        end,
        size,
        scaleStart,
        scaleEnd,
        scaleSize,
        viewSize: view,
        mouse,
        mouseScale,
        hasSize: !!(size.width || size.height),
        hitSpotArea,
        originalEvent: event,
    };
};

export const getScaleLeft = (view: Size, scale: Size, value: number) => {
    return (scale.width / view.width) * value;
};

export const getScaleTop = (view: Size, scale: Size, value: number) => {
    return (scale.height / view.height) * value;
};

export const getViewValueLeft = (view: Size, scale: Size, value: number) => {
    return (view.width / scale.width) * value;
};

export const getViewValueTop = (view: Size, scale: Size, value: number) => {
    return (view.height / scale.height) * value;
};

export const pointHitSpotArea = (view: Box, point: Point): boolean => {
    const relative: Point = {
        left: point.left - view.left,
        top: point.top - view.top,
    };

    return (
        relative.left + MAX_SPOT_DISTANCE >= 0 &&
        relative.top + MAX_SPOT_DISTANCE >= 0 &&
        relative.left - MAX_SPOT_DISTANCE <= view.width &&
        relative.top - MAX_SPOT_DISTANCE <= view.height
    );
};

export const pointHitBox = (box: Box, point: Point): boolean => {
    const hit =
        point.left >= box.left &&
        point.left <= box.left + box.width &&
        point.top >= box.top &&
        point.top <= box.top + box.height;

    return hit;
};

export const findHoverBox = (boxes: TextBoxData[], point: Point) => {
    const hoverBoxes = boxes.filter(box => pointHitBox(box.scaleBox, point));

    if (hoverBoxes.length) {
        return hoverBoxes[hoverBoxes.length - 1];
    }

    return null;
};

export const mouseOverConfirmMenu = (event: MouseEvent) => {
    if (event.target instanceof HTMLElement) {
        return !!event.target.closest('.confirm-menu');
    }

    return false;
};

export const getScaleTextBox = (view: Size, scale: Size, box: PageDataTextBox): Box => {
    return {
        left: getViewValueLeft(view, scale, box.boxX),
        top: getViewValueTop(view, scale, box.boxY),
        width: getViewValueLeft(view, scale, box.width),
        height: getViewValueTop(view, scale, box.fontSize),
    };
};

export const getHighlightBox = (highlight: HighlightValue | null, rect: Size, scale: Size) => {
    const value = (highlight?.value && highlight.value[0]) || 0;

    const box: Box = {
        left: 0,
        top: 0,
        height: getViewValueTop(rect, scale, value),
        width: rect.width,
    };

    if (highlight?.type === 'footer') {
        box.top = rect.height - getViewValueTop(rect, scale, value);
    }

    if (highlight?.type === 'area' && highlight.area) {
        const { area } = highlight;
        box.left = getViewValueLeft(rect, scale, area.startX || 0);
        box.top = getViewValueTop(rect, scale, area.startY || 0);
        box.width = getViewValueLeft(rect, scale, (area.endX || 0) - (area.startX || 0));
        box.height = getViewValueTop(rect, scale, (area.endY || 0) - (area.startY || 0));
    }

    return box;
};
