import { useState } from 'react';

import { PdfViewer } from 'components/PdfViewer';
import { useTemplate } from '../useTemplate.hook';
import { FileZoom, MAX_ZOOM, MIN_ZOOM, ZOOM_STEP } from './FileZoom';
import { FilePagination } from './FilePagination';
import { round } from './helpers';
import { SelectionLayer } from '../Selection/SelectionLayer';

const CANVAS_WIDTH = 600;

export const FileView = ({ file }: Props) => {
    const { currentPage } = useTemplate();
    const [zoom, setZoom] = useState(1);
    const [pages, setPages] = useState(0);
    const zoomWidth = CANVAS_WIDTH * zoom;

    const handleDocumentLoad = (pagesNumber: number) => {
        setPages(pagesNumber);
    };

    const handleZoomIn = () => {
        if (zoom < MAX_ZOOM) {
            setZoom(prev => round(prev + ZOOM_STEP));
        }
    };

    const handleZoomOut = () => {
        if (zoom > MIN_ZOOM) {
            setZoom(prev => round(prev - ZOOM_STEP));
        }
    };

    const handleTrackClick = (newZoom: number) => {
        setZoom(newZoom);
    };

    return (
        <div className="file-view">
            <div className="file-view-pdf-area">
                <div className="file-view-pdf-container" style={{ width: zoomWidth + 60 }}>
                    <div className="file-view-pdf-border" style={{ width: zoomWidth + 2 }}>
                        <PdfViewer
                            file={file}
                            width={zoomWidth}
                            currentPage={currentPage + 1}
                            onDocumentLoad={handleDocumentLoad}
                        />
                        <SelectionLayer />
                    </div>
                </div>
            </div>
            <div className="file-view-shadow" />
            <FileZoom
                onPlusClick={handleZoomIn}
                onMinusClick={handleZoomOut}
                onTrackClick={handleTrackClick}
                zoom={zoom}
            />
            <FilePagination pages={pages} />
        </div>
    );
};

interface Props {
    file: Blob;
}
