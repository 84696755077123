import { LoginLayout } from 'components/Layouts';
import { LoginContainer } from 'components/Login/LoginContainer';

export const LoginPage = () => {
    return (
        <LoginLayout>
            <LoginContainer />
        </LoginLayout>
    );
};
