import { ColumnsType } from 'antd/es/table';

import { ExtractedField } from './ExtractedField';
import { ExtractedRemoveLine } from './ExtractedRemoveLine';
import { ExtractedFieldTableData, ExtractedFieldTableDataItem } from './types';
import { TemplateFieldResponseData } from 'components/Templates';

export const getColumns = (
    header: string[],
    tableFields: TemplateFieldResponseData[],
    editable: boolean
) => {
    const columns: ColumnsType<ExtractedFieldTableData> = header.map((headerTitle, dataIndex) => ({
        key: tableFields[dataIndex].fieldId,
        dataIndex: tableFields[dataIndex].fieldId,
        title: headerTitle,
        render: (item: ExtractedFieldTableDataItem, row, index) => (
            <ExtractedField field={item.field} extractedValue={item.value} rowIndex={index} />
        ),
    }));

    if (editable) {
        columns.push({
            key: 'delete',
            render: (id, item, index) => <ExtractedRemoveLine lineIndex={index} />,
        });
    }

    return columns;
};
