export const PageHeader = ({ title, children }: Props) => {
    return (
        <div className="page-header">
            <h1>{title}</h1>
            <div className="page-header-controls">{children}</div>
        </div>
    );
};

interface Props extends WithChildren {
    title: string;
}
