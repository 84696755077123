import { ErrorQueueList } from 'components/ErrorQueue';
import { ImportButton } from 'components/ImportButton';
import { AmountFilter, DateFilter, Page, PageHeader } from 'components/Page';
import { Divider } from 'components/atomic';
import { useSearchParams } from 'react-router-dom';
import { useDrawer } from '../../components/DrawerContainer';
import { useEffect } from 'react';
import { MatchTemplateForm } from '../../components/MatchTemplate/MatchTemplateForm';
import { closeAppNotification } from '../../state/actions';
import { store } from '../../state/store';

export const ErrorQueuePage = () => {
    let [searchParams] = useSearchParams();
    const { openDrawer } = useDrawer();

    useEffect(() => {
        const str = searchParams.get('id') || '';
        if (str) {
            store.dispatch(closeAppNotification());
            openDrawer('Set template', <MatchTemplateForm documentId={parseInt(str)} />);
        }
    }, [searchParams]);

    return (
        <Page pageId="errorQueue">
            <PageHeader title="Error Queue">
                <AmountFilter />
                <DateFilter />
                <Divider vertical />
                <ImportButton text="Manual Import" />
            </PageHeader>
            <ErrorQueueList />
        </Page>
    );
};
