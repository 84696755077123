import { pdfjs, Document, Page } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import { Loading } from 'components/atomic';

export const PdfViewer = ({ file, width, currentPage, onDocumentLoad }: Props) => {
    const handleDocumentLoad: OnDocumentLoadSuccess = document => {
        onDocumentLoad && onDocumentLoad(document.numPages);
    };

    return (
        <Document file={file} onLoadSuccess={handleDocumentLoad} loading={<Loading />}>
            <Page
                pageNumber={currentPage}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={width}
            />
        </Document>
    );
};

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

interface Props {
    currentPage: number;
    file: Blob | null;
    width?: number;
    onDocumentLoad?: (pagesNumber: number) => void;
}
