import { ErrorIcon, NewInvoiceIcon } from 'components/atomic';

export const iconsMap = {
    error: (
        <div className="notification-icon error">
            <ErrorIcon />
        </div>
    ),
    newInvoice: (
        <div className="notification-icon new-invoice">
            <NewInvoiceIcon />
        </div>
    ),
};
