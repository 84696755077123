import { useEffect, useRef, useState } from 'react';

export const useRect = () => {
    const [rect, setRect] = useState<DOMRect>();
    const ref = useRef<HTMLDivElement>(null);

    const handleRectChange = () => {
        setRect(ref.current?.getBoundingClientRect());
    };

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(handleRectChange);

        resizeObserver.observe(ref.current);
        handleRectChange();

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref.current]);

    return {
        rect,
        ref,
    };
};
