import { MenuProps, Popconfirm } from 'antd';

import { ItemMenu } from 'components/atomic';
import { getListenerAction } from 'hooks/data.hooks';
import { useAppDispatch } from 'hooks/redux.hooks';
import { apiRoutes, removeLine } from 'services';
import { useExtractedPreview } from './context';

export const ExtractedRemoveLine = ({ lineIndex }: Props) => {
    const { orderId } = useExtractedPreview();
    const dispatch = useAppDispatch();

    const handleClick = async () => {
        if (orderId) {
            await removeLine(orderId, lineIndex);
            dispatch(getListenerAction(`${apiRoutes.document}/${orderId}`));
        }
    };

    const items: MenuProps['items'] = [
        {
            key: 'delete',
            label: (
                <Popconfirm
                    placement="leftTop"
                    title="Delete row"
                    description="Are you sure to delete this row?"
                    onConfirm={handleClick}
                    okText="Yes"
                    cancelText="No">
                    <div>Delete row</div>
                </Popconfirm>
            ),
        },
    ];

    return (
        <div className="extracted-remove-line-icon">
            <ItemMenu items={items} />
        </div>
    );
};

interface Props {
    lineIndex: number;
}
