import { Button, Form, Input, Radio, Select } from 'antd';
import { useState } from 'react';

import { UserFormData, UserRole, UserStatus, UsersListItem } from '../types';
import { PasswordRules } from './PasswordRules';
import { passwordFormRule } from './helpers';
import { CommonForm, Container } from 'components/atomic';
import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { addUser, editUser } from 'services';
import { useDrawer } from 'components/DrawerContainer';

export const UserForm = ({ action, data }: Props) => {
    const dispatch = useAppDispatch();
    const { closeDrawer } = useDrawer();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const addAction = action === 'add';

    const onFinish = async (values: UserFormData) => {
        setLoading(true);

        if (addAction) {
            await addUser(values);
        } else if (data?.id) {
            await editUser(values, data?.id);
        }

        dispatch(getListenerAction(apiRoutes.users));

        handleClose();

        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    return (
        <CommonForm
            name="userForm"
            initialValues={data || { status: 'ACTIVE' }}
            onFinish={onFinish}
            noValidate={true}
            form={form}>
            <Container className="form-container">
                <Form.Item<UserFormData>
                    label="Full name"
                    name="name"
                    required={false}
                    rules={[{ required: true, message: 'Please enter user name' }]}>
                    <Input autoFocus />
                </Form.Item>

                <Form.Item<UserFormData>
                    label="Email"
                    name="email"
                    required={false}
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter valid email',
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item<UserFormData>
                    label="Role"
                    name="role"
                    required={false}
                    rules={[{ required: true, message: 'Please select role' }]}>
                    <Select<UserRole> placeholder="Select role" options={roleOptions} />
                </Form.Item>

                {addAction && (
                    <>
                        <Form.Item<UserFormData>
                            label="Set password"
                            name="password"
                            required={false}
                            rules={[passwordFormRule]}>
                            <Input />
                        </Form.Item>

                        <PasswordRules />
                    </>
                )}

                <Form.Item<UserFormData> name="status" label="Set status">
                    <Radio.Group options={statusOptions} optionType="button" />
                </Form.Item>
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Save
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

const roleOptions: RoleOption[] = [
    { value: 'ROLE_VIEWER', label: 'Viewer' },
    { value: 'ROLE_EDITOR', label: 'Editor' },
];

const statusOptions: StatusOption[] = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'NOT_ACTIVE', label: 'Disabled' },
];

interface Props {
    action: FormAction;
    data?: UsersListItem;
}

interface RoleOption {
    value: UserRole;
    label: string;
}

interface StatusOption {
    value: UserStatus;
    label: string;
}
