import { Button, Form, Input } from 'antd';
import { useState } from 'react';

import { CommonForm, Container } from 'components/atomic';
import { AddDocumentTemplateData } from './types';
import { useDrawer } from 'components/DrawerContainer';
import { UploadDocument } from '../FormItems/UploadDocument';
import { apiRoutes, cloneTemplate } from 'services';
import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';

export const TemplateCloneForm = ({ data, id }: Props) => {
    const { closeDrawer } = useDrawer();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleFinish = async (values: AddDocumentTemplateData) => {
        setLoading(true);
        await cloneTemplate(id, values);
        if (data) {
            dispatch(getListenerAction(`${apiRoutes.templatesByType}/${data.type}`));
        }

        handleClose();

        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    const initialValues: Partial<AddDocumentTemplateData> = {
        files: [],
        type: data?.type,
        ...data,
    };

    return (
        <CommonForm
            name="documentTemplateForm"
            onFinish={handleFinish}
            initialValues={initialValues}
            form={form}>
            <Container className="form-container">
                <Form.Item<AddDocumentTemplateData>
                    label="Template name"
                    name="name"
                    required={false}
                    rules={[{ required: true, message: 'Please enter template name' }]}>
                    <Input autoFocus />
                </Form.Item>

                <UploadDocument />
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Save
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

interface Props {
    data: Partial<AddDocumentTemplateData>;
    id: number;
}
