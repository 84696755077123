import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    expandStepAction,
    tableAction,
    saveTableAction,
    selectionInfoAction,
    tipAction,
    getTemplateDataAction,
    clearTableSeparatorTitle,
    clearTableSeparatorValue,
} from 'state/actions';
import { RootState } from 'state/store.types';
import { saveTemplateStep } from 'services';
import { StepCommonMethods, TableState } from 'state/types';
import { stepBase } from './stepBase';
import { TableDataToSave } from 'components/Template/Steps/types';

export const tableMiddleware = createListenerMiddleware<RootState>();

tableMiddleware.startListening({
    actionCreator: saveTableAction,
    effect: async ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { id, table, tableMode } = template;

        dispatch(tableAction({ loading: true }));

        const dataToSave: Partial<TableDataToSave> = {
            startTitleId: table.items[0].value?.toString(),
            startTitlePage: table.items[0].pageNum,
            endTitleId: table.items[1].value?.toString(),
            endTitlePage: table.items[1].pageNum,
            useRowLines: tableMode === 'auto' ? table.useRowLinesAuto : table.useRowLinesManual,
            separateByColumnBoundaries: table.separateByColumnBoundaries,
        };

        if (table.useColumnRowSeparator) {
            dataToSave.columnRowSeparator = table.columnRowSeparator;
        }

        if (
            table.addSeparator &&
            table.separator?.contentIds?.length &&
            table.separator?.titleId !== null
        ) {
            dataToSave.header = table.separator;
        }

        await saveTemplateStep(id, 'STEP_3', {
            table: dataToSave,
            dynamicTable: !!payload?.dynamicTable,
        });

        dispatch(tableAction({ loading: false }));

        if (!payload?.saveAll) {
            dispatch(getTemplateDataAction());
            dispatch(expandStepAction(3));
        }
    },
});

tableMiddleware.startListening({
    actionCreator: tableAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();

        if (payload.active === 0) {
            dispatch(tipAction('Click to mark **Table start**'));
            dispatch(selectionInfoAction({ type: 'box', text: 'table start' }));
        }

        if (payload.active === 1) {
            dispatch(tipAction('Click to mark **Table end**'));
            dispatch(selectionInfoAction({ type: 'box', text: 'table end' }));
        }

        if (payload.active === null && !template.table.separatorSelection) {
            dispatch(tipAction(null));
            dispatch(selectionInfoAction(null));
        }

        if (payload.useRowLinesManual === true) {
            dispatch(table.action({ useColumnRowSeparator: false }));
        }

        if (payload.useColumnRowSeparator === true) {
            dispatch(table.action({ useRowLinesManual: false }));
        }

        if (payload.items) {
            const valid = table.validate(payload.items);
            const active = table.nextItemIndex(payload.items);
            dispatch(table.action({ valid, active }));
        }

        if (payload.separatorSelection === 'title') {
            dispatch(tipAction('Click to mark **Docs separator title**'));
            dispatch(selectionInfoAction({ type: 'box', text: 'docs separator title' }));
        }

        if (payload.separatorSelection === 'value') {
            dispatch(tipAction('Click to mark **Docs separator value**'));
            dispatch(selectionInfoAction({ type: 'box', text: 'table separator value' }));
        }
    },
});

tableMiddleware.startListening({
    actionCreator: clearTableSeparatorTitle,
    effect: (action, { dispatch, getState }) => {
        const { template } = getState();
        const separator = { ...template.table.separator };

        if (!separator) {
            return;
        }

        separator.titleId = null;

        dispatch(table.action({ separator, separatorSelection: 'title' }));
    },
});

tableMiddleware.startListening({
    actionCreator: clearTableSeparatorValue,
    effect: (action, { dispatch, getState }) => {
        const { template } = getState();
        const separator = { ...template.table.separator };

        if (!separator) {
            return;
        }

        separator.contentIds = [];
        separator.value = '';

        dispatch(table.action({ separator, separatorSelection: 'value' }));
    },
});

export const table: StepCommonMethods<TableState> = {
    ...stepBase,

    stepName: 'table',
    action: tableAction,

    initState(dispatch, template) {
        const {
            position,
            columnRowSeparator = null,
            useRowLines,
            headerRule,
            separateByColumnBoundaries,
        } = template.table;
        const useColumnRowSeparator = columnRowSeparator !== null;
        const items = [
            {
                title: 'Mark table start',
                value: typeof position?.startTable.id === 'number' ? position?.startTable.id : null,
                pageNum: position?.startTable.page || 0,
                id: 0,
            },
            {
                title: 'Mark table end',
                value: typeof position?.endTable.id === 'number' ? position?.endTable.id : null,
                pageNum: position?.endTable.page || 0,
                id: 1,
            },
        ];

        dispatch(
            this.action({
                items,
                columnRowSeparator,
                separateByColumnBoundaries,
                useColumnRowSeparator,
                useRowLinesAuto: useRowLines === null ? true : useRowLines,
                useRowLinesManual: !!useRowLines,
                addSeparator: !!headerRule?.contentIds,
                separator: headerRule,
            })
        );
    },
    stepChange(dispatch, state) {
        this.handleStepChange(dispatch, state.table.items);
    },
    selectionChange(dispatch, state, { boxes }) {
        if (!boxes?.length) {
            return;
        }

        const separator = { ...state.table.separator } || {};

        if (state.table.separatorSelection === 'title') {
            separator.titleId = boxes[0].id;
            dispatch(this.action({ separator, separatorSelection: 'value' }));
            return;
        }

        if (state.table.separatorSelection === 'value') {
            separator.contentIds = [boxes[0].id];
            separator.value = boxes[0].text;
            dispatch(this.action({ separator, separatorSelection: null }));
            return;
        }

        const box = boxes[0];
        const { currentPage } = state;
        const { active } = state.table;
        const items = this.itemsCopy(state.table.items);

        if (typeof active === 'number') {
            items[active].value = box.id;
            items[active].pageNum = currentPage;
        }

        dispatch(this.action({ items }));
    },
    clearValue(dispatch, state, id) {
        const { active } = state.table;
        const items = this.itemsCopy(state.table.items);

        if (typeof active === 'number') {
            items[id || active].value = null;
        }

        dispatch(this.action({ items }));
    },
    validate(items) {
        return this.validateItem(items[0]);
    },
};
