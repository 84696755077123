import { usePageContext, usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { FailedImportData, FailedImportsResponse } from './types';
import { ErrorQueueListControls } from './ErrorQueueListControls';
import { getColumns } from './getColumns';
import {
    DocumentsListType,
    GenericDocsList,
    GenericDocsListProps,
} from 'components/GenericDocsList';

export const ErrorQueueList = ({ type = 'documents' }: Props) => {
    const { data, loading } = usePageData<FailedImportsResponse>(
        apiRoutes.documentsErrors,
        type !== 'overview'
    );
    const { dateRange } = usePageContext();

    const listProps: GenericDocsListProps<FailedImportData> = {
        columns: getColumns(type),
        controls: <ErrorQueueListControls />,
        data,
        loading,
        type,
        title: `Failed imports ${dateRange} ${data ? `(${data.total})` : ''}`,
    };

    return <GenericDocsList {...listProps} />;
};

interface Props {
    type?: DocumentsListType;
}
