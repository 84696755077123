import { StorageItem } from 'global/storage-items';
import { apiRoutes } from './api.routes';
import { api } from './api.service';
import { UserData } from 'state/types';
import { UserFormData } from 'components/Users/types';
import config from 'configs';

export const loginWithEmail = async ({ email, password }: EmailLoginValues) => {
    return await api.fetch<LoginResponse>(apiRoutes.login, 'post', {
        email,
        password,
        accountKey: config.accountKey,
    });
};

export const refreshToken = async () => {
    return await api.fetch<LoginResponse>(apiRoutes.refreshToken, 'post');
};

export const loginWithToken = () => {
    return localStorage.getItem(StorageItem.token);
};

export const getUserDetails = async () => {
    return await api.fetch<UserDetailsResponse>(apiRoutes.userDetails);
};

export const addUser = async (data: UserFormData) => {
    return await api.fetch<void>(apiRoutes.users, 'post', data);
};

export const editUser = async (data: UserFormData, userId: number) => {
    return await api.fetch<void>(`${apiRoutes.users}/${userId}`, 'put', data);
};

export interface EmailLoginValues {
    email?: string;
    password?: string;
}

export interface LoginResponse {
    accessToken: string;
    tokenType: string;
}

export type UserDetailsResponse = UserData;
