import { Tooltip, Upload, UploadProps, message } from 'antd';

import { useUserData } from 'hooks/user.hooks';
import { DefaultImage } from './DefaultImage';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { uploadUserImageAction } from 'state/actions';

const MAX_SIZE_MB = 3;

export const UserImage = () => {
    const { role } = useUserData();
    const dispatch = useAppDispatch();
    const image = useAppSelector(state => state.user.image);
    const isAdmin = role === 'ROLE_ADMIN';

    const handleChange: UploadProps['onChange'] = info => {
        const { file } = info;

        if (!file.size || !file.type || !file.originFileObj) {
            return;
        }

        const properFormat = ['image/jpeg', 'image/png'].includes(file.type);
        const exceedSize = file.size / 1024 / 1024 > MAX_SIZE_MB;

        if (!properFormat) {
            message.error('You can only upload JPG/PNG file');
            return;
        }

        if (exceedSize) {
            message.error(`Image must smaller than ${MAX_SIZE_MB}MB`);
            return;
        }

        dispatch(uploadUserImageAction(file.originFileObj));
    };
    const imgComponent = image ? (
        <img className="user-info-image" src={image} style={{ width: '100%' }} />
    ) : (
        <DefaultImage />
    );

    return (
        <div className="user-info-image-container">
            <Tooltip
                title={isAdmin && `Upload image not grater than ${MAX_SIZE_MB}MB`}
                className="tooltip-container">
                {isAdmin ? (
                    <Upload
                        multiple={false}
                        name="avatar"
                        listType="picture-circle"
                        className="user-info-image-upload"
                        showUploadList={false}
                        accept=".png,.jpg,.jpeg"
                        customRequest={() => {}}
                        onChange={handleChange}>
                        {imgComponent}
                    </Upload>
                ) : (
                    imgComponent
                )}
            </Tooltip>
        </div>
    );
};
