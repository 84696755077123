import { Logo } from 'components/Logo/Logo';

export const LoginLayout = ({ children }: WithChildren) => {
    return (
        <div className="login-layout">
            <div className="login-content">
                <div className="login-header">
                    <Logo className="login-logo" />
                </div>
                <div className="login-component-wrapper">{children}</div>
            </div>
        </div>
    );
};
