import { Button, Input, Select } from 'antd';
import { ExtractedError } from './ExtractedError';

export const ExtractedEdit = ({
    editMode,
    editable,
    value,
    loading,
    error,
    errorReason,
    fieldOptions,
    onChange,
    onSave,
    toggleEditMode,
    onCancel,
}: Props) => {
    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        onChange(event.target.value);
    };

    return editMode ? (
        <>
            {fieldOptions ? (
                <Select
                    options={fieldOptions}
                    value={value}
                    placeholder="Select option"
                    className="extracted-edit-options"
                    onChange={onChange}
                />
            ) : (
                <Input value={value} onChange={handleInputChange} />
            )}

            <span className="extracted-edit-controls">
                <Button
                    type="link"
                    size="small"
                    onClick={onSave}
                    disabled={loading}
                    loading={loading}>
                    Save
                </Button>
                <Button type="link" size="small" onClick={onCancel}>
                    Cancel
                </Button>
            </span>
        </>
    ) : (
        <>
            {error ? <ExtractedError text={errorReason} value={value} /> : <span>{value}</span>}
            {editable && (
                <Button
                    type="link"
                    size="small"
                    className="extracted-edit-button"
                    onClick={toggleEditMode}
                    disabled={loading}
                    loading={loading}>
                    Edit
                </Button>
            )}
        </>
    );
};

interface Props {
    editMode: boolean;
    editable: boolean;
    value: string;
    loading: boolean;
    error?: boolean;
    errorReason?: string;
    fieldOptions?: SelectOptions<string>;
    onChange: (value: string) => void;
    onSave: () => void;
    onCancel: () => void;
    toggleEditMode: () => void;
}
