import classNames from 'classnames';

export const StepTitle = ({ index, children, active }: Props) => {
    const className = classNames(active && 'step-title-active');

    return (
        <span className="step-title">
            <span className={className}>Step {index + 1}: </span>
            {children}
        </span>
    );
};

interface Props extends WithChildren {
    index: number;
    active: boolean;
}
