import { createListenerMiddleware } from '@reduxjs/toolkit';

import { api, loginWithToken } from 'services';
import { socket } from 'services/socket.service';
import { initializeAction, initializeFinishAction, setAuthStateAction } from 'state/actions';
import { RootState } from 'state/store.types';

export const initMiddleWare = createListenerMiddleware<RootState>();

initMiddleWare.startListening({
    actionCreator: initializeAction,
    effect: async (action, { dispatch }) => {
        socket.connect();
        const accessToken = loginWithToken();

        if (!accessToken) {
            dispatch(initializeFinishAction());
            return;
        }

        api.setToken(accessToken);
        dispatch(setAuthStateAction(true));
    },
});
