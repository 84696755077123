export interface FailedImportData extends WithId {
    createdAt: string;
    templateName: string | null;
    templateId: number | null;
    failStatus: FailStatus;
    failStatusMsg: string;
    fixMsg: string | null;
    typeId: number | null;
    typeLabel: string | null;
    documentId: number | null;
    amount: string | number | null;
}

export interface SelectedFailedImports {
    selected: FailedImportData[];
    clearSelection?: () => void;
}

export type FailedImportsResponse = ContentListResponse<FailedImportData>;

export enum FailStatusIndexes {
    READ,
    TYPE_MATCH,
    VALIDATION,
    IMPORT,
}

export type FailStatus = keyof typeof FailStatusIndexes;
