import { Checkbox, Form, Input, Select } from 'antd';

import { Block } from 'components/atomic';
import { NotificationFrequency, SettingsData } from '../types';

export const Notifications = () => {
    return (
        <Block title="Notifications">
            <Form.Item<SettingsData>
                label="Send notifications to Email"
                name="notificationEmail"
                required={false}>
                <Input type="email" placeholder="Please enter your email" />
            </Form.Item>
            <Form.Item<SettingsData>
                label="Send import summary notification"
                name="notificationFrequency"
                required={false}>
                <Select options={frequencyOptions} placeholder="Select notification frequency" />
            </Form.Item>
            <Form.Item<SettingsData> name="notifyOnFail" required={false} valuePropName="checked">
                <Checkbox>Notify on each failed import</Checkbox>
            </Form.Item>
            <Form.Item<SettingsData>
                name="notifyOnSuccess"
                required={false}
                valuePropName="checked">
                <Checkbox>Notify on each success import</Checkbox>
            </Form.Item>
        </Block>
    );
};

const frequencyOptions: SelectOptions<NotificationFrequency> = [
    {
        label: 'Daily',
        value: 'DAILY',
    },
    {
        label: 'Weekly',
        value: 'WEEKLY',
    },
    {
        label: 'Monthly',
        value: 'MONTHLY',
    },
];
