import { Form } from 'antd';

import { passwordRulesConfig } from './helpers';
import { PasswordRule } from './PasswordRule';

export const PasswordRules = () => {
    const form = Form.useFormInstance();
    const password = Form.useWatch('password', form);

    return (
        <div className="password-rules">
            {passwordRulesConfig.map(rule => (
                <PasswordRule rule={rule} value={password} key={rule.id} />
            ))}
        </div>
    );
};
