export const ListCelText = ({ text, subtext }: ListCelTextProps) => {
    return (
        <div>
            <div className="list-cel-text">{text || ' '}</div>
            <div className="list-cel-subtext">{subtext}</div>
        </div>
    );
};

export interface ListCelTextProps {
    text?: string | number;
    subtext?: string | number;
}
