import { useAppDispatch } from 'hooks/redux.hooks';
import { logoutAction } from 'state/actions';

export const Logout = () => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(logoutAction());
    };

    return <div onClick={handleClick}>Logout</div>;
};
