import { useState } from 'react';

import { SelectionArea } from '../SelectionArea';
import { SelectionHandler } from '../SelectionHandler';
import { Box, SelectionEventHandler, SelectionTypeProps } from '../types';
import { useSelection } from '../context';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setSelectionValue } from 'state/actions';

export const FooterSelection = ({ rect, scale }: SelectionTypeProps) => {
    const dispatch = useAppDispatch();
    const { setConfirmMenu } = useSelection();
    const [box, setBox] = useState<Box | null>(null);

    const handleSelectionStart: SelectionEventHandler = event => {
        setConfirmMenu(null);
        setBox({
            left: 0,
            top: event.mouse.top,
            height: event.viewSize.height - event.mouse.top,
            width: event.viewSize.width,
        });
    };

    const handleSelectionChange: SelectionEventHandler = event => {
        setBox({
            left: 0,
            top: event.mouse.top,
            height: event.viewSize.height - event.mouse.top,
            width: event.viewSize.width,
        });
    };

    const handleSelectionEnd: SelectionEventHandler = event => {
        setConfirmMenu({
            position: event.mouse,
            onConfirm: () => {
                dispatch(setSelectionValue({ size: scale.height - event.mouseScale.top }));
                setBox(null);
            },
        });
    };

    return (
        <>
            <SelectionHandler
                scale={scale}
                rect={rect}
                onSelectionStart={handleSelectionStart}
                onSelectionChange={handleSelectionChange}
                onSelectionEnd={handleSelectionEnd}
            />
            {box && <SelectionArea {...box} />}
        </>
    );
};
