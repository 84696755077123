import { createContext, useContext } from 'react';

export interface DrawerContainerContextValues {
    openDrawer: (title: string, content: React.ReactNode) => void;
    closeDrawer: () => void;
}

export const DrawerContainerContext = createContext({} as DrawerContainerContextValues);
export const DrawerContainerProvider = DrawerContainerContext.Provider;

export const useDrawer = () => useContext(DrawerContainerContext);
