import { createReducer } from '@reduxjs/toolkit';

import {
    clearTemplateStateAction,
    columnsToTemplateAction,
    disabledStepsAction,
    disableStepChangeAction,
    expandStepAction,
    generalFieldsAction,
    headerAndFooterAction,
    highlightAction,
    requiredFieldsFulfilledAction,
    selectionInfoAction,
    setPageAction,
    setTemplateDataAction,
    stepChangeAction,
    tableAction,
    tableColumnsAction,
    tableModeAction,
    tipAction,
    toggleViewAction,
} from 'state/actions';
import { CommonStepState, ExpandedState, TemplateState } from 'state/types';

const stepInitialState: CommonStepState<any> = {
    items: [],
    active: 0,
    loading: false,
    valid: false,
};

const initialState: TemplateState = {
    id: 0,
    template: null,
    currentPage: 0,
    expanded: [false, false, false, false],
    disabled: [false, false, false, true],
    selectionInfo: null,
    highlight: null,
    tip: null,
    headerAndFooter: stepInitialState,
    generalFields: {
        ...stepInitialState,
        itemsToDelete: [],
    },
    table: {
        ...stepInitialState,
        columnRowSeparator: null,
        useRowLinesAuto: true,
        useRowLinesManual: false,
        useColumnRowSeparator: false,
        addSeparator: false,
        separator: null,
        separatorSelection: null,
        separateByColumnBoundaries: false,
    },
    columnsToTemplate: {
        ...stepInitialState,
        selectedValues: [],
    },
    tableColumns: {
        ...stepInitialState,
        selection: 'column',
    },
    currentStep: 'headerAndFooter',
    tableMode: 'auto',
    fileView: true,
    requiredFieldsFulfilled: true,
    disableStepChange: false,
};

export const templateReducer = createReducer(initialState, builder => {
    builder
        .addCase(setTemplateDataAction, (state, { payload }) => {
            state.template = payload;
            state.id = payload?.templateId ?? 0;
        })
        .addCase(clearTemplateStateAction, state => {
            state.id = initialState.id;
            state.template = initialState.template;
            state.currentPage = initialState.currentPage;
            state.expanded = initialState.expanded;
            state.disabled = initialState.disabled;
            state.selectionInfo = initialState.selectionInfo;
            state.highlight = initialState.highlight;
            state.tip = initialState.tip;
            state.headerAndFooter = initialState.headerAndFooter;
            state.generalFields = initialState.generalFields;
            state.table = initialState.table;
            state.columnsToTemplate = initialState.columnsToTemplate;
            state.tableColumns = initialState.tableColumns;
            state.currentStep = initialState.currentStep;
            state.tableMode = initialState.tableMode;
            state.fileView = initialState.fileView;
        })
        .addCase(setPageAction, (state, { payload }) => {
            state.currentPage = payload;
        })
        .addCase(expandStepAction, (state, { payload }) => {
            const newState: ExpandedState = [false, false, false, false];
            newState[payload] = true;
            state.expanded = newState;
        })
        .addCase(selectionInfoAction, (state, { payload }) => {
            state.selectionInfo = payload;
        })
        .addCase(highlightAction, (state, { payload }) => {
            state.highlight = payload;
        })
        .addCase(tipAction, (state, { payload }) => {
            state.tip = payload;
        })
        .addCase(stepChangeAction, (state, { payload }) => {
            state.currentStep = payload;
        })
        .addCase(headerAndFooterAction, (state, { payload }) => {
            state.headerAndFooter = { ...state.headerAndFooter, ...payload };
        })
        .addCase(generalFieldsAction, (state, { payload }) => {
            state.generalFields = { ...state.generalFields, ...payload };
        })
        .addCase(tableModeAction, (state, { payload }) => {
            state.tableMode = payload;
        })
        .addCase(tableAction, (state, { payload }) => {
            state.table = { ...state.table, ...payload };
        })
        .addCase(columnsToTemplateAction, (state, { payload }) => {
            state.columnsToTemplate = { ...state.columnsToTemplate, ...payload };
        })
        .addCase(tableColumnsAction, (state, { payload }) => {
            state.tableColumns = { ...state.tableColumns, ...payload };
        })
        .addCase(toggleViewAction, (state, { payload }) => {
            state.fileView = typeof payload === 'boolean' ? payload : !state.fileView;
        })
        .addCase(requiredFieldsFulfilledAction, (state, { payload }) => {
            state.requiredFieldsFulfilled = payload;
        })
        .addCase(disabledStepsAction, (state, { payload }) => {
            state.disabled = payload;
        })
        .addCase(disableStepChangeAction, (state, { payload }) => {
            state.disableStepChange = payload;
        });
});
