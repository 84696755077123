import { combineReducers } from '@reduxjs/toolkit';

import {
    documentReducer,
    initReducer,
    licenseReducer,
    overviewReducer,
    searchReducer,
    templateReducer,
    uiReducer,
    userReducer,
} from './reducers';

export const rootReducer = combineReducers({
    init: initReducer,
    user: userReducer,
    license: licenseReducer,
    template: templateReducer,
    overview: overviewReducer,
    document: documentReducer,
    ui: uiReducer,
    search: searchReducer,
});
