import { Pagination } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { PdfViewer } from 'components/PdfViewer';
import { TemplateData } from 'components/Template';
import { DocumentHighlight } from './DocumentHighlight';
import { Size } from 'components/Template/Selection/types';
import { FileZoom, MAX_ZOOM, MIN_ZOOM, ZOOM_STEP } from 'components/Template/FileView/FileZoom';
import { round } from 'components/Template/FileView/helpers';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { setDocumentCurrentPageAction } from 'state/actions';

const CANVAS_WIDTH = 600;

export const DocumentView = ({ file, document }: Props) => {
    const dispatch = useAppDispatch();
    const currentPage = useAppSelector(state => state.document.currentPage);
    const [pagesNumber, setPagesNumber] = useState(1);
    const [rect, setRect] = useState<DOMRect>();
    const [zoom, setZoom] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const scale: Size = {
        width: document.map.pages[currentPage]?.width ?? 1,
        height: document.map.pages[currentPage]?.height ?? 1,
    };
    const zoomWidth = CANVAS_WIDTH * zoom;

    const handleDocumentLoad = (pages: number) => {
        setPagesNumber(pages);
    };

    const handlePageChange = (page: number) => {
        dispatch(setDocumentCurrentPageAction(page - 1));
    };

    const handleRectChange = () => {
        setRect(containerRef.current?.getBoundingClientRect());
    };

    const handleZoomIn = () => {
        if (zoom < MAX_ZOOM) {
            setZoom(prev => round(prev + ZOOM_STEP));
        }
    };

    const handleZoomOut = () => {
        if (zoom > MIN_ZOOM) {
            setZoom(prev => round(prev - ZOOM_STEP));
        }
    };

    const handleTrackClick = (newZoom: number) => {
        setZoom(newZoom);
    };

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const elem = containerRef.current;
        const scrollingElem = window.document.querySelector('.file-view-pdf-area');
        const resizeObserver = new ResizeObserver(handleRectChange);

        handleRectChange();

        resizeObserver.observe(elem);
        scrollingElem?.addEventListener('scroll', handleRectChange);

        return () => {
            resizeObserver.disconnect();
            scrollingElem?.removeEventListener('scroll', handleRectChange);
        };
    }, [containerRef.current]);

    return (
        <div className="document-view-container">
            <div className="document-view-wrapper">
                <div className="document-view" ref={containerRef}>
                    <PdfViewer
                        onDocumentLoad={handleDocumentLoad}
                        currentPage={currentPage + 1}
                        file={file}
                        width={zoomWidth}
                    />
                    {rect && (
                        <DocumentHighlight
                            currentPage={currentPage}
                            document={document}
                            rect={rect}
                            scale={scale}
                        />
                    )}
                    <Pagination
                        current={currentPage + 1}
                        total={pagesNumber}
                        pageSize={1}
                        hideOnSinglePage
                        className="file-pagination pdf-view"
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            <FileZoom
                onPlusClick={handleZoomIn}
                onMinusClick={handleZoomOut}
                onTrackClick={handleTrackClick}
                zoom={zoom}
            />
        </div>
    );
};

interface Props {
    file: Blob;
    document: TemplateData;
}
