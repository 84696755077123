import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { Page, PageHeader } from 'components/Page';
import { routeParams } from 'navigator';
import { CancelButton, CompleteImportButton, Document, ImportOptions } from 'components/Document';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setCurrentDocumentDataAction, setCurrentDocumentIdAction } from 'state/actions';

export const DocumentPage = () => {
    const { documentId } = useParams<routeParams>();
    let [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(setCurrentDocumentIdAction(null));
            dispatch(setCurrentDocumentDataAction(null));
        };
    }, []);

    if (!documentId || isNaN(parseInt(documentId))) {
        return <ErrorPage />;
    }

    const idsSet = new Set([parseInt(documentId)]);
    const searchIds = searchParams.get('ids');

    if (searchParams.get('ids')) {
        searchIds?.split(',').forEach(id => idsSet.add(parseInt(id)));
    }

    const ids = Array.from(idsSet);

    return (
        <Page pageId="document">
            <PageHeader title="Import preview">
                <CancelButton />
                <CompleteImportButton />
                <ImportOptions />
            </PageHeader>
            <Document ids={ids} />
        </Page>
    );
};
