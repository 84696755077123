import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';

import { MenuIcon } from 'components/atomic';
import { useDrawer } from 'components/DrawerContainer';
import { TemplateTypeForm } from './TemplateTypeForm/TemplateTypeForm';
import { getTemplateType } from 'services';

export const TemplateAction = ({ id, label }: Props) => {
    const { state } = useLocation();
    const { openDrawer } = useDrawer();

    const handleEditClick = async () => {
        const data = await getTemplateType(id);

        if (data) {
            openDrawer(`Edit ${label} Type`, <TemplateTypeForm action="edit" data={data} />);
        }
    };

    const items: MenuProps['items'] = [
        {
            key: 'edit',
            label: <div onClick={handleEditClick}>Edit document type</div>,
        },
    ];

    useEffect(() => {
        if (state?.typeId === id) {
            handleEditClick();
        }
    }, [state]);

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <div className="template-type">
                <MenuIcon />
            </div>
        </Dropdown>
    );
};

interface Props {
    id: number;
    label: string;
}
