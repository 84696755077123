import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { routes } from 'navigator';

export const CancelButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(routes.documents);
    };

    return (
        <Button type="link" onClick={handleClick}>
            Cancel
        </Button>
    );
};
