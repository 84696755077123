import { Link } from 'react-router-dom';

export const ListCelLink = ({ text, subtext, path }: ListCelLinkProps) => {
    return (
        <div>
            <Link className="list-cel-text" to={path}>
                {text || '   '}
            </Link>
            <div className="list-cel-subtext">{subtext}</div>
        </div>
    );
};

export interface ListCelLinkProps {
    text?: string | number;
    subtext?: string | number;
    path: string;
}
