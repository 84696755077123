import classNames from 'classnames';

export const ContainerCenter = ({ children, className, ...props }: DivProps) => {
    const containerClassName = classNames('container-center', className);

    return (
        <div className={containerClassName} {...props}>
            {children}
        </div>
    );
};
