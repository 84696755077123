import { Button } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';

import { MarkTableList } from './MarkTableList';
import { saveTableAction, tableAction } from 'state/actions';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { ToggleButton } from 'components/atomic';

export const MarkTableAuto = () => {
    const { dispatch, table } = useTemplate();
    const { loading, useRowLinesAuto, valid } = table;

    const handleScanClick = () => {
        dispatch(saveTableAction({ dynamicTable: true }));
    };

    const handleUseRowLinesToggle: SwitchChangeEventHandler = event => {
        dispatch(tableAction({ useRowLinesAuto: event.valueOf() }));
    };

    return (
        <div>
            <MarkTableList />
            <p>
                As soon as you click on “Scan”, the table should be automatically identified and the
                columns will be mapped.
            </p>
            <ToggleButton
                label="Rows are separated by lines"
                checked={useRowLinesAuto}
                onChange={handleUseRowLinesToggle}
            />
            <br />
            <Button
                type="primary"
                disabled={loading || !valid}
                loading={loading}
                onClick={handleScanClick}>
                Scan and next
            </Button>
        </div>
    );
};
