import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ExtractionMapping, PageDataTextBox, TemplateData } from 'components/Template';
import { FieldDataToSave, TableContentData } from 'components/Template/Steps/types';
import { AppDispatch } from 'state/store.types';

export enum TemplateActionTypes {
    SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA',
    GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA',
    CLEAR_STATE = 'CLEAR_STATE',
    CURRENT_PAGE = 'CURRENT_PAGE',
    EXPAND_STEP = 'EXPAND_STEP',
    DISABLED_STEPS = 'DISABLED_STEPS',
    STEP_CHANGE = 'STEP_CHANGE',
    SELECTION_INFO = 'SELECTION_INFO',
    HIGHLIGHT_VALUE = 'HIGHLIGHT_VALUE',
    TIP = 'TIP',
    SET_SELECTION_VALUE = 'SET_SELECTION_VALUE',
    CLEAR_VALUE_ACTION = 'CLEAR_VALUE_ACTION',
    HEADER_AND_FOOTER = 'HEADER_AND_FOOTER',
    SAVE_HEADER_AND_FOOTER = 'SAVE_HEADER_AND_FOOTER',
    GENERAL_FIELDS = 'GENERAL_FIELDS',
    CLEAR_GENERAL_FIELDS_TITLE = 'CLEAR_GENERAL_FIELDS_TITLE',
    SAVE_GENERAL_FIELDS = 'SAVE_GENERAL_FIELDS',
    TABLE_MODE = 'TABLE_MODE',
    TABLE = 'TABLE',
    SAVE_TABLE = 'SAVE_TABLE',
    CLEAR_TABLE_SEPARATOR_TITLE = 'CLEAR_TABLE_SEPARATOR_TITLE',
    CLEAR_TABLE_SEPARATOR_VALUE = 'CLEAR_TABLE_SEPARATOR_VALUE',
    COLUMNS_TO_TEMPLATE = 'COLUMNS_TO_TEMPLATE',
    SAVE_COLUMNS_TO_TEMPLATE = 'SAVE_COLUMNS_TO_TEMPLATE',
    SET_COLUMNS_TO_TEMPLATE_VALUE = 'SET_COLUMNS_TO_TEMPLATE_VALUE',
    TABLE_COLUMNS = 'TABLE_COLUMNS',
    SAVE_TABLE_COLUMNS = 'SAVE_TABLE_COLUMNS',
    CLEAR_TABLE_COLUMN_TITLE = 'CLEAR_TABLE_COLUMN_TITLE',
    CLEAR_TABLE_COLUMN_CONTENT = 'CLEAR_TABLE_COLUMN_CONTENT',
    SAVE_TEMPLATE = 'SAVE_TEMPLATE',
    SAVE_TEMPLATE_COMPLETE = 'SAVE_TEMPLATE_COMPLETE',
    SAVE_TEMPLATE_INCOMPLETE = 'SAVE_TEMPLATE_INCOMPLETE',
    TOGGLE_VIEW = 'TOGGLE_VIEW',
    REQUIRED_FIELDS_FULFILLED = 'REQUIRED_FIELDS_FULFILLED',
    DISABLE_STEP_CHANGE = 'DISABLE_STEP_CHANGE',
}

export interface TemplateState {
    id: number;
    template: TemplateData | null;
    currentPage: number;
    expanded: [boolean, boolean, boolean, boolean];
    disabled: [boolean, boolean, boolean, boolean];
    selectionInfo: SelectionInfo;
    highlight: HighlightValue | null;
    tip: string | null;
    headerAndFooter: HeaderAndFooterState;
    generalFields: GeneralFieldsState;
    table: TableState;
    columnsToTemplate: ColumnsToTemplateState;
    tableColumns: TableColumnsState;
    currentStep: StepName;
    tableMode: TableMode;
    fileView: boolean;
    requiredFieldsFulfilled: boolean;
    disableStepChange: boolean;
}

export interface CommonStepState<Items extends CommonStepItem<T> = CommonStepItem<any>, T = any> {
    active: number | null;
    valid: boolean;
    loading: boolean;
    items: Items[];
}

export interface HeaderAndFooterState extends CommonStepState<CommonStepItem> {}

export interface GeneralFieldsState extends CommonStepState<GeneralFieldsItem> {
    itemsToDelete: number[];
}

export interface TableState extends CommonStepState<TableItem> {
    useColumnRowSeparator: boolean;
    columnRowSeparator: number | null;
    useRowLinesAuto: boolean;
    useRowLinesManual: boolean;
    addSeparator: boolean;
    separator: Partial<TableContentData> | null;
    separatorSelection: 'value' | 'title' | null;
    separateByColumnBoundaries: boolean;
}

export interface ColumnsToTemplateState
    extends CommonStepState<CommonStepItem<ColumnsToTemplateItem>> {
    selectedValues: ColumnsToTemplateItem[];
}

export interface ColumnsToTemplateItem {
    selected?: string;
    freeText?: string;
}

export interface TableColumnsState extends CommonStepState<TableColumnsItem> {
    selection: 'column' | 'title' | 'content';
}

export interface CommonStepItem<T = number> {
    title: string;
    value?: T | null;
    id: number;
}

export interface GeneralFieldsItem extends Omit<FieldDataToSave, 'value'>, CommonStepItem<string> {
    fieldId: number;
}

export interface TableItem extends CommonStepItem {
    pageNum: number;
}

export interface TableColumnsItem
    extends Partial<Omit<FieldDataToSave, 'value'>>,
        CommonStepItem<TableColumnsValue> {
    fieldId: number;
    columnData: ColumnData[];
    extractedContentIds: number[];
}

export interface ColumnData {
    mapping?: ExtractionMapping;
    content: string;
}

export interface TableColumnsValue {
    startX: number | null;
    endX: number | null;
    startY: number | null;
    endY: number | null;
}

export interface ColumnsToTemplateSetValue {
    value: ColumnsToTemplateItem;
    id: number;
}

export interface HighlightValue {
    value: Array<number | null>;
    area?: TableColumnsValue | null;
    type: SelectionType;
}

export interface SelectionInfoData {
    type: SelectionType;
    text?: string;
    multiple?: boolean;
}

export interface SelectionValue {
    size?: number;
    boxes?: PageDataTextBox[];
    area?: TableColumnsValue;
}

export interface StepSavePayload {
    saveAll?: boolean;
    dynamicTable?: boolean;
}

export type SelectionInfo = SelectionInfoData | null;

export type SelectionType = 'header' | 'footer' | 'box' | 'area' | null;

export type StepNumber = 'STEP_1' | 'STEP_2' | 'STEP_3' | 'STEP_4' | 'COMPLETE';

export type ExpandedState = [boolean, boolean, boolean, boolean];

export type StepName =
    | 'headerAndFooter'
    | 'generalFields'
    | 'table'
    | 'columnsToTemplate'
    | 'tableColumns';

export type TableMode = 'auto' | 'manual';

export type StepInitialStateSetter = (
    dispatch: AppDispatch,
    template: TemplateData,
    state?: TemplateState
) => void;

export type StepChangeHandler = (dispatch: AppDispatch, state: TemplateState) => void;

export type SelectionChangeHandler = (
    dispatch: AppDispatch,
    state: TemplateState,
    value: SelectionValue
) => void;

export type StepClearValueHandler = (
    dispatch: AppDispatch,
    state: TemplateState,
    id: number | undefined
) => void;

export type StepValidation<T extends CommonStepItem[]> = (items: T) => boolean;

/**
 * Common methods and properties for all steps that can be overwritten,
 * but not mandatory to be implemented
 */
export interface StepBase<
    T extends CommonStepState<CommonStepItem<any>> = CommonStepState<CommonStepItem<any>>,
> {
    stepName: StepName;
    action: ActionCreatorWithPayload<Partial<T>, string>;
    /**
     * Validation function for all step
     */
    validate: (items: T['items'], state?: T) => boolean;
    /**
     * Validation function for single item
     */
    validateItem: (item: T['items'][0]) => boolean;
    /**
     * Returns number of next active index depending on item validation
     */
    nextItemIndex: (items: T['items'], active?: number | null) => number | null;
    /**
     * Returns object with next active item index depending on item validation
     */
    nextItemActive: (items: T['items']) => Pick<CommonStepState, 'active'>;
    /**
     * Dispatches common actions on step change
     */
    handleStepChange: (dispatch: AppDispatch, items: T['items']) => void;
    /**
     * Helper method to get copy of items
     */
    itemsCopy: <ItemT>(items: ItemT[]) => ItemT[];
    /**
     * Helper method to get extracted field data
     */
    getExtractedFieldsData: (template: TemplateData, isTable: boolean) => GeneralFieldsItem[];
}

/**
 * Common steps methods that should be implemented
 */
export interface StepCommonMethods<T extends CommonStepState<CommonStepItem<any>>>
    extends StepBase<T> {
    /**
     * Should return initial items for step parsed from template data
     */
    initState: StepInitialStateSetter;
    /**
     * Should handle Clear Value click in item options
     */
    clearValue: StepClearValueHandler;
    /**
     * Should handle step change, e.g. on expand
     */
    stepChange: StepChangeHandler;
    /**
     * Should handle `SelectionValue` change
     * after event triggered in `SelectionLayer`
     */
    selectionChange: SelectionChangeHandler;
}

export interface GetTemplatePayload {
    disableRefresh?: boolean;
}
