import { Button, FormInstance } from 'antd';

import { CommonForm, Container } from 'components/atomic';
import { BaseDataType } from './types';

export const EditListItemForm = <T extends BaseDataType>({
    name,
    selectedItem,
    initialValues = {},
    children,
    form,
    onFinish,
    onCancel,
    onDelete,
}: Props<T>) => {
    return (
        <Container className="form-container">
            <CommonForm
                name={name}
                onFinish={onFinish}
                form={form}
                initialValues={selectedItem || initialValues}>
                <>{children}</>
                <div className="form-list-item-buttons">
                    <div>
                        <Button type="primary" size="middle" htmlType="submit">
                            {selectedItem ? 'Save changes' : 'Add'}
                        </Button>
                        {selectedItem && (
                            <Button type="link" onClick={onCancel} size="middle">
                                Cancel
                            </Button>
                        )}
                    </div>
                    {selectedItem && (
                        <Button size="middle" onClick={onDelete}>
                            Delete
                        </Button>
                    )}
                </div>
            </CommonForm>
        </Container>
    );
};

interface Props<T> extends WithChildren {
    name: string;
    selectedItem: T | null;
    initialValues?: Record<string, any>;
    form?: FormInstance<T>;
    onFinish: (item: T) => void;
    onCancel: () => void;
    onDelete: () => void;
}
