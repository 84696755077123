import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './root.reducer';
import { initializeAction } from './actions/init.actions';
import {
    initMiddleWare,
    licenseMiddleWare,
    userMiddleware,
    templateMiddleWare,
    headerAndFooterMiddleware,
    generalFieldsMiddleware,
    tableMiddleware,
    columnsToTemplateMiddleware,
    tableColumnsMiddleware,
    documentMiddleWare,
} from './middleware';

export const store = configureStore({
    reducer: rootReducer,
    middleware: middleware =>
        middleware()
            .prepend(initMiddleWare.middleware)
            .prepend(userMiddleware.middleware)
            .prepend(licenseMiddleWare.middleware)
            .prepend(templateMiddleWare.middleware)
            .prepend(headerAndFooterMiddleware.middleware)
            .prepend(generalFieldsMiddleware.middleware)
            .prepend(tableMiddleware.middleware)
            .prepend(columnsToTemplateMiddleware.middleware)
            .prepend(tableColumnsMiddleware.middleware)
            .prepend(documentMiddleWare.middleware),
});

store.dispatch(initializeAction());
