import { Switch, SwitchProps } from 'antd';
import { useRef } from 'react';

import { CheckIcon } from '../Icon/Icon';

export const ToggleButton = ({ label, ...props }: Props) => {
    const ref = useRef<HTMLButtonElement>(null);

    const handleLabelClick = () => {
        ref.current?.click();
    };

    return (
        <div className="toggle-button">
            <Switch checkedChildren={<CheckIcon />} {...props} ref={ref} />
            <span onClick={handleLabelClick}>{label}</span>
        </div>
    );
};

interface Props extends SwitchProps {
    label?: React.ReactNode;
}
