import { createContext, useContext } from 'react';

import { TemplateData } from 'components/Template';

export interface ExtractedPreviewContext {
    extractedFields: TemplateData['extractedFields'];
    fieldOptions: TemplateData['fieldOptions'];
    orderId: number | undefined;
    selected: number[];
}

export const extractedPreviewContext = createContext({} as ExtractedPreviewContext);
export const ExtractedPreviewContextProvider = extractedPreviewContext.Provider;

export const useExtractedPreview = () => useContext(extractedPreviewContext);
