import { Header } from 'components/Header/Header';
import { SideMenu } from 'components/SideMenu/SideMenu';

export const DefaultLayout = ({ children }: WithChildren) => {
    return (
        <div className="default-layout">
            <Header />
            <div className="default-layout-section">
                <div className="default-layout-side-menu">
                    <SideMenu />
                </div>
                <div className="default-layout-content">{children}</div>
            </div>
        </div>
    );
};
