export const TemplateLayout = ({ children }: Props) => {
    return (
        <div className="template">
            <div className="template-left">{children[0]}</div>
            <div className="template-right">{children[1]}</div>
        </div>
    );
};

interface Props {
    children: React.ReactNode[];
}
