export enum OverviewActionTypes {
    SET_DATA = 'SET_DATA',
    LOADING = 'LOADING',
}

export interface OverviewState {
    data: OverviewData;
    loading: boolean;
}

export interface OverviewResponseData {
    totalImports: number;
    failedImports: number;
    activeTemplates: number;
    importedDocuments: number;
    importedPages: number;
    extractionCount: number;
    extractionOcrCount: number;
    extractionLimit: number;
}

export interface OverviewData extends OverviewResponseData {
    timeSaved: number;
    moneySaved: number;
}
