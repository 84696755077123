import { useState } from 'react';
import { Button, FormInstance } from 'antd';
import classNames from 'classnames';

import { testConnection } from 'services/settings.service';
import { IntegrationFormValues } from './types';
import { IntegrationConnectionProperties } from '../types';

export const TestConnection = ({ form, systemProps, disabled, selectedSystemId }: Props) => {
    const [status, setStatus] = useState<keyof typeof ConnectionStatus>('idle');
    const iconClassName = classNames('test-connection-status-icon', status);

    const handleClick = async () => {
        setStatus('loading');

        const values = form.getFieldsValue();

        const props: Record<string, IntegrationConnectionProperties['value']> = {};

        systemProps.forEach(prop => {
            props[prop.key] = values[prop.key];
        });
        props['systemId'] = selectedSystemId;

        const result = await testConnection(props);
        const success = typeof result === 'string' && result.toLocaleLowerCase() === 'success';

        if (success) {
            setStatus('ok');
        } else {
            setStatus('fail');
        }
    };

    return (
        <div className="test-connection">
            <Button
                type="primary"
                onClick={handleClick}
                disabled={disabled || status === 'loading'}
                loading={status === 'loading'}>
                Test connection
            </Button>
            <div className="test-connection-status">
                {status !== 'idle' && (
                    <>
                        <div className={iconClassName} />
                        {ConnectionStatus[status]}
                    </>
                )}
            </div>
        </div>
    );
};

interface Props {
    form: FormInstance<IntegrationFormValues>;
    systemProps: IntegrationConnectionProperties[];
    selectedSystemId: number | null;
    disabled: boolean;
}

enum ConnectionStatus {
    idle = '',
    ok = 'Connected Successfully',
    fail = 'Connection failed',
    loading = 'Testing connection…',
}
