export enum SearchActionTypes {
    SEARCH_QUERY = 'SEARCH_QUERY',
    CLEAR_SEARCH = 'CLEAR_SEARCH',
    SEARCH_LOADING = 'SEARCH_LOADING',
}

export interface SearchState {
    searchQuery: string;
    loading: boolean;
}
