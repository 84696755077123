import { Dropdown, MenuProps } from 'antd';

import { MenuIcon } from '../Icon/Icon';

export const ItemMenu = ({ items }: Props) => {
    return (
        <Dropdown menu={{ items }} trigger={['click']} arrow>
            <div className="item-menu-icon">
                <MenuIcon />
            </div>
        </Dropdown>
    );
};

interface Props {
    items: MenuProps['items'];
}
