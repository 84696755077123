import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';

import './styles/index.scss';
import { store } from 'state/store';
import { theme } from 'styles/antd-themes/default';
import { App } from 'App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <ConfigProvider theme={theme}>
                <App />
            </ConfigProvider>
        </Provider>
    </StrictMode>
);
