import { useState } from 'react';
import { Button, Form, Input } from 'antd';

import { CommonForm, Container } from 'components/atomic';
import { AddressTableData } from './types';
import { addAddressTable, apiRoutes, editAddressTable } from 'services';
import { useDrawer } from 'components/DrawerContainer';
import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';

export const AddressTableForm = ({ data }: Props) => {
    const { closeDrawer } = useDrawer();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleFinish = async (values: AddressTableData) => {
        setLoading(true);

        if (data) {
            await editAddressTable(data.id, values);
        } else {
            await addAddressTable(values);
        }

        dispatch(getListenerAction(apiRoutes.addressTable));
        setLoading(false);
        handleClose();
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    return (
        <CommonForm
            name="addressTableForm"
            onFinish={handleFinish}
            initialValues={data}
            form={form}>
            <Container className="form-container">
                <Form.Item<AddressTableData>
                    label="Customer Number"
                    name="customerNumber"
                    required={false}
                    rules={[{ required: true, message: 'Please enter customer number' }]}>
                    <Input autoFocus />
                </Form.Item>
                <Form.Item<AddressTableData>
                    label="Address"
                    name="address"
                    required={false}
                    rules={[{ required: true, message: 'Please enter address' }]}>
                    <Input autoFocus />
                </Form.Item>
                <Form.Item<AddressTableData>
                    label="Code"
                    name="code"
                    required={false}
                    rules={[{ required: true, message: 'Please enter code' }]}>
                    <Input autoFocus />
                </Form.Item>
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Save
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

interface Props {
    data?: AddressTableData;
}
