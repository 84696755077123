import { Dropdown, MenuProps } from 'antd';
import { useDrawer } from 'components/DrawerContainer';

import { MenuIcon } from 'components/atomic';
import { ReloadTemplateForm } from './ReloadTemplateForm';
import { SetSeparatorForm } from './SetSeparatorForm';
import { downloadDocument } from '../../../Document/Controls/helpers';
import { SetAdvancedSettingsForm } from './SetAdvancedSettingsForm';

export const Menu = ({ id }: Props) => {
    const { openDrawer } = useDrawer();

    const handleReloadClick = () => {
        openDrawer('Reload document', <ReloadTemplateForm id={id} />);
    };

    const handleSetSeparatorsClick = () => {
        openDrawer('Set separators', <SetSeparatorForm templateId={id} />);
    };

    const handleDownloadDocumentClick = () => {
        if (!id) {
            return;
        }

        downloadDocument(id, true);
    };

    const handleSetAdvancedSettingsClick = () => {
        openDrawer('Set advanced settings', <SetAdvancedSettingsForm templateId={id} />);
    };

    const items: MenuProps['items'] = [
        {
            key: 'reload',
            label: <div onClick={handleReloadClick}>Reload document</div>,
        },
        {
            key: 'separator',
            label: <div onClick={handleSetSeparatorsClick}>Set separators</div>,
        },
        {
            key: 'downloadTemplate',
            label: <div onClick={handleDownloadDocumentClick}>Download document</div>,
        },
        {
            type: 'divider',
        },

        {
            key: 'custom settings',
            label: <div onClick={handleSetAdvancedSettingsClick}>Advanced settings</div>,
        },
    ];

    return (
        <Dropdown menu={{ items }} trigger={['click']} arrow>
            <div className="template-menu">
                <MenuIcon />
            </div>
        </Dropdown>
    );
};

interface Props {
    id: number;
}
