export const round = (value: number) => {
    return Math.round(value * 10) / 10;
};

export const roundToAliquot = (value: number, aliquot: number) => {
    const multiplied = value * 10;
    const remainder = multiplied % (aliquot * 10);

    if (remainder !== 0) {
        return (multiplied - remainder) / 10;
    }

    return value;
};
