export const ListAction = ({ icon, action, text }: Props) => {
    return (
        <div className="list-action" onClick={action}>
            <div className="list-action-icon">{icon}</div>
            <div className="list-action-text">{text}</div>
        </div>
    );
};

export interface ListActionProps {
    action: () => void;
}

interface Props extends ListActionProps {
    icon: React.ReactNode;
    text: string;
}
