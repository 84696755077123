import { TemplatesDataResponse } from 'components/Templates';

export const getTemplatesOptions = (
    data: TemplatesDataResponse | null,
    templateType: number,
    addNew: boolean = false
) => {
    if (!data) {
        return [];
    }

    const filtered =
        data.content?.filter(template => template.templateType.value === templateType) || [];

    let templatesOptions = filtered.map(({ id, templateName }) => ({
        value: id,
        label: templateName,
    }));
    if (addNew) {
        templatesOptions.push({ value: -1, label: '+Add new template' });
    }

    return templatesOptions;
};
