import { Page, PageHeader } from 'components/Page';
import { SystemSettingsForm } from 'components/SystemSettings';

export const SystemSettingsPage = () => {
    return (
        <Page pageId="system-settings">
            <PageHeader title="System Settings" />
            <SystemSettingsForm />
        </Page>
    );
};
