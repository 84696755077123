import { TemplateData } from 'components/Template';
import { Divider } from 'components/atomic';
import { ExtractedField } from './ExtractedField';
import { ExtractedPreviewContextProvider } from './context';
import { getColumns } from './getColumns';
import { ExtractedFieldTableData } from './types';
import { Table, TableProps } from 'antd';
import { Key, useState } from 'react';

export const ExtractedPreview = ({ data, orderId }: Props) => {
    const [selected, setSelected] = useState<number[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const { extractResult, fields, extractedFields, fieldOptions } = data;
    const generalFields = fields
        .filter(field => !field.isTable)
        .map(field => ({
            field,
            value: extractResult.order_header[field.fieldId] || '',
        }));

    const tableFields = fields.filter(field => field.isTable);
    const tableHeader = tableFields.map(field => field.label);
    const tableRows: ExtractedFieldTableData[] = extractResult.order_lines.map((record, rowIndex) =>
        Object.fromEntries(
            tableFields.map(field => [
                field.fieldId,
                {
                    field,
                    value: record[field.fieldId] || '',
                    rowIndex,
                },
            ])
        )
    );
    const editable = typeof orderId === 'number';
    const columns = getColumns(tableHeader, tableFields, editable);

    const rowSelection: TableProps<ExtractedFieldTableData>['rowSelection'] = {
        selectedRowKeys,
        onChange: (selectedKeys, selectedRows) => {
            const indexes = selectedRows.map(item => Object.values(item)[0].rowIndex);

            setSelected([...indexes]);
            setSelectedRowKeys(selectedKeys);
        },
    };

    return (
        <ExtractedPreviewContextProvider
            value={{ extractedFields, fieldOptions, orderId, selected }}>
            <div className="extracted-preview">
                <div className="extracted-preview-title">General Fields</div>
                <Divider />
                <table className="extracted-preview-general-fields">
                    <tbody>
                        {generalFields.map(({ field, value }) => (
                            <tr className="extracted-preview-row" key={field.fieldId}>
                                <td className="extracted-preview-gf-label">{field.label}</td>
                                <td className="extracted-preview-gf-value">
                                    <ExtractedField field={field} extractedValue={value} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Divider />
                <div className="extracted-preview-title">Table Values</div>
                <Table
                    columns={columns}
                    dataSource={tableRows}
                    rowKey={(item, index) => `${index}`}
                    showHeader
                    rowSelection={editable ? rowSelection : undefined}
                    pagination={false}
                    className="extracted-table-values"
                />
            </div>
        </ExtractedPreviewContextProvider>
    );
};

interface Props {
    data: TemplateData;
    orderId?: number;
}
