import { useData } from 'hooks/data.hooks';
import { IntegrationEntity, Validation } from '../types';
import { apiRoutes } from 'services';

export const useTemplateTypeData = () => {
    const { data: validations } = useData<Validation[]>(apiRoutes.validations);
    const { data: integrationEntities } = useData<IntegrationEntity[]>(
        apiRoutes.integrationEntities
    );

    return {
        validations,
        integrationEntities,
    };
};
