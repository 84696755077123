import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { KpiType } from './types';
import { routes } from 'navigator';
import { Spin } from 'antd';

export const KpiItem = ({ action, route, title, type, value, loading }: Props) => {
    const containerClassName = classNames('kpi-item', `kpi-item-${type}`);

    return (
        <div className={containerClassName}>
            <div>
                <div className="kpi-item-title">{title}</div>
                {loading ? <Spin /> : <div className="kpi-item-value">{value}</div>}
            </div>
            <Link to={route} className="kpi-item-action">
                {action}
            </Link>
        </div>
    );
};

interface Props {
    type: KpiType;
    value: number;
    title: string;
    route: routes;
    action: string;
    loading: boolean;
}
