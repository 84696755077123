import { MenuProps, Tooltip } from 'antd';

import { useHighlight } from 'components/Template/Selection/Highlight/helpers';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { Badge, Divider, ItemMenu, SelectionButton, ViewIcon } from 'components/atomic';
import { clearTableSeparatorTitle, clearTableSeparatorValue, tableAction } from 'state/actions';

export const MarkTableHeader = () => {
    const { dispatch, table } = useTemplate();
    const { separator, separatorSelection } = table;

    const handleClearTitle = () => {
        dispatch(clearTableSeparatorTitle());
    };

    const handleClearValue = () => {
        dispatch(clearTableSeparatorValue());
    };

    const handleTitleButtonClick: ClickHandler = event => {
        event.stopPropagation();
        dispatch(tableAction({ separatorSelection: 'title' }));
    };

    const handleContentButtonClick: ClickHandler = event => {
        event.stopPropagation();
        dispatch(tableAction({ separatorSelection: 'value' }));
    };

    const items: MenuProps['items'] = [
        {
            key: 'clearTitle',
            disabled: typeof separator?.titleId !== 'number',
            label: <div onClick={handleClearTitle}>Clear title</div>,
        },
        {
            key: 'clearValue',
            disabled: !separator?.contentIds?.length,
            label: <div onClick={handleClearValue}>Clear value</div>,
        },
    ];

    const titleHighlight = useHighlight({
        value: separator?.titleId ? [separator.titleId] : [],
        type: 'box',
    });

    const valueHighligh = useHighlight({
        value: separator?.contentIds || [],
        type: 'box',
    });

    return (
        <div className="single-item-multiline">
            <div className="single-item-multiline-row first">
                <div className="item-center">
                    {typeof separator?.titleId === 'number' ? (
                        <Badge text="Title" {...titleHighlight} />
                    ) : (
                        <SelectionButton
                            text="Title"
                            active={separatorSelection === 'title'}
                            onClick={handleTitleButtonClick}
                        />
                    )}
                    {separator?.contentIds?.length ? (
                        <Badge text="Value" {...valueHighligh} />
                    ) : (
                        <SelectionButton
                            text="Value"
                            active={separatorSelection === 'value'}
                            onClick={handleContentButtonClick}
                        />
                    )}
                    <div className="view-field-value">
                        {!!separator?.value && (
                            <Tooltip title={separator.value}>
                                <ViewIcon />
                            </Tooltip>
                        )}
                    </div>
                    <ItemMenu items={items} />
                </div>
            </div>
            {separator?.value && (
                <>
                    <Divider />
                    <div className="single-item-multiline-row second">
                        <span className="single-item-multiline-description">Scanned value:</span>
                        <span className="scanned-value">{separator.value}</span>
                    </div>
                </>
            )}
        </div>
    );
};

type ClickHandler = React.DOMAttributes<HTMLDivElement>['onClick'];
