import { Form, Input, Radio } from 'antd';

import { useAppDispatch } from 'hooks/redux.hooks';
import { setTemplateSettings } from 'services';
import { useTemplate } from '../../useTemplate.hook';
import { Separator } from '../../types';
import { FormList } from 'components/FormList';
import { disableStepChangeAction, getTemplateDataAction } from 'state/actions';

export const SetSeparatorForm = ({ templateId }: Props) => {
    const { template } = useTemplate();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<Separator>();
    const separators: Separator[] = (template?.textSeparators || [])
        .map(item => ({ ...item }))
        .reverse();

    const handleSave = async (values: Separator, id?: number) => {
        const index = separators.findIndex(item => item.id === id);

        if (~index) {
            separators[index] = { ...values, id: separators[index].id };
        } else {
            separators.unshift(values);
        }

        await setTemplateSettings(templateId, { textSeparators: separators });

        dispatch(disableStepChangeAction(true));
        dispatch(getTemplateDataAction());
    };

    const handleDelete = async (values: Separator, id: number) => {
        const newValue = separators.filter(item => item.id !== id);
        await setTemplateSettings(templateId, { textSeparators: newValue });

        dispatch(disableStepChangeAction(true));
        dispatch(getTemplateDataAction());
    };

    return (
        <div className="separators-form">
            <FormList
                name="setSeparators"
                data={separators}
                getItemTitle={item => item.value}
                onSave={handleSave}
                onDelete={handleDelete}
                form={form}>
                <Form.Item<Separator>
                    name="value"
                    label="Separator text"
                    required={false}
                    rules={[{ required: true, message: 'Please enter text' }]}>
                    <Input placeholder="Separator text" autoFocus />
                </Form.Item>
                <Form.Item<Separator> name="mode" initialValue="ADD_AFTER">
                    <Radio.Group options={modeOptions} optionType="button" />
                </Form.Item>
            </FormList>
        </div>
    );
};

const modeOptions: ModeOption[] = [
    { value: 'ADD_BEFORE', label: 'Before' },
    { value: 'ADD_AFTER', label: 'After' },
];

interface Props {
    templateId: number;
}

interface ModeOption {
    value: Separator['mode'];
    label: string;
}
