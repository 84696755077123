import { MenuProps } from 'antd';

import { Badge, ItemMenu, SingleListItemTitle } from 'components/atomic';
import { useHighlight } from 'components/Template/Selection/Highlight/helpers';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { clearValueAction } from 'state/actions';
import { TableItem } from 'state/types';

export const MarkTableListItem = ({ title, value, id, pageNum }: TableItem) => {
    const { dispatch } = useTemplate();

    const handleClearValue = () => {
        dispatch(clearValueAction(id));
    };

    const items: MenuProps['items'] = [
        {
            key: 'clear',
            label: <div onClick={handleClearValue}>Clear value</div>,
        },
    ];

    const highlight = useHighlight(
        {
            value: [value || null],
            type: 'box',
        },
        pageNum
    );

    return (
        <>
            <SingleListItemTitle>{title}</SingleListItemTitle>
            <div className="item-right">
                {value !== null && <Badge text="Set" {...highlight} />}
                <ItemMenu items={items} />
            </div>
        </>
    );
};
