import { KpiData } from './types';
import { KpiItem } from './KpiItem';
import { routes } from 'navigator';
import { useOverview } from '../useOverview.hook';

export const Kpi = () => {
    const { data, loading } = useOverview();

    const kpiData: KpiData = {
        failed: data ? data.failedImports : 0,
        imports: data ? data.totalImports : 0,
        templates: data ? data.activeTemplates : 0,
    };

    return (
        <div className="kpi-container">
            <KpiItem
                action="View"
                route={routes.documents}
                title="Total Imports"
                type="imports"
                value={kpiData.imports}
                loading={loading}
            />
            <KpiItem
                action="View"
                route={routes.errorQueue}
                title="Failed imports"
                type="failed"
                value={kpiData.failed}
                loading={loading}
            />
            <KpiItem
                action="Edit"
                route={routes.templates}
                title="Active templates"
                type="templates"
                value={kpiData.templates}
                loading={loading}
            />
        </div>
    );
};
