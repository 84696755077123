import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    clearGeneralFieldTitleAction,
    expandStepAction,
    generalFieldsAction,
    getTemplateDataAction,
    requiredFieldsFulfilledAction,
    saveGeneralFieldsAction,
    selectionInfoAction,
    tipAction,
} from 'state/actions';
import { RootState } from 'state/store.types';
import { saveTemplateStep } from 'services';
import { GeneralFieldsState, StepCommonMethods } from 'state/types';
import { DataToSave, FieldDataToSave } from 'components/Template/Steps/types';
import { stepBase } from './stepBase';

export const generalFieldsMiddleware = createListenerMiddleware<RootState>();

generalFieldsMiddleware.startListening({
    actionCreator: saveGeneralFieldsAction,
    effect: async ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { id, generalFields: data } = template;

        dispatch(generalFieldsAction({ loading: true }));

        const dataToSave: DataToSave['fields'] = {};
        const setToDelete = new Set(data.itemsToDelete);

        data.items.forEach(item => {
            const { fieldId, ...values } = item;
            const valid = generalFields.validateItem(item);

            if (!valid) {
                return;
            }

            const fieldToSave: FieldDataToSave = {
                contentIds: values.contentIds,
                pageNum: values.pageNum,
                propertyName: values.propertyName,
                required: values.required,
                titleId: values.titleId,
                value: values.value || '',
                label: values.label,
            };

            setToDelete.delete(fieldId);
            dataToSave[fieldId] = fieldToSave;
        });

        await saveTemplateStep(id, 'STEP_2', {
            fields: dataToSave,
            fieldsToDelete: Array.from(setToDelete),
        });

        const requiredFieldsFulfilled = data.items
            .filter(item => item.required)
            .every(item => generalFields.validateItem(item));

        dispatch(generalFieldsAction({ loading: false, itemsToDelete: [] }));
        dispatch(requiredFieldsFulfilledAction(requiredFieldsFulfilled));

        if (!payload?.saveAll) {
            dispatch(getTemplateDataAction());
            dispatch(expandStepAction(2));
        }
    },
});

generalFieldsMiddleware.startListening({
    actionCreator: generalFieldsAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { generalFields: data } = template;

        if (typeof payload.active !== 'undefined' && data.items.length) {
            if (payload.active === null) {
                dispatch(selectionInfoAction(null));
                dispatch(tipAction(null));
            } else {
                const activeItem = data.items[payload.active];

                if (activeItem.titleId === null) {
                    dispatch(selectionInfoAction({ type: 'box', text: 'title' }));
                    dispatch(tipAction(`Click to mark the title of **${activeItem.label}**`));
                } else if (!activeItem.contentIds?.length) {
                    dispatch(selectionInfoAction({ type: 'box', text: 'value', multiple: true }));
                    dispatch(tipAction(`Click to mark the value of **${activeItem.label}**`));
                } else {
                    dispatch(selectionInfoAction(null));
                    dispatch(tipAction(null));
                }
            }
        }

        if (payload.items) {
            const valid = generalFields.validate(payload.items);

            dispatch(generalFieldsAction({ valid }));
        }
    },
});

generalFieldsMiddleware.startListening({
    actionCreator: clearGeneralFieldTitleAction,
    effect: ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const items = generalFields.itemsCopy(template.generalFields.items);

        items[payload].titleId = null;

        dispatch(generalFields.action({ items }));
    },
});

export const generalFields: StepCommonMethods<GeneralFieldsState> = {
    ...stepBase,

    stepName: 'generalFields',
    action: generalFieldsAction,

    initState(dispatch, template) {
        const items = this.getExtractedFieldsData(template, false);

        dispatch(this.action({ items }));
    },
    stepChange(dispatch, state) {
        this.handleStepChange(dispatch, state.generalFields.items);
    },
    selectionChange(dispatch, state, { boxes }) {
        if (!boxes?.length) {
            return;
        }

        const box = boxes[0];
        const { currentPage } = state;
        const { active } = state.generalFields;
        const { selectionInfo: selectionType } = state;
        const items = this.itemsCopy(state.generalFields.items);
        let next: number | null | undefined;

        if (active === null) {
            return;
        }

        if (typeof items[active].titleId !== 'number' && selectionType?.text === 'title') {
            items[active].titleId = box.id;
            items[active].pageNum = currentPage;
            dispatch(selectionInfoAction({ type: 'box', text: 'value', multiple: true }));
            dispatch(tipAction(`Click to mark the value of **${items[active].label}**`));
        }

        if (!items[active].contentIds?.length && selectionType?.text === 'value') {
            items[active].contentIds = boxes.map(item => item.id);
            items[active].value = boxes.map(item => item.text).join(' ');
            items[active].pageNum = currentPage;
            next = this.nextItemIndex(items, active);
        }

        const newData: Partial<GeneralFieldsState> = { items };

        if (typeof next !== 'undefined') {
            newData.active = next;
        }

        dispatch(this.action(newData));
    },
    clearValue(dispatch, state, id) {
        const { active } = state.generalFields;
        const items = this.itemsCopy(state.generalFields.items);

        if (active === null) {
            return;
        }

        const itemsToDelete = [...state.generalFields.itemsToDelete];

        itemsToDelete.push(items[id || active].fieldId);

        items[id || active].value = '';
        items[id || active].contentIds = [];

        dispatch(this.action({ items, itemsToDelete }));
    },
    validateItem({ contentIds, titleId }) {
        return !!contentIds?.length && titleId !== null;
    },
    validate(items) {
        return items.every(
            ({ contentIds, titleId }) =>
                (contentIds?.length === 0 && titleId === null) ||
                (contentIds?.length > 0 && titleId !== null)
        );
    },
    nextItemIndex(items, active) {
        if (typeof active === 'number' && items[active + 1]) {
            if (!this.validateItem(items[active + 1])) {
                return active + 1;
            }
        }

        return stepBase.nextItemIndex.call(this, items);
    },
};
