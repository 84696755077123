import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';

import { BotIcon, TransferIcon } from 'components/atomic';
import { useAppDispatch } from 'hooks/redux.hooks';
import { addListener } from '@reduxjs/toolkit';
import { exportResultAction } from 'state/actions';
import { ImportResult } from 'state/types';
import { getDetails, getTitle } from './helpers';

export const ResultsModal = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState<ImportResult | null>(null);
    const title = getTitle(result);
    const details = getDetails(result);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        return dispatch(
            addListener({
                actionCreator: exportResultAction,
                effect: ({ payload }) => {
                    setOpen(true);
                    setResult(payload);
                },
            })
        ) as unknown as () => void;
    }, []);

    return (
        <Modal
            className="import-result-modal"
            open={open}
            onCancel={handleClose}
            centered
            footer={[
                <Button key="fix" type="primary" size="small" onClick={handleClose}>
                    Close
                </Button>,
            ]}>
            <div className="save-template-modal-content">
                <div className="save-template-icon-big icon">
                    {result?.success ? <TransferIcon /> : <BotIcon />}
                </div>
                <div className="save-template-title">{title}</div>
                {!!details && <div className="import-result-details">{details}</div>}
            </div>
        </Modal>
    );
};
