import { Block } from 'components/atomic';
import { ConnectedIntegration } from './ConnectedIntegration';
import { ListenerIntegration } from './ListenerIntegration';

export const Integration = () => {
    return (
        <Block title="Integration">
            <div className="integration">
                <ConnectedIntegration />
                <ListenerIntegration />
            </div>
        </Block>
    );
};
