import { UserImage } from './UserImage/UserImage';
import { getDisplayUserRole } from './helpers';
import { useUserData } from 'hooks/user.hooks';

export const UserInfo = () => {
    const { name, role } = useUserData();

    return (
        <div className="user-info">
            <div className="user-info-text">
                <div className="user-info-name">{name}</div>
                <div className="user-info-role">{getDisplayUserRole(role)}</div>
            </div>
            <UserImage />
        </div>
    );
};
