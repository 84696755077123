import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import classNames from 'classnames';
import { TemplateFieldData } from '../../types';
import { SortableItem } from './types';

export const FieldsListItem = ({ item, dragging, onEditClick }: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: item.id,
    });

    const containerClassName = classNames(
        'template-field',
        dragging && 'dragging',
        isDragging && 'drag-placeholder'
    );

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleEditClick = () => {
        onEditClick && onEditClick(item.data);
    };

    return (
        <div ref={setNodeRef} style={style} className={containerClassName}>
            <div className="template-field-left" {...attributes} {...listeners}>
                <div className="template-field-drag-handle" />
                <span>{item.data.label}</span>
            </div>
            <div>
                {item.data.isRequired && <span className="template-field-required">Required</span>}
                <Button
                    type="link"
                    className="template-field-edit-button"
                    onClick={handleEditClick}>
                    Edit
                </Button>
            </div>
        </div>
    );
};

interface Props {
    item: SortableItem;
    dragging?: boolean;
    onEditClick?: (selected: TemplateFieldData) => void;
}
