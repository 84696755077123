export enum NotificationsActionTypes {
    SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
    CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
}

export interface NotificationData {
    type: NotificationType;
    title?: string;
    content?: React.ReactNode;
}

export type NotificationType = 'error' | 'newInvoice';
