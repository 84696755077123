import { Block } from 'components/atomic';
import { EmailLink, emails } from 'components/EmailLink';
import { useOverview } from '../useOverview.hook';

export const Performance = () => {
    const { data } = useOverview();

    return (
        <Block
            title="Performance stats"
            className="overview-block"
            description="Performance stats show documents processed, time saved, and estimated cost savings with DOConvert">
            <div className="overview-stats">
                <span>Documents imported</span>
                <span>{data.importedDocuments}</span>
            </div>
            <div className="overview-stats">
                <span>Pages imported</span>
                <span>{data.importedPages}</span>
            </div>
            <div className="overview-stats">
                <span>Total time saved</span>
                <span>{data.timeSaved} Hrs.</span>
            </div>
            <div className="overview-stats-money-saved">Est. ${data.moneySaved} saved</div>
            <EmailLink className="share-stats-link" emailContent={emails.toBoss}>
                Share stats
            </EmailLink>
        </Block>
    );
};
