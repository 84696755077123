import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CommonForm, Container, TooltipIcon } from 'components/atomic';
import { AddDocumentTemplateData, EditDocumentTemplateData, TemplateCustomProperty } from './types';
import { TemplateTypeSelect } from '../FormItems';
import { useDrawer } from 'components/DrawerContainer';
import { UploadDocument } from '../FormItems/UploadDocument';
import { addDocumentTemplate, apiRoutes, editTemplate } from 'services';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getListenerAction, useData } from 'hooks/data.hooks';
import { routes } from 'navigator';
import { CustomDataList } from 'components/Template/types';
import { UsersDataResponse } from '../../Users/types';
import { getUsersOptions } from '../FormItems/helpers';

export const DocumentTemplateForm = ({ data, id, customDataList }: Props) => {
    const selectedType = useAppSelector(state => state.ui.selectedType);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { closeDrawer } = useDrawer();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { data: custom } = useData<TemplateCustomProperty[]>(apiRoutes.templatesCustomProperties);
    const { data: users } = useData<UsersDataResponse>(apiRoutes.users);

    const handleFinish = async (values: AddDocumentTemplateData) => {
        setLoading(true);

        if (data && id) {
            const { name, type, businessEntityId, userIds, files, ...rest } = values;
            const editValues: EditDocumentTemplateData = {
                name,
                type,
                businessEntityId,
                userIds,
            };

            Object.entries(rest).forEach(([key, value]) => {
                if (!customDataList) {
                    customDataList = [];
                }

                const customDataItem = customDataList.find(item => item.key === key);

                if (customDataItem) {
                    customDataItem.value = value as string;
                } else {
                    customDataList.push({
                        key: key,
                        value: value as string,
                        templateId: id,
                    });
                }
            });

            editValues.customDataList = customDataList;

            await editTemplate(id, editValues);
            dispatch(getListenerAction(`${apiRoutes.templatesByType}/${values.type}`));
        } else {
            const template = await addDocumentTemplate(values);

            if (template) {
                navigate(`${routes.templates}/${template.templateId}`);
            }
        }

        handleClose();

        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    useEffect(() => {
        if (!custom || !customDataList?.length) {
            return;
        }

        const values: Record<string, string | boolean | number> = {};

        customDataList?.forEach(item => {
            const property = custom.find(({ key }) => item.key === key);
            let value: string | boolean | number = item.value;

            switch (property?.type) {
                case 'boolean':
                    value = value === 'true';
                    break;
                case 'number':
                    value = parseInt(value);
                    break;
                default:
                    break;
            }

            values[item.key] = value;
        });

        form.setFieldsValue(values);
    }, [custom]);

    const initialValues: Partial<AddDocumentTemplateData> = {
        files: [],
        type: data?.type || selectedType?.value,
        ...data,
    };

    return (
        <CommonForm
            name="documentTemplateForm"
            onFinish={handleFinish}
            initialValues={initialValues}
            form={form}>
            <Container className="form-container">
                <TemplateTypeSelect isRequired={true} disabled={!!(data && id)} />
                <Form.Item<AddDocumentTemplateData>
                    label="Template name"
                    name="name"
                    required={false}
                    rules={[{ required: true, message: 'Please enter template name' }]}>
                    <Input autoFocus />
                </Form.Item>
                <Form.Item<AddDocumentTemplateData>
                    label={
                        <span className="with-tooltip">
                            Entity ID (Optional)
                            <TooltipIcon>
                                a unique identification number that represents the business entity
                            </TooltipIcon>
                        </span>
                    }
                    name="businessEntityId"
                    required={false}>
                    <Input />
                </Form.Item>
                <Form.Item<AddDocumentTemplateData>
                    label="Select owner email"
                    name="userIds"
                    required={false}>
                    <Select
                        options={getUsersOptions(users)}
                        placeholder="Select owner email"
                        mode="multiple"
                    />
                </Form.Item>
                {!!custom &&
                    custom.map(item => {
                        let input = <Input />;

                        switch (item.type) {
                            case 'boolean':
                                input = <Checkbox>{item.name}</Checkbox>;
                                break;
                            case 'number':
                                input = <InputNumber />;
                                break;
                            default:
                                break;
                        }

                        return (
                            <Form.Item
                                label={item.type === 'boolean' ? undefined : item.name}
                                name={item.key}
                                required={false}
                                valuePropName={item.type === 'boolean' ? 'checked' : undefined}>
                                {input}
                            </Form.Item>
                        );
                    })}
                {!data && <UploadDocument />}
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Save
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

interface Props {
    data?: Partial<AddDocumentTemplateData>;
    customDataList?: CustomDataList[];
    id?: number;
}
