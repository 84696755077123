import { List } from 'components/List';
import { useTemplates } from './useTemplates.hook';
import { useAppSelector } from 'hooks/redux.hooks';
import { getColumns } from '../getColumns';

export const DocumentTemplates = () => {
    const selectedType = useAppSelector(state => state.ui.selectedType);
    const { data, loading } = useTemplates();
    let title = 'Document templates';

    if (selectedType) {
        title += ` - ${selectedType?.label}`;
    }

    const columns = getColumns();

    return (
        <List
            title={title}
            columns={columns}
            dataSource={data || []}
            rowKey={item => item.createdAt}
            loading={loading}
            total={data?.length}
            pageSize={50}
            currentPage={1}
        />
    );
};
