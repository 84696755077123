import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

export const SideMenuItem = ({ text, icon, count, path, onClick }: Props) => {
    const location = useLocation();
    const isSelected = location.pathname.split('/')[1] === path.split('/')[1];
    const itemClassName = classNames('side-menu-item', isSelected && 'side-menu-item-selected');

    return (
        <Link to={path} onClick={onClick}>
            <div className={itemClassName}>
                <div className="side-menu-icon">{icon}</div>
                <div className="side-menu-text">{text}</div>
                <div className="side-menu-count">{count}</div>
            </div>
        </Link>
    );
};

interface Props {
    text: string;
    path: string;
    count?: string | number;
    icon?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}
