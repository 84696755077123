import { ListCelText, ListIconCelText } from 'components/List';
import { TemplateData } from 'components/Template';
import { Container, DocumentIcon, SingleListItem } from 'components/atomic';
import { getSuggestedFields } from 'services/suggestions.service';

export const DocumentHeader = ({ document }: Props) => {
    const fields = getSuggestedFields(document);

    return (
        <Container className="document-preview-header">
            <SingleListItem isActive importStatus={document.importStatus}>
                <>
                    <ListIconCelText
                        icon={<DocumentIcon />}
                        text={document.templateType}
                        subtext="Template type"
                    />
                    <ListCelText text={document.templateName} subtext="Template name" />
                    <ListCelText text={document.filename} subtext="File name" />
                    {document.amount && <ListCelText text={document.amount} subtext="Amount" />}
                    {fields.map((field, index) => (
                        <ListCelText
                            text={field.value}
                            subtext={field.label}
                            key={field.id + field.label + index}
                        />
                    ))}
                </>
            </SingleListItem>
        </Container>
    );
};

interface Props {
    document: TemplateData;
}
