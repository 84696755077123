import { addTemplateType, editTemplateType } from 'services';
import {
    IntegrationEntity,
    TemplateTypeSelectOption,
    TemplateFieldData,
    TemplateTypeData,
    TemplateTypeResponseData,
    Validation,
    TemplateFiledDataToSave,
} from '../types';

export const getIntegrationEntitiesOptions = (data: IntegrationEntity[] | null) => {
    if (!data) {
        return [];
    }

    const options: TemplateTypeSelectOption[] = data.map(({ id, label }) => ({ value: id, label }));

    return options;
};

export const getValidationsOptions = (data: Validation[] | null) => {
    if (!data) {
        return [];
    }

    const options: Validation[] = data.map(item => item);

    return options;
};

export const separateFields = (data?: TemplateTypeData) => {
    const separatedFields: [TemplateFieldData[], TemplateFieldData[]] = [[], []];

    if (!data || !data.fields) {
        return separatedFields;
    }

    data.fields.forEach(field => {
        if (field.isTable) {
            separatedFields[1].push(field);
        } else {
            separatedFields[0].push(field);
        }
    });

    return separatedFields;
};

export const getNewId = (items: TemplateFieldData[]) => {
    let fieldId = 1;

    [...items]
        .sort((a, b) => a.fieldId - b.fieldId)
        .forEach(field => {
            if (field.fieldId === fieldId) {
                fieldId++;
            }
        });

    return fieldId;
};

export const prepareInitialFormData = (data?: TemplateTypeResponseData): TemplateTypeData => {
    const initialData: TemplateTypeData = {
        label: '',
        fields: [],
    };

    if (!data) {
        return initialData;
    }

    initialData.label = data.label;
    initialData.value = data.value;
    initialData.integrationEntityId = data.integrationEntity?.id || null;
    initialData.fields = data.fields.map(
        ({ fieldId, isTable, label, position, propertyName, required, validation }) => {
            return {
                fieldId,
                isTable,
                label,
                position,
                propertyName,
                isRequired: required,
                isIdField: data.idField === fieldId,
                validation: validation,
                existingField: true,
            };
        }
    );

    return initialData;
};

export const prepareDataAndSave = async (
    allFields: TemplateFieldData[],
    values: TemplateTypeData,
    action: FormAction,
    typeId?: number
) => {
    const fields: TemplateFiledDataToSave[] = allFields.map(
        ({ fieldId, existingField, ...field }, index) => {
            const fieldToSave: TemplateFiledDataToSave = {
                ...field,
                position: index,
                isIdField: !!field.isIdField,
                validation: typeof field.validation === 'undefined' ? null : field.validation,
            };

            if (action === 'edit' && existingField) {
                fieldToSave.fieldId = fieldId;
            }

            return fieldToSave;
        }
    );

    const dataToSave = { ...values, fields };

    if (typeof dataToSave.integrationEntityId === 'undefined') {
        dataToSave.integrationEntityId = null;
    }

    delete dataToSave.value;

    if (action === 'add') {
        return await addTemplateType(dataToSave);
    } else if (typeof typeId === 'number') {
        return await editTemplateType(dataToSave, typeId);
    }

    return null;
};
