import { useMemo } from 'react';

import { Size, TextBoxData } from './types';
import { TemplateData } from '../types';
import { getScaleTextBox } from './helpers';

export const useBoxes = (
    template: TemplateData | null,
    currentPage: number,
    rect: DOMRect,
    scale: Size
): TextBoxData[] =>
    useMemo(
        () =>
            template?.map.pages[currentPage].textBoxes.map(box => ({
                scaleBox: getScaleTextBox(rect, scale, box),
                value: box,
            })) || [],
        [rect.width, rect.height, scale.width, scale.height, currentPage, template?.map]
    );
