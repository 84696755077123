import { TemplateTypesResponseData } from '../TemplateTypes/types';
import { UsersDataResponse } from '../../Users/types';

export const getTemplateTypesOptions = (data: TemplateTypesResponseData | null) => {
    if (!data) {
        return [];
    }

    return data.content.map(({ value, label }) => ({ value, label }));
};

export const getUsersOptions = (data: UsersDataResponse | null) => {
    if (!data) {
        return [];
    }

    return data.content.map(({ id, email }) => ({ value: id, label: email }));
};
