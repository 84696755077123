import { createReducer } from '@reduxjs/toolkit';
import { loadingOverviewDataAction, setOverviewDataAction } from 'state/actions';

import { OverviewState } from 'state/types';

const initialState: OverviewState = {
    data: {
        activeTemplates: 0,
        extractionCount: 0,
        extractionOcrCount: 0,
        extractionLimit: 0,
        failedImports: 0,
        importedDocuments: 0,
        importedPages: 0,
        totalImports: 0,
        moneySaved: 0,
        timeSaved: 0,
    },
    loading: false,
};

export const overviewReducer = createReducer(initialState, builder => {
    builder
        .addCase(setOverviewDataAction, (state, { payload }) => {
            state.data = payload;
        })
        .addCase(loadingOverviewDataAction, (state, { payload }) => {
            state.loading = payload;
        });
});
