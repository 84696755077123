import { useDrawer } from 'components/DrawerContainer';
import { UserForm } from './UserForm/UserForm';
import { ListActions } from 'components/List';
import { UsersListItem } from './types';

export const UsersListItemEdit = ({ data }: Props) => {
    const { openDrawer } = useDrawer();

    if (data.role === 'ROLE_ADMIN') {
        return null;
    }

    const handleEditClick = () => {
        openDrawer('Edit user', <UserForm data={data} action="edit" />);
    };

    return <ListActions onEditClick={handleEditClick} />;
};

interface Props {
    data: UsersListItem;
}
