import { createContext, useContext } from 'react';

export interface PageContext {
    itemsAmount: number;
    dataPage: number;
    dateRange: string;
    setItemsAmount: (value: number) => void;
    setDataPage: (value?: number) => void;
    setDateRange: (value: string) => void;
    pageId: string;
}

export const pageContext = createContext({} as PageContext);
export const PageContextProvider = pageContext.Provider;

export const usePageContext = () => useContext(pageContext);
