import { Button } from 'antd';

import { SingleList } from 'components/atomic';
import { MarkGeneralFieldsItem } from './MarkGeneralFieldsItem';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { generalFieldsAction, saveGeneralFieldsAction } from 'state/actions';

export const MarkGeneralFields = () => {
    const { dispatch, generalFields } = useTemplate();
    const { items, active, valid, loading } = generalFields;

    const handleItemClick = (index: number) => {
        dispatch(generalFieldsAction({ active: index }));
    };

    const handleSave = async () => {
        dispatch(saveGeneralFieldsAction());
    };

    return (
        <div>
            <SingleList
                dataSource={items}
                renderItem={(item, index) => (
                    <MarkGeneralFieldsItem field={item} isActive={active === index} />
                )}
                onItemClick={handleItemClick}
                activeIndex={active}
            />
            <Button
                type="primary"
                disabled={loading || !valid}
                loading={loading}
                onClick={handleSave}>
                Save and next
            </Button>
        </div>
    );
};
