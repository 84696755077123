import { useBoxes, SelectionArea } from 'components/Template';
import { SelectionTypeProps } from 'components/Template/Selection/types';
import { TemplateData } from 'components/Template';
import { useAppSelector } from 'hooks/redux.hooks';

export const DocumentHighlight = ({ rect, scale, document, currentPage }: Props) => {
    const highlight = useAppSelector(state => state.document.highlight);
    const boxes = useBoxes(document, currentPage, rect, scale);

    if (!highlight) {
        return null;
    }

    const boxesToHighlight = boxes.filter(box => highlight?.includes(box.value.id));

    return (
        <>
            {boxesToHighlight.map((box, key) => (
                <SelectionArea key={key} {...box.scaleBox} />
            ))}
        </>
    );
};

interface Props extends SelectionTypeProps {
    document: TemplateData;
    currentPage: number;
}
