import { createReducer } from '@reduxjs/toolkit';
import {
    documentLoadingAction,
    setCurrentDocumentDataAction,
    setCurrentDocumentIdAction,
    setDocumentCurrentPageAction,
    setDocumentHighlightAction,
} from 'state/actions';

import { DocumentState } from 'state/types';

const initialState: DocumentState = {
    loading: false,
    currentId: null,
    document: null,
    highlight: null,
    currentPage: 0,
};

export const documentReducer = createReducer(initialState, builder => {
    builder
        .addCase(documentLoadingAction, (state, { payload }) => {
            state.loading = payload;
        })
        .addCase(setCurrentDocumentIdAction, (state, { payload }) => {
            state.currentId = payload;
        })
        .addCase(setCurrentDocumentDataAction, (state, { payload }) => {
            state.document = payload;
        })
        .addCase(setDocumentHighlightAction, (state, { payload }) => {
            state.highlight = payload;
        })
        .addCase(setDocumentCurrentPageAction, (state, { payload }) => {
            state.currentPage = payload;
        });
});
