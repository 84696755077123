import classNames from 'classnames';

import config from 'configs';
import { EmailContent } from './contents';
import { useAppSelector } from 'hooks/redux.hooks';
import { replaceEmailText } from './helpers';

export const EmailLink = ({
    children,
    className,
    email = config.infoEmail,
    emailContent = { subject: '', body: '' },
    ...props
}: Props) => {
    const { data: user } = useAppSelector(state => state.user);
    const { data: overview } = useAppSelector(state => state.overview);
    const { data: license } = useAppSelector(state => state.license);
    const linkClass = classNames('email-link', className);

    const handleClick = () => {
        let href = `mailto:${email}`;
        href += `?subject=${encodeURIComponent(
            replaceEmailText(emailContent.subject, user, overview, license)
        )}`;
        href += `&body=${encodeURIComponent(
            replaceEmailText(emailContent.body, user, overview, license)
        )}`;

        window.location.href = href;
    };

    return (
        <a onClick={handleClick} className={linkClass} {...props}>
            {children}
        </a>
    );
};

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    email?: string;
    emailContent?: EmailContent;
}
