export const ViewToggleOption = ({ icon, text }: Props) => {
    return (
        <div className="view-toggle-option">
            <div className="view-toggle-icon">{icon}</div>
            {text}
        </div>
    );
};

interface Props {
    icon: React.ReactNode;
    text: string;
}
