import { AmountFilter, Page, PageHeader } from 'components/Page';
import { AddUserButton } from 'components/Users/AddUser/AddUserButton';
import { UsersList } from 'components/Users/UsersList';
import { Divider } from 'components/atomic';

export const UsersPage = () => {
    return (
        <Page pageId="users">
            <PageHeader title="Users Management">
                <AmountFilter />
                <Divider vertical />
                <AddUserButton />
            </PageHeader>
            <UsersList />
        </Page>
    );
};
