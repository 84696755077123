import { useEffect, useRef, useState } from 'react';

import { MinusIcon, PlusIcon } from 'components/atomic';
import { round, roundToAliquot } from './helpers';

export const ZOOM_STEP = 0.2;
export const MAX_ZOOM = 3;
export const MIN_ZOOM = 0.4;

const DELTA = MAX_ZOOM - MIN_ZOOM;

export const FileZoom = ({ onPlusClick, onMinusClick, onTrackClick, zoom }: Props) => {
    const [trackHeight, setTrackHeight] = useState(0);
    const trackRef = useRef<HTMLDivElement>(null);
    const level = round((trackHeight * (zoom - MIN_ZOOM)) / DELTA);

    const handleTrackClick: React.MouseEventHandler<HTMLDivElement> = event => {
        if (trackRef.current) {
            const { y, height } = trackRef.current.getBoundingClientRect();
            const pressedLevel = height - (event.clientY - y);
            const newZoom = roundToAliquot(
                round((pressedLevel * DELTA) / height + MIN_ZOOM),
                ZOOM_STEP
            );
            onTrackClick(newZoom);
        }
    };

    useEffect(() => {
        if (trackRef.current) {
            const { height } = trackRef.current.getBoundingClientRect();
            setTrackHeight(height);
        }
    }, [trackRef.current]);

    return (
        <div className="zoom">
            <div className="zoom-button plus" onClick={onPlusClick}>
                <PlusIcon />
            </div>
            <div className="zoom-track" ref={trackRef} onClick={handleTrackClick}>
                <div className="zoom-level" style={{ height: level }} />
            </div>
            <div className="zoom-button minus" onClick={onMinusClick}>
                <MinusIcon />
            </div>
        </div>
    );
};

interface Props {
    onPlusClick: () => void;
    onMinusClick: () => void;
    onTrackClick: (zoom: number) => void;
    zoom: number;
}
