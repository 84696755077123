import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { ListIconCelText } from 'components/List';
import { DocumentIcon } from 'components/atomic';
import { TemplateAction } from './TemplateAction';
import { formatPlural } from 'utils';
import { TemplateTypeResponseData } from './types';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { setSelectedTypeAction } from 'state/actions';

export const TemplateTypesItem = ({ data }: Props) => {
    const dispatch = useAppDispatch();
    const selectedType = useAppSelector(state => state.ui.selectedType);
    const isSelected = selectedType?.value === data.value;
    const containerClass = classNames(
        'template-types-list-item',
        isSelected && 'template-type-selected'
    );
    const itemRef = useRef<HTMLDivElement>(null);

    const handleClick: React.MouseEventHandler<HTMLDivElement> = ({ target }) => {
        if (target instanceof HTMLElement && target.className !== 'ant-dropdown-trigger') {
            dispatch(setSelectedTypeAction(data));
        }
    };

    useEffect(() => {
        const scrollElement = document.querySelector('.template-types-list');
        if (itemRef.current && scrollElement && isSelected) {
            scrollElement.scrollTo({
                behavior: 'smooth',
                top: itemRef.current.offsetTop,
            });
        }
    }, [itemRef.current]);

    return (
        <div className={containerClass} onClick={handleClick} ref={itemRef}>
            <ListIconCelText
                icon={<DocumentIcon />}
                text={data.label}
                subtext={`${data.templatesCount} ${formatPlural('template', data.templatesCount)}`}
            />
            <TemplateAction id={data.value} label={data.label} />
        </div>
    );
};

interface Props {
    data: TemplateTypeResponseData;
}
