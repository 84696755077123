import { useState } from 'react';
import { Button, Form, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useDrawer } from 'components/DrawerContainer';
import { CommonForm, Container } from 'components/atomic';
import { ImportData } from './types';
import { uploadDocument } from 'services/docs.service';
import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { TemplateTypeSelect, UploadDocument } from 'components/Templates/FormItems';
import { getTemplatesOptions } from './helpers';
import { TemplatesDataResponse } from 'components/Templates';
import { routes } from 'navigator';

export const ImportForm = ({ data }: Props) => {
    const navigate = useNavigate();
    const { closeDrawer } = useDrawer();
    const { data: templatesData, loading: loadingTemplates } = useData<TemplatesDataResponse>(
        apiRoutes.templates
    );
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<ImportData>();
    const type = Form.useWatch('type', form);

    const handleFinish = async (values: ImportData) => {
        setLoading(true);
        const document = await uploadDocument(values);

        if (document && document.orderId) {
            navigate(`${routes.documents}/${document.orderId}?ids=${document.orderIds.join(',')}`);
        }

        handleClose();
        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    const handleTypeChange = () => {
        form.setFieldValue('template', undefined);
    };

    return (
        <CommonForm
            name="importDocumentForm"
            onFinish={handleFinish}
            form={form}
            initialValues={data}>
            <Container className="form-container">
                <TemplateTypeSelect onChange={handleTypeChange} isRequired={false} />
                <Form.Item<ImportData> label="Template" name="template" required={false}>
                    <Select
                        loading={loadingTemplates}
                        options={getTemplatesOptions(templatesData, type)}
                        placeholder="Select template"
                        disabled={!type}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
                <UploadDocument multiple={true} />
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Save
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

interface Props {
    documentId?: number;
    data?: Partial<ImportData>;
}
