import { createAction } from '@reduxjs/toolkit';
import { DocumentActionTypes, ImportResult } from 'state/types';
import { TemplateData } from 'components/Template';

export const documentsImportAction = createAction(DocumentActionTypes.IMPORT_DOCUMENTS);
export const exportResultAction = createAction<ImportResult | null>(
    DocumentActionTypes.IMPORT_RESULT
);
export const documentLoadingAction = createAction<boolean>(DocumentActionTypes.LOADING);
export const setCurrentDocumentIdAction = createAction<number | null>(
    DocumentActionTypes.CURRENT_ID
);
export const setCurrentDocumentDataAction = createAction<TemplateData | null>(
    DocumentActionTypes.DOCUMENT_DATA
);
export const setDocumentHighlightAction = createAction<number[] | null>(
    DocumentActionTypes.HIGHLIGHT
);
export const setDocumentCurrentPageAction = createAction<number>(DocumentActionTypes.CURRENT_PAGE);
