import { useNavigate } from 'react-router-dom';

import { ListActionsProps, ListActions } from 'components/List';
import { routes } from 'navigator';
import { useState } from 'react';
import { DeleteDocumentTemplateModal } from './DeleteDocumentTemplateModal';
import { getTemplateData } from 'services';
import { useDrawer } from 'components/DrawerContainer';
import { DocumentTemplateForm } from './DocumentTemplateForm';
import { TemplateByTypeData } from './types';
import { TemplateCloneForm } from './TemplateCloneForm';

export const DocumentTemplatesEdit = ({ item, typeId }: Props) => {
    const navigate = useNavigate();
    const { openDrawer } = useDrawer();
    const [open, setOpen] = useState(false);

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleViewClick = () => {
        navigate(`${routes.templates}/${item.id}`);
    };

    const handleCloneClick = async () => {
        const template = await getTemplateData(item.id);

        if (template) {
            openDrawer(
                `Clone template ${template.templateName}`,
                <TemplateCloneForm
                    data={{
                        name: template.templateName + '_copy',
                        type: typeId,
                    }}
                    id={item.id}
                />
            );
        }
    };

    const handleEditClick = async () => {
        const template = await getTemplateData(item.id);

        if (template) {
            openDrawer(
                `Edit template ${template.templateName}`,
                <DocumentTemplateForm
                    data={{
                        name: template.templateName,
                        businessEntityId: template.businessEntityId,
                        type: typeId,
                        userIds: template.userIds,
                    }}
                    id={item.id}
                    customDataList={template.customDataList}
                />
            );
        }
    };

    const listActionProp: ListActionsProps = {
        onDeleteClick: handleDeleteClick,
        onEditClick: handleEditClick,
        onCloneClick: handleCloneClick,
    };

    if (item.currentStep === 'COMPLETE') {
        listActionProp.onViewClick = handleViewClick;
    } else {
        listActionProp.onSetClick = handleViewClick;
    }

    return (
        <>
            <ListActions {...listActionProp} />
            <DeleteDocumentTemplateModal
                open={open}
                setOpen={setOpen}
                id={item.id}
                typeId={typeId}
                templateName={item.templateName}
            />
        </>
    );
};

interface Props {
    item: TemplateByTypeData;
    typeId: number;
}
