export enum UIActionTypes {
    SELECTED_TYPE = 'SELECTED_TYPE',
    SHOW_ADDRESS_TABLE = 'SHOW_ADDRESS_TABLE',
}

export interface UIState {
    selectedType: null | SelectedType;
    showAddressTable: boolean;
}

export interface SelectedType {
    /**
     * Actually id of the template
     */
    value: number;
    /**
     * Name of the template
     */
    label: string;
}
