export const OverviewLayout = ({ kpi, list, right }: Props) => {
    return (
        <div className="overview-layout">
            <div className="overview-layout-left">
                <div className="overview-layout-kpi">{kpi}</div>
                <div className="overview-layout-list">{list}</div>
            </div>
            <div className="overview-layout-right">{right}</div>
        </div>
    );
};

interface Props {
    kpi?: React.ReactNode;
    list?: React.ReactNode;
    right?: React.ReactNode;
}
