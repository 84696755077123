import { Button } from 'antd';

import { SingleList } from 'components/atomic';
import { useTemplate } from 'components/Template/useTemplate.hook';
import { columnsToTemplateAction, saveColumnsToTemplateAction } from 'state/actions';
import { MarkColumnsToTemplateItem } from './MarkColumnsToTemplateItem';

export const MarkColumnsToTemplate = () => {
    const { dispatch, columnsToTemplate } = useTemplate();
    const { items, active, loading } = columnsToTemplate;

    const handleItemClick = (index: number) => {
        dispatch(columnsToTemplateAction({ active: index }));
    };

    const handleSave = async () => {
        dispatch(saveColumnsToTemplateAction());
    };

    return (
        <div>
            <SingleList
                dataSource={items}
                renderItem={item => <MarkColumnsToTemplateItem {...item} />}
                onItemClick={handleItemClick}
                activeIndex={active}
            />
            <Button type="primary" disabled={loading} loading={loading} onClick={handleSave}>
                Save
            </Button>
        </div>
    );
};
