import { Button } from 'antd';

import { ExcelIcon } from 'components/atomic';
import { downloadFile } from './helpers';

export const DownloadButton = () => {
    const handleDownloadTemplate = () => {
        downloadFile();
    };

    return (
        <Button
            className="translations-download"
            size="middle"
            onClick={handleDownloadTemplate}
            icon={
                <div className="icon">
                    <ExcelIcon />
                </div>
            }>
            Download table
        </Button>
    );
};
