import { createContext, useContext } from 'react';
import { ConfirmMenuProps } from '../types';

export interface SelectionContext {
    confirmMenu: ConfirmMenuProps | null;
    setConfirmMenu: (value: ConfirmMenuProps | null) => void;
}

export const selectionContext = createContext({} as SelectionContext);
export const SelectionContextProvider = selectionContext.Provider;

export const useSelection = () => useContext(selectionContext);
