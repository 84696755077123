import { useState } from 'react';

import { SelectionArea } from '../SelectionArea';
import { SelectionHandler } from '../SelectionHandler';
import { Box, SelectionEventHandler, SelectionTypeProps } from '../types';
import { useSelection } from '../context';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setSelectionValue } from 'state/actions';

export const AreaSelection = ({ rect, scale }: SelectionTypeProps) => {
    const dispatch = useAppDispatch();
    const { setConfirmMenu } = useSelection();
    const [box, setBox] = useState<Box | null>(null);

    const handleSelectionStart: SelectionEventHandler = event => {
        setConfirmMenu(null);
        setBox({
            left: event.start.left,
            top: event.start.top,
            height: event.size.height,
            width: event.size.width,
        });
    };

    const handleSelectionChange: SelectionEventHandler = event => {
        setBox({
            left: event.start.left,
            top: event.start.top,
            height: event.size.height,
            width: event.size.width,
        });
    };

    const handleSelectionEnd: SelectionEventHandler = event => {
        setConfirmMenu({
            position: event.mouse,
            onConfirm: () => {
                dispatch(
                    setSelectionValue({
                        area: {
                            startX: event.scaleStart.left,
                            endX: event.scaleEnd.left,
                            startY: event.scaleStart.top,
                            endY: event.scaleEnd.top,
                        },
                    })
                );
                setBox(null);
            },
        });
    };

    return (
        <>
            <SelectionHandler
                scale={scale}
                rect={rect}
                onSelectionStart={handleSelectionStart}
                onSelectionChange={handleSelectionChange}
                onSelectionEnd={handleSelectionEnd}
            />
            {box && <SelectionArea {...box} />}
        </>
    );
};
