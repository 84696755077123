import { AppNotifications } from 'components/AppNotifications/AppNotifications';
import { useAppSelector } from 'hooks/redux.hooks';
import { Router } from 'navigator';

export const App = () => {
    const initialized = useAppSelector(state => state.init.initialized);

    if (initialized) {
        return (
            <>
                <Router />
                <AppNotifications />
            </>
        );
    }

    return <></>;
};
