import { useEffect, useState } from 'react';

import { PageContextProvider } from './context';
import {
    getSavedAmountFilterValue,
    getSavedDateFilterValue,
    saveAmountFilterValue,
    saveDateFilterValue,
} from './helpers';

export const Page = ({ children, pageId }: Props) => {
    const [itemsAmount, setItemsAmount] = useState(getSavedAmountFilterValue(pageId));
    const [dataPage, setDataPage] = useState(1);
    const [dateRange, setDateRange] = useState<string>(getSavedDateFilterValue());

    useEffect(() => {
        saveAmountFilterValue(pageId, itemsAmount);
    }, [itemsAmount]);

    useEffect(() => {
        saveDateFilterValue(dateRange);
    }, [dateRange]);

    const handleSetDataPage = (value?: number) => {
        if (typeof value === 'number') {
            setDataPage(value);
        }
    };

    return (
        <PageContextProvider
            value={{
                itemsAmount,
                dataPage,
                dateRange,
                setItemsAmount,
                setDataPage: handleSetDataPage,
                setDateRange,
                pageId,
            }}>
            {children}
        </PageContextProvider>
    );
};

interface Props extends WithChildren {
    pageId: string;
}
