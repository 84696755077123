import { useEffect } from 'react';

import { usePageData } from 'components/Page';
import { useAppDispatch } from 'hooks/redux.hooks';
import { apiRoutes } from 'services';
import { loadingOverviewDataAction, setOverviewDataAction } from 'state/actions';
import { OverviewResponseData } from 'state/types';

const TIME_SAVED_MULTIPLIER = 0.25;
const MONEY_SAVED_MULTIPLIER = 30;

export const OverviewDataHelper = () => {
    const dispatch = useAppDispatch();
    const { data, loading } = usePageData<OverviewResponseData>(apiRoutes.overview);

    useEffect(() => {
        if (data) {
            dispatch(
                setOverviewDataAction({
                    ...data,
                    timeSaved: data.importedPages * TIME_SAVED_MULTIPLIER,
                    moneySaved: data.importedPages * TIME_SAVED_MULTIPLIER * MONEY_SAVED_MULTIPLIER,
                })
            );
        }
    }, [data]);

    useEffect(() => {
        dispatch(loadingOverviewDataAction(loading));
    }, [loading]);

    return null;
};
