import { useState } from 'react';
import { Button, Input, Form } from 'antd';

import { useAppDispatch } from 'hooks/redux.hooks';
import { loginWithEmail } from 'services/user.service';
import { loginAction } from 'state/actions';
import { CommonForm } from 'components/atomic';

export const LoginForm = ({ setError }: Props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const onFinish = async (values: FieldType) => {
        setLoading(true);
        setError(false);

        const response = await loginWithEmail(values);

        setLoading(false);

        if (!response) {
            setError(true);
            return;
        }

        dispatch(loginAction(response));
    };

    return (
        <CommonForm
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="on">
            <Form.Item<FieldType>
                label="Email"
                name="email"
                className="login-label"
                required={false}
                rules={[{ required: true, message: 'Please input your email' }]}>
                <Input id="login_email" autoComplete="email" autoFocus />
            </Form.Item>

            <Form.Item<FieldType>
                label="Password"
                name="password"
                className="login-label login-password"
                required={false}
                rules={[{ required: true, message: 'Please input your password' }]}>
                <Input.Password id="login_password" autoComplete="current-password" />
            </Form.Item>

            {/* <div className="login-row">
                <Form.Item<FieldType>
                    name="remember"
                    className="login-rememberme"
                    valuePropName="checked">
                    <Checkbox>Remember for 30 days</Checkbox>
                </Form.Item>
                <Link to="">Forgot password?</Link>
            </div> */}

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                Log in
            </Button>
        </CommonForm>
    );
};

type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
};

interface Props {
    setError: (value: boolean) => void;
}
