import { Spin } from 'antd';

import { ExportOutputData } from 'components/Template';
import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { DocumentError } from './DocumentError';
import { ContainerCenter, Divider } from 'components/atomic';

export const DocumentOutput = ({ id }: Props) => {
    const { data, loading } = useData<ExportOutputData>(
        `${apiRoutes.document}/${id}/export?outputType=JSON`
    );

    if (!data && loading) {
        return (
            <ContainerCenter>
                <Spin size="large" />
            </ContainerCenter>
        );
    }

    if (!data) {
        return <DocumentError />;
    }

    return (
        <div className="document-preview-info document-output">
            <div className="extracted-preview">
                <div className="extracted-preview-title">General Fields</div>
                <Divider />
                <table className="extracted-preview-general-fields">
                    <tbody>
                        {Object.entries(data.HEADER).map(([key, value]) => (
                            <tr className="extracted-preview-row" key={key}>
                                <td className="extracted-preview-gf-label">{key}</td>
                                <td className="extracted-preview-gf-value">
                                    <div className="extracted-preview-edit">{value}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Divider />
                <div className="extracted-preview-title">Table Values</div>
                <table cellPadding={0} cellSpacing={0} className="extracted-preview-table">
                    <thead>
                        <tr>
                            {data.LineItems.Line[0] &&
                                Object.keys(data.LineItems.Line[0]).map((item, index) => (
                                    <th key={'outputField' + index}>{item}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.LineItems.Line.map((row, rowIndex) => (
                            <tr key={'outputTableRow' + rowIndex.toString()}>
                                {Object.values(row).map((value, colIndex) => (
                                    <td
                                        key={
                                            'outputTableCell' +
                                            rowIndex.toString() +
                                            colIndex.toString()
                                        }>
                                        {value}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

interface Props {
    id: number;
}
