import { useEffect } from 'react';

import { EmptyTemplate } from './EmptyTemplate';
import { useTemplateData } from './useTemplate.hook';
import { TemplateLayout } from 'components/Layouts';
import { Steps } from './Steps/Steps';
import { PageHeader } from 'components/Page';
import { Menu, SaveTemplateButton, ViewToggle } from './Controls';
import { Divider, Loading } from 'components/atomic';
import { clearTemplateStateAction, setTemplateDataAction } from 'state/actions';
import { useAppDispatch } from 'hooks/redux.hooks';
import { TemplateView } from './TemplateView';

export const Template = ({ id }: Props) => {
    const dispatch = useAppDispatch();
    const { file, template, loading } = useTemplateData(id);

    useEffect(() => {
        dispatch(setTemplateDataAction(template));
    }, [template]);

    useEffect(() => {
        return () => {
            dispatch(clearTemplateStateAction());
        };
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (!file || !template) {
        return <EmptyTemplate />;
    }

    return (
        <>
            <PageHeader title={`Template: ${template.templateName}`}>
                <ViewToggle />
                <Menu id={id} />
                <Divider vertical />
                <SaveTemplateButton />
            </PageHeader>
            <Divider />
            <TemplateLayout>
                <Steps />
                <TemplateView file={file} />
            </TemplateLayout>
        </>
    );
};

interface Props {
    id: number;
}
