import { useDrawer } from 'components/DrawerContainer';
import { PostProcessRuleForm } from './PostProcessRuleForm';

export const PostProcessRuleLabel = ({ fieldId, templateId, label, isTable }: Props) => {
    const { openDrawer } = useDrawer();

    const handlePostProcessRuleClick = () => {
        openDrawer(
            'Set post-process rule',
            <PostProcessRuleForm
                fieldId={fieldId}
                fieldName={label}
                templateId={templateId}
                isTable={!!isTable}
            />
        );
    };

    return <div onClick={handlePostProcessRuleClick}>Set post process rule</div>;
};

interface Props {
    fieldId: number;
    templateId: number;
    label: string;
    isTable?: boolean;
}
