import { CollapsibleBlock } from 'components/atomic';
import { StepConfig } from './types';
import { StepTitle } from './StepTitle';
import { MarkHeaderAndFooter } from './MarkHeaderAndFooter/MarkHeaderAndFooter';
import { MarkGeneralFields } from './MarkGeneralFields/MarkGeneralFields';
import { MarkTable } from './MarkTable/MarkTable';
import { MarkColumnsToTemplate } from './MarkColumnsToTemplate/MarkColumnsToTemplate';
import { MarkTableColumns } from './MarkTableColumns/MarkTableColumns';
import { expandStepAction } from 'state/actions';
import { useTemplate } from '../useTemplate.hook';
import { Tip } from '../Tip';

export const Steps = () => {
    const { dispatch, expanded, tableMode, disabled } = useTemplate();

    const handleExpandedChange = (index: number) => {
        dispatch(expandStepAction(index));
    };

    if (tableMode === 'auto') {
        items[3] = {
            title: 'Fit columns to template',
            children: <MarkColumnsToTemplate />,
        };
    } else {
        items[3] = {
            title: 'Mark table columns',
            children: <MarkTableColumns />,
        };
    }

    return (
        <>
            {items.map(({ children, title }, index) => (
                <CollapsibleBlock
                    title={
                        <StepTitle index={index} active={expanded[index]}>
                            {title}
                        </StepTitle>
                    }
                    key={title}
                    expanded={expanded[index]}
                    disabled={disabled[index]}
                    onChange={() => handleExpandedChange(index)}>
                    {children}
                </CollapsibleBlock>
            ))}
            <Tip />
        </>
    );
};

const items: StepConfig[] = [
    {
        title: 'Mark header and footer',
        children: <MarkHeaderAndFooter />,
    },
    {
        title: 'Mark general fields',
        children: <MarkGeneralFields />,
    },
    {
        title: 'Mark table area',
        children: <MarkTable />,
    },
];
