import { Tooltip } from 'antd';
import { HelpIcon } from '../Icon/Icon';

export const TooltipIcon = ({ children, suffix }: Props) => {
    return (
        <Tooltip title={children} className="tooltip-container">
            <div className="tooltip-icon">
                <HelpIcon />
            </div>
            {suffix ?? <span className="tooltip-suffix">{suffix}</span>}
        </Tooltip>
    );
};

interface Props extends WithChildren {
    suffix?: string;
}
