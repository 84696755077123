import classNames from 'classnames';

import { LogoSize, LogoType } from './types';

export const LogoImage = ({ className, size = 'normal', type = 'default' }: Props) => {
    const logoClassName = classNames(
        'logo-image',
        `logo-image-size-${size}`,
        `logo-image-type-${type}`,
        className
    );

    return <div className={logoClassName} />;
};

interface Props {
    className?: string;
    size?: LogoSize;
    type?: LogoType;
}
