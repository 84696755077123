import classNames from 'classnames';

import { Container } from '../Container/Container';
import { Divider } from '../Divider/Divider';
import { TooltipIcon } from '../TooltipIcon/TooltipIcon';

export const Block = ({ title, children, description, className, controls }: Props) => {
    const containerClassName = classNames('block', className);

    return (
        <Container className={containerClassName}>
            <div className="block-header">
                <h2>{title}</h2>
                {!!description && <TooltipIcon>{description}</TooltipIcon>}
                {!!controls && <div className="block-controls">{controls}</div>}
            </div>
            <div className="block-content">
                <Divider />
                {children}
            </div>
        </Container>
    );
};

interface Props extends WithChildren, WithClassName {
    title: string;
    description?: string;
    controls?: React.ReactNode;
}
