import { useEffect, useState } from 'react';
import { addListener } from '@reduxjs/toolkit';

import { api } from 'services';
import { useAppDispatch } from './redux.hooks';
import queryString from 'query-string';

export const useData = <T>(path: string | null): DataResponse<T> => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        if (path) {
            setLoading(true);
            api.fetch<T>(path, 'get')
                .then(value => setData(value))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (path === null) {
            setLoading(false);
            return;
        }

        fetchData();

        return dispatch(
            addListener({
                type: getListenerType(path),
                effect: () => {
                    fetchData();
                },
            })
        ) as unknown as () => void;
    }, [path]);

    return {
        data,
        loading,
    };
};

const LISTENER_TYPE_PREFIX = 'REQUEST_DATA_UPDATE_';

export const getListenerType = (path: string) => {
    const withoutQuery = queryString.parseUrl(path).url;

    return `${LISTENER_TYPE_PREFIX}${withoutQuery.toUpperCase()}`;
};

export const getListenerAction = (path: string) => ({ type: getListenerType(path) });

export interface DataResponse<T> {
    data: T | null;
    loading: boolean;
}
