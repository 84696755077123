import { List, ListProps } from 'antd';

import { SingleListItem } from './SingleListItem';

export const SingleList = <T extends any>({
    renderItem,
    activeIndex,
    onItemClick,
    ...props
}: Props<T>) => {
    return (
        <List
            className="single-list"
            renderItem={(item, index) => (
                <SingleListItem
                    isActive={index === activeIndex}
                    index={index}
                    onClick={onItemClick}>
                    {renderItem && renderItem(item, index)}
                </SingleListItem>
            )}
            pagination={false}
            {...props}
        />
    );
};

interface Props<T> extends ListProps<T> {
    activeIndex?: number | null;
    onItemClick?: (index: number) => void;
}
