import { Button, ButtonProps } from 'antd';

import { useDrawer } from 'components/DrawerContainer';
import { PlusButton } from 'components/atomic';
import { TemplateTypeForm } from './TemplateTypeForm/TemplateTypeForm';

export const AddDocumentTypeButton = ({ type }: Props) => {
    const { openDrawer } = useDrawer();
    const text = buttonText[type];

    const buttonProps: ButtonProps = {
        children: text,
        onClick: () => {
            openDrawer('Add Doc Type', <TemplateTypeForm action="add" />);
        },
    };

    return type == 'link' ? (
        <Button type="link" {...buttonProps} />
    ) : (
        <PlusButton {...buttonProps} />
    );
};

const buttonText: Record<Props['type'], string> = {
    button: 'New Doc type',
    link: '+ Add Document Type',
};

interface Props {
    type: 'link' | 'button';
}
