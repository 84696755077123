export const Badge = ({ text, ...props }: Props) => {
    return (
        <div className="badge" {...props}>
            {text}
        </div>
    );
};

interface Props extends React.DOMAttributes<HTMLDivElement> {
    text: string;
}
