import { saveSettings } from 'services/settings.service';
import { SettingsData } from './types';

let queue: Partial<SettingsData> = {};
let timeout: ReturnType<typeof setTimeout> | null = null;
let DEBOUNCE_TIMEOUT = 2000;

export const saveData = (values: Partial<SettingsData>) => {
    queue = { ...queue, ...values };

    if (!timeout) {
        timeout = setTimeout(() => {
            saveSettings(queue);
            timeout = null;
        }, DEBOUNCE_TIMEOUT);
    }
};
