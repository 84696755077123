import { useEffect, useState } from 'react';

import { DocumentTabBar } from './DocumentTabBar';
import { DocumentPreview } from '../DocumentPreview/DocumentPreview';

export const DocumentTabs = ({ ids }: Props) => {
    const [active, setActive] = useState(ids[0]);

    const handleChange = (id: number) => {
        setActive(id);
    };

    useEffect(() => {
        setActive(ids[0]);
    }, [ids]);

    return (
        <div className="document-tabs">
            {ids.length > 1 && (
                <DocumentTabBar ids={ids} activeId={active} onChange={handleChange} />
            )}
            <DocumentPreview id={active} />
        </div>
    );
};

interface Props {
    ids: number[];
}
