import { useEffect, useState } from 'react';
import { api, apiRoutes } from '../../services';
import { PdfViewer } from '../PdfViewer';
import * as React from 'react';

export const MatchViewer = ({ id }: Props) => {
    const [file, setFile] = useState<Blob | null>(null);

    const fetchData = () => {
        api.getFile(`${apiRoutes.documentPdf}/${id}`).then(data => {
            setFile(data);
        });
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <div className="match-view">
            <PdfViewer currentPage={1} file={file} width={350} />
        </div>
    );
};

interface Props {
    id?: number;
}
