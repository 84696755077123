import { AddAddressTableButton, AddressTable } from 'components/AddressTable';
import { Page, PageHeader } from 'components/Page';

export const AddressTablePage = () => {
    return (
        <Page pageId="addressTable">
            <PageHeader title="Addresses Management">
                <AddAddressTableButton />
            </PageHeader>
            <AddressTable />
        </Page>
    );
};
