import { Form, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';

import { DeleteIcon, DocumentIcon } from 'components/atomic';
import { useState } from 'react';

export const UploadDocument = ({ multiple = false }: Props) => {
    const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
    const form = Form.useFormInstance();
    const files: File[] = Form.useWatch('files', form);
    let text = 'Drag & drop or click to select';

    if (files?.length) {
        text = files.map(item => item.name).join(', ');
    }

    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        let ary: UploadFile[] = info.fileList;
        if (!multiple) {
            ary = [info.fileList[info.fileList.length - 1]];
        }
        setUploadFiles(info.fileList);

        form.setFieldValue(
            'files',
            ary.map(item => item.originFileObj)
        );

        return;
    };

    const handleClear: React.MouseEventHandler<HTMLDivElement> = event => {
        event.stopPropagation();
        event.preventDefault();
        setUploadFiles([]);
        form.setFieldValue('files', []);
    };

    return (
        <Form.Item
            label="Doc sample"
            name="files"
            required={false}
            rules={[{ required: true, message: 'Please upload document' }]}
            valuePropName="">
            <Upload.Dragger
                name="file"
                multiple={multiple}
                onChange={handleChange}
                className="document-upload"
                showUploadList={false}
                accept=".pdf,.doc,.docx"
                customRequest={() => {}}
                fileList={uploadFiles}>
                <div className="document-upload-content">
                    <div className="document-upload-icon">
                        <DocumentIcon />
                    </div>
                    <p className="document-upload-text">{text}</p>
                    {!!uploadFiles.length && (
                        <div className="document-upload-delete-icon" onClick={handleClear}>
                            <DeleteIcon />
                        </div>
                    )}
                </div>
            </Upload.Dragger>
        </Form.Item>
    );
};

interface Props {
    multiple?: boolean;
}

export interface UploadFormData {
    files?: File[];
}
