import { Rule } from 'antd/es/form';

export const validateByRule = (rule: PasswordValidationRule, value?: string): boolean => {
    if (!value) {
        return false;
    }

    if (rule.regexp) {
        return rule.regexp.test(value);
    }

    if (rule.min) {
        return value.length >= rule.min;
    }

    return true;
};

export const passwordFormRule: Rule = {
    validator: (_, value) => {
        const isValid = passwordRulesConfig.every(rule => validateByRule(rule, value));
        return isValid
            ? Promise.resolve()
            : Promise.reject(new Error('Password should meet requirements below'));
    },
    validateTrigger: 'onBlur',
};

export const passwordRulesConfig: PasswordValidationRule[] = [
    {
        id: '1',
        text: 'Low case letters',
        regexp: /(?=.*[a-z])/g,
    },
    {
        id: '2',
        text: 'High case letters',
        regexp: /(?=.*[A-Z])/g,
    },
    {
        id: '3',
        text: 'Numbers',
        regexp: /(?=.*\d)/g,
    },
    {
        id: '4',
        text: 'Special character (e.g. #, !, $, etc…)',
        regexp: /(?=.*[!@#\$%\^\&*\)\(+=._-])/g,
    },
    {
        id: '5',
        text: 'At least 8 symbols',
        min: 8,
    },
];

export interface PasswordValidationRule {
    id: string;
    text: string;
    regexp?: RegExp;
    min?: number;
}
