import { Button } from 'antd';
import { useState } from 'react';

import { DeleteErrorQueueModal } from './DeleteErrorQueueModal';
import { useGenericDocsListContext } from 'components/GenericDocsList';
import { FailedImportData } from './types';

export const ErrorQueueListControls = () => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { selected, clearSelection } = useGenericDocsListContext<FailedImportData>();

    const handleDeleteClick = async () => {
        setDeleteOpen(true);
    };

    return (
        <div className="documents-list-controls">
            {!!selected.length && (
                <div className="documents-list-controls-amount">
                    {selected.length} docs selected
                </div>
            )}
            <Button disabled={!selected.length} onClick={handleDeleteClick}>
                Delete
            </Button>
            <DeleteErrorQueueModal
                open={deleteOpen}
                setOpen={setDeleteOpen}
                selected={selected}
                clearSelection={clearSelection}
            />
        </div>
    );
};
