import { Select } from 'antd';
import { useState } from 'react';
import { DeleteDocumentModal } from './DeleteDocumentModal';
import { useGenericDocsListContext } from 'components/GenericDocsList';
import { DocumentData } from './types';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigator';

export const DocumentsListControls = () => {
    const navigate = useNavigate();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [value, setValue] = useState<DocumentActionOptions | null>(null);
    const { selected, clearSelection } = useGenericDocsListContext<DocumentData>();

    const handleChange = async (action: DocumentActionOptions) => {
        if (action === DocumentActionOptions.import) {
            const ids = selected.map(item => item.id).join(',');

            clearSelection && clearSelection();
            navigate(`${routes.documents}/${selected[0].id}?ids=${ids}`);
        }

        if (action === DocumentActionOptions.delete) {
            setDeleteOpen(true);
        }

        setValue(null);
    };

    return (
        <div className="documents-list-controls">
            {!!selected.length && (
                <div className="documents-list-controls-amount">
                    {selected.length} docs selected
                </div>
            )}
            <Select
                className="documents-list-controls-actions"
                options={documentActionOptions}
                onChange={handleChange}
                placeholder="Select action"
                value={value}
                disabled={!selected.length}
            />
            <DeleteDocumentModal
                open={deleteOpen}
                setOpen={setDeleteOpen}
                selected={selected}
                clearSelection={clearSelection}
            />
        </div>
    );
};

enum DocumentActionOptions {
    import,
    delete,
}

const documentActionOptions = [
    { value: DocumentActionOptions.import, label: 'View documents' },
    { value: DocumentActionOptions.delete, label: 'Delete documents' },
];
