import { PlusButton } from 'components/atomic';

import { UserForm } from '../UserForm/UserForm';
import { useDrawer } from 'components/DrawerContainer';

export const AddUserButton = () => {
    const { openDrawer } = useDrawer();

    const handleClick = () => {
        openDrawer('Add User', <UserForm action="add" />);
    };

    return <PlusButton onClick={handleClick}>Add User</PlusButton>;
};
