import { createAction } from '@reduxjs/toolkit';
import {
    ColumnsToTemplateSetValue,
    ColumnsToTemplateState,
    GeneralFieldsState,
    GetTemplatePayload,
    HeaderAndFooterState,
    SelectionValue,
    StepName,
    StepSavePayload,
    TableColumnsState,
    TableMode,
    TableState,
    TemplateActionTypes,
    TemplateState,
} from 'state/types';

export const setTemplateDataAction = createAction<TemplateState['template']>(
    TemplateActionTypes.SET_TEMPLATE_DATA
);
export const getTemplateDataAction = createAction<GetTemplatePayload | undefined>(
    TemplateActionTypes.GET_TEMPLATE_DATA
);
export const clearTemplateStateAction = createAction(TemplateActionTypes.CLEAR_STATE);
export const setPageAction = createAction<number>(TemplateActionTypes.CURRENT_PAGE);
export const expandStepAction = createAction<number>(TemplateActionTypes.EXPAND_STEP);
export const disabledStepsAction = createAction<TemplateState['disabled']>(
    TemplateActionTypes.DISABLED_STEPS
);
export const stepChangeAction = createAction<StepName>(TemplateActionTypes.STEP_CHANGE);
export const selectionInfoAction = createAction<TemplateState['selectionInfo']>(
    TemplateActionTypes.SELECTION_INFO
);
export const highlightAction = createAction<TemplateState['highlight']>(
    TemplateActionTypes.HIGHLIGHT_VALUE
);
export const tipAction = createAction<TemplateState['tip']>(TemplateActionTypes.TIP);
export const setSelectionValue = createAction<SelectionValue>(
    TemplateActionTypes.SET_SELECTION_VALUE
);

export const clearValueAction = createAction<number | undefined>(
    TemplateActionTypes.CLEAR_VALUE_ACTION
);

export const headerAndFooterAction = createAction<Partial<HeaderAndFooterState>>(
    TemplateActionTypes.HEADER_AND_FOOTER
);
export const saveHeaderAndFooterAction = createAction<StepSavePayload | undefined>(
    TemplateActionTypes.SAVE_HEADER_AND_FOOTER
);

export const generalFieldsAction = createAction<Partial<GeneralFieldsState>>(
    TemplateActionTypes.GENERAL_FIELDS
);
export const saveGeneralFieldsAction = createAction<StepSavePayload | undefined>(
    TemplateActionTypes.SAVE_GENERAL_FIELDS
);
export const clearGeneralFieldTitleAction = createAction<number>(
    TemplateActionTypes.CLEAR_GENERAL_FIELDS_TITLE
);

export const tableModeAction = createAction<TableMode>(TemplateActionTypes.TABLE_MODE);
export const tableAction = createAction<Partial<TableState>>(TemplateActionTypes.TABLE);
export const saveTableAction = createAction<StepSavePayload | undefined>(
    TemplateActionTypes.SAVE_TABLE
);
export const clearTableSeparatorTitle = createAction(
    TemplateActionTypes.CLEAR_TABLE_SEPARATOR_TITLE
);
export const clearTableSeparatorValue = createAction(
    TemplateActionTypes.CLEAR_TABLE_SEPARATOR_VALUE
);

export const columnsToTemplateAction = createAction<Partial<ColumnsToTemplateState>>(
    TemplateActionTypes.COLUMNS_TO_TEMPLATE
);
export const saveColumnsToTemplateAction = createAction<StepSavePayload | undefined>(
    TemplateActionTypes.SAVE_COLUMNS_TO_TEMPLATE
);
export const setColumnsToTemplateValueAction = createAction<ColumnsToTemplateSetValue>(
    TemplateActionTypes.SET_COLUMNS_TO_TEMPLATE_VALUE
);

export const tableColumnsAction = createAction<Partial<TableColumnsState>>(
    TemplateActionTypes.TABLE_COLUMNS
);
export const saveTableColumnsAction = createAction<StepSavePayload | undefined>(
    TemplateActionTypes.SAVE_TABLE_COLUMNS
);
export const clearTableColumnTitleAction = createAction<number>(
    TemplateActionTypes.CLEAR_TABLE_COLUMN_TITLE
);
export const clearTableColumnContentAction = createAction<number>(
    TemplateActionTypes.CLEAR_TABLE_COLUMN_CONTENT
);

export const saveTemplateAction = createAction(TemplateActionTypes.SAVE_TEMPLATE);
export const saveTemplateCompleteAction = createAction(TemplateActionTypes.SAVE_TEMPLATE_COMPLETE);
export const saveTemplateIncompleteAction = createAction(
    TemplateActionTypes.SAVE_TEMPLATE_INCOMPLETE
);

export const toggleViewAction = createAction<boolean | undefined>(TemplateActionTypes.TOGGLE_VIEW);
export const requiredFieldsFulfilledAction = createAction<boolean>(
    TemplateActionTypes.REQUIRED_FIELDS_FULFILLED
);
export const disableStepChangeAction = createAction<boolean>(
    TemplateActionTypes.DISABLE_STEP_CHANGE
);
