import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { FailedImportData } from './types';
import { ListCelText, ListIconCelText } from 'components/List';
import { DocumentIcon } from 'components/atomic';
import { ErrorQueueListItemEdit } from './ErrorQueueListItemEdit';
import { ErrorQueueStatus } from './ErrorQueueStatus';
import { DocumentsListType } from 'components/GenericDocsList';

export const getColumns = (type: DocumentsListType) => {
    const columns: ColumnsType<FailedImportData> = [
        {
            key: 'id',
            dataIndex: 'id',
            render: (id: number, item) => (
                <ListIconCelText
                    icon={<DocumentIcon />}
                    text={`${item.typeLabel ? item.typeLabel : 'document'}   #${
                        item.documentId ? item.documentId : id
                    }`}
                    subtext={moment(item.createdAt).format('MMM DD, HH:mm')}
                />
            ),
        },
        {
            key: 'templateName',
            dataIndex: 'templateName',
            render: (templateName: string) =>
                templateName && <ListCelText text={templateName} subtext="Organization" />,
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            render: (amount: string) => amount && <ListCelText text={amount} subtext="Amount" />,
        },
        {
            key: 'failStatus',
            render: (value, data) => <ErrorQueueStatus data={data} />,
        },
    ];

    if (type !== 'delete') {
        columns.push({
            key: 'edit',
            render: (value, data) => {
                return <ErrorQueueListItemEdit data={data} />;
            },
        });
    }

    return columns;
};
