import classNames from 'classnames';
import { ErrorIcon, FileIcon } from 'components/atomic';

export const UploadContent = ({ fileName, error }: Props) => {
    const containErClassName = classNames(
        'translations-upload-content',
        error && 'translations-upload-error'
    );

    let displayText = (
        <span>
            <span className="translations-upload-text-link">Upload file</span> or drag and drop
        </span>
    );

    if (fileName) {
        displayText = <span>{fileName}</span>;
    }

    if (error) {
        displayText = <span>Upload failed</span>;
    }

    return (
        <div className={containErClassName}>
            <div className="translations-upload-icon">{error ? <ErrorIcon /> : <FileIcon />}</div>
            <p className="translations-upload-text">{displayText}</p>
        </div>
    );
};

interface Props {
    fileName?: string;
    error: boolean;
}
