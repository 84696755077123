import { useState } from 'react';

import { ListActions } from 'components/List';
import { useDrawer } from 'components/DrawerContainer';
import { DeleteAddressTableModal } from './DeleteAddressTableModal';
import { AddressTableForm } from './AddressTableForm';
import { AddressTableData } from './types';

export const AddressTableListItemEdit = ({ data }: Props) => {
    const { openDrawer } = useDrawer();

    const [open, setOpen] = useState(false);

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleEditClick = () => {
        openDrawer('Edit Address', <AddressTableForm data={data} />);
    };

    return (
        <>
            <ListActions onDeleteClick={handleDeleteClick} onEditClick={handleEditClick} />
            <DeleteAddressTableModal open={open} setOpen={setOpen} id={data.id} />
        </>
    );
};

interface Props {
    data: AddressTableData;
}
