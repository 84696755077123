import { createListenerMiddleware } from '@reduxjs/toolkit';
import { importDocuments } from 'services';

import {
    documentLoadingAction,
    documentsImportAction,
    exportResultAction,
    setCurrentDocumentIdAction,
    setDocumentCurrentPageAction,
} from 'state/actions';
import { RootState } from 'state/store.types';

export const documentMiddleWare = createListenerMiddleware<RootState>();

documentMiddleWare.startListening({
    actionCreator: documentsImportAction,
    effect: async (action, { dispatch, getState }) => {
        dispatch(documentLoadingAction(true));

        const { document } = getState();

        if (document.currentId !== null) {
            const result = await importDocuments(document.currentId);
            dispatch(exportResultAction(result));
            dispatch(documentLoadingAction(false));
        }
    },
});

documentMiddleWare.startListening({
    actionCreator: setCurrentDocumentIdAction,
    effect: async (action, { dispatch }) => {
        dispatch(setDocumentCurrentPageAction(0));
    },
});
