import { useEffect, useState } from 'react';
import { addListener } from '@reduxjs/toolkit';

import { api, apiRoutes } from 'services';
import { TemplateData } from './types';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getListenerType, useData } from 'hooks/data.hooks';
import { highlightAction } from 'state/actions';
import { HighlightValue } from 'state/types';

export const useTemplateData = (id: number) => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<Blob | null>(null);
    const [fileLoading, setFileLoading] = useState(true);
    const path = getPath(id);

    const { data: template, loading: templateLoading } = useData<TemplateData>(path);

    const fetchData = () => {
        api.getFile(`${apiRoutes.templatesPdf}/${id}`).then(data => {
            setFile(data);
            setFileLoading(false);
        });
    };

    useEffect(() => {
        fetchData();

        return dispatch(
            addListener({
                type: getListenerType(path),
                effect: () => {
                    fetchData();
                },
            })
        ) as unknown as () => void;
    }, [id]);

    return {
        file,
        template,
        loading: templateLoading || fileLoading,
    };
};

export const useTemplate = () => {
    const dispatch = useAppDispatch();
    const template = useAppSelector(state => state.template);

    const setHighlight = (value: HighlightValue | null) => {
        dispatch(highlightAction(value));
    };

    return { dispatch, setHighlight, ...template };
};

export const getPath = (id: number) => {
    return `${apiRoutes.templates}/${id}`;
};
