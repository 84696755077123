import classNames from 'classnames';

export const StatusBadge = ({ children, type, className, ...props }: Props) => {
    const statusClassName = classNames('status-badge', `status-badge-${type}`, className);
    return (
        <div className={statusClassName} {...props}>
            {children}
        </div>
    );
};

interface Props extends React.DOMAttributes<HTMLDivElement>, WithClassName {
    type: StatusBadgeType;
}

export type StatusBadgeType = 'active' | 'not_active' | 'terminated';
