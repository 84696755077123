import { SaveIcon } from 'components/atomic';

export const SaveCompleteNotification = () => {
    return (
        <div className="save-template-complete">
            <div className="save-template-icon">
                <SaveIcon />
            </div>
            <div className="save-template-title">Template saved successfully!</div>
        </div>
    );
};
