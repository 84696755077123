import { getHighlightBox } from '../helpers';
import { SelectionTypeProps } from '../types';
import { useTemplate } from '../../useTemplate.hook';
import { SelectionArea } from '../SelectionArea';
import { useBoxes } from '../useBoxes.hook';

export const Highlight = ({ rect, scale }: SelectionTypeProps) => {
    const { template, highlight, currentPage } = useTemplate();
    const boxes = useBoxes(template, currentPage, rect, scale);

    if (!highlight || !highlight.type) {
        return null;
    }

    if (['header', 'footer', 'area'].includes(highlight.type)) {
        return <SelectionArea {...getHighlightBox(highlight, rect, scale)} />;
    }

    const boxToHighlight = boxes.filter(box => highlight?.value?.includes(box.value.id));

    return (
        <>
            {boxToHighlight.map((box, key) => (
                <SelectionArea key={key} {...box.scaleBox} />
            ))}
        </>
    );
};
