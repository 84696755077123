import moment from 'moment';

import { routes } from 'navigator';
import { NotificationType } from 'state/types';

export const ImportNotification = ({ date, id, type, isTypeMatchError, onNavigate }: Props) => {
    let href = isTypeMatchError
        ? `/#${routes.errorQueue}/?id=${id}`
        : `/#${routes.documents}/${id}`;

    return (
        <div className="import-notification">
            {moment(date).format('MMM DD, HH:mm')}
            <a className="link-to-import" href={href} onClick={onNavigate}>
                {type === 'error' ? 'Check and fix' : 'View'}
            </a>
        </div>
    );
};

interface Props {
    date: string;
    id: number;
    type: NotificationType;
    isTypeMatchError: boolean;
    onNavigate: () => void;
}
