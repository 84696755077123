import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { documentsImportAction } from 'state/actions';

export const CompleteImportButton = () => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.document);

    const handleClick = () => {
        dispatch(documentsImportAction());
    };

    return (
        <Button type="primary" onClick={handleClick} disabled={loading} loading={loading}>
            Complete Import
        </Button>
    );
};
