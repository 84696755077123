import { useEffect, useState } from 'react';
import { Drawer } from 'antd';

import { DrawerContainerProvider } from './context';
import { useAuth } from 'hooks/user.hooks';

export const DrawerContainer = ({ children }: WithChildren) => {
    const isAuth = useAuth();
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<DrawerContent>({ title: '', children: null });

    useEffect(() => {
        if (!isAuth) {
            setOpen(false);
        }
    }, [isAuth]);

    const openDrawer = (title: string, node: React.ReactNode) => {
        setContent({ title, children: node });
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <DrawerContainerProvider value={{ openDrawer, closeDrawer }}>
            {children}
            <Drawer
                destroyOnClose={true}
                placement="right"
                onClose={closeDrawer}
                open={open}
                width={460}
                title={content.title}>
                {content.children}
            </Drawer>
        </DrawerContainerProvider>
    );
};

interface DrawerContent {
    title: string;
    children: React.ReactNode;
}
