import { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';

import { PageContext, usePageContext } from 'components/Page';
import { useData } from 'hooks/data.hooks';
import { ApiParams } from 'services';
import { convertFilterOptionToRange } from './DateFilter';
import { useAppSelector } from 'hooks/redux.hooks';

export const usePageData = <T>(path: string, resetSearch = false) => {
    const context = usePageContext();
    const searchQuery = useAppSelector(state => state.search.searchQuery);
    const [reset, setReset] = useState(resetSearch);
    const { itemsAmount, dataPage, dateRange } = context;
    const params = useMemo(
        () => getParams(context, searchQuery, reset),
        [itemsAmount, dataPage, dateRange, searchQuery, reset]
    );

    useEffect(() => {
        setReset(false);
    }, []);

    const req = path + '?' + params;

    return useData<T>(req);
};

export const getParams = (
    { itemsAmount, dataPage, dateRange }: PageContext,
    searchQuery: string,
    reset: boolean
) => {
    const range = convertFilterOptionToRange(dateRange);

    const query: ApiParams = {
        page: dataPage - 1,
        size: itemsAmount,
        ...range,
    };

    if (!reset && searchQuery) {
        query.searchQuery = searchQuery;
    }

    return queryString.stringify(query, { encode: false });
};
