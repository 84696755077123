import { headerAndFooterAction, stepChangeAction } from 'state/actions';
import { StepBase } from 'state/types';

export const stepBase: StepBase = {
    stepName: 'headerAndFooter',
    action: headerAndFooterAction,

    handleStepChange(dispatch, items) {
        dispatch(stepChangeAction(this.stepName));
        dispatch(this.action(this.nextItemActive(items)));
    },

    nextItemActive(items) {
        return { active: this.nextItemIndex(items) };
    },

    nextItemIndex(items) {
        let next = 0;

        items.some(item => {
            if (this.validateItem(item)) {
                next++;
                return false;
            }
            return true;
        });

        if (next >= items.length) {
            return null;
        }

        return next;
    },

    validateItem(item) {
        return item.value !== null && typeof item.value !== 'undefined';
    },
    validate(items) {
        return items.every(item => this.validateItem(item));
    },
    itemsCopy(items) {
        return items.map(item => ({ ...item }));
    },
    getExtractedFieldsData(template, isTable) {
        return template.fields
            .filter(field => !!field.isTable === isTable)
            .map((field, id) => {
                const extracted = template.extractedFields.find(item => item.id === field.fieldId);
                const mapping = extracted?.extractionMapping;
                const contentIds = mapping ? mapping.content.map(item => item.index) : [];
                const titleId =
                    typeof mapping?.title?.index === 'number' ? mapping?.title?.index : null;
                const value = extracted?.content ?? '';

                return {
                    propertyName: field.propertyName,
                    required: field.required,
                    pageNum: extracted?.extractionMapping?.title?.pageNum || 0,
                    label: field.label,
                    value,
                    contentIds,
                    titleId,
                    fieldId: field.fieldId,
                    title: field.label,
                    id,
                };
            });
    },
};
