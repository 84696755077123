import { useState } from 'react';

import { Container } from 'components/atomic';
import { LogoImage } from 'components/Logo/LogoImage';
import { LoginForm } from './LoginForm';
import { LoginError } from './LoginError';

export const LoginContainer = () => {
    const [error, setError] = useState(false);

    return (
        <Container className="login-container">
            <LogoImage size="big" className="login-container-logo" />
            <h1>Welcome back!</h1>
            <LoginForm setError={setError} />

            {/* Divider className="login-divider" />
            <Button
                className="login-google-button"
                type="default"
                icon={<div className="login-google-button-icon" />}>
                Log in with Google
            </Button>
            <div className="login-sign-up">
                Don't have an account? <Link to={routes.singup}>Sign Up</Link>
            </div> */}

            <LoginError error={error} />
        </Container>
    );
};
