import { usePageData } from 'components/Page';
import { apiRoutes } from 'services';
import { TemplatesAllDataResponse } from './types';
import { GenericDocsList, GenericDocsListProps } from 'components/GenericDocsList';
import { TemplateByTypeData } from '../DocumentTemplates/types';
import { getColumns } from '../getColumns';

export const TemplatesList = () => {
    const { data, loading } = usePageData<TemplatesAllDataResponse>(apiRoutes.templatesAll);

    const listProps: GenericDocsListProps<TemplateByTypeData> = {
        columns: getColumns(),
        data,
        loading,
        title: `Templates ${data ? `(${data.total})` : ''}`,
    };

    return <GenericDocsList {...listProps} />;
};
