import { TipIcon } from 'components/atomic';
import { useTemplate } from './useTemplate.hook';
import { CloseIcon } from 'components/atomic';
import { useState } from 'react';

export const Tip = () => {
    const { tip } = useTemplate();
    const [showTip, setShowTip] = useState(true);

    if (!tip) {
        return null;
    }

    return showTip ? (
        <div className="template-tip">
            <div className="template-tip-close" onClick={() => setShowTip(false)}>
                <CloseIcon />
            </div>
            <div className="template-tip-icon">
                <TipIcon />
            </div>
            <div className="template-tip-text">{parseText(tip)}</div>
        </div>
    ) : (
        <div />
    );
};

const parseText = (text: string) => {
    return text.split('**').map((part, index) => {
        if (index % 2 === 0) {
            return <span key={index}>{part}</span>;
        }

        return <b key={index}>{part}</b>;
    });
};
