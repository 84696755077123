import { useAppDispatch } from 'hooks/redux.hooks';
import { setDocumentCurrentPageAction, setDocumentHighlightAction } from 'state/actions';

export const useDocumentHighlight = (
    value: number[] | null,
    pageNum?: number
): Pick<React.DOMAttributes<HTMLDivElement>, 'onMouseOver' | 'onMouseOut'> => {
    const dispatch = useAppDispatch();

    return {
        onMouseOver: () => {
            dispatch(setDocumentHighlightAction(value));
            if (typeof pageNum === 'number') {
                dispatch(setDocumentCurrentPageAction(pageNum));
            }
        },
        onMouseOut: () => {
            dispatch(setDocumentHighlightAction(null));
        },
    };
};
