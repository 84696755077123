export const emails = Object.freeze({
    upgradeAccount: {
        subject: 'Upgrade Request: Reaching Document Parsing Limit 🚀',
        body: `Hey DOConvert Team,\n\n
Hope you're doing well! 🌟 We've hit the limit of our document parsing capacity here at [Company Name], and it's time to unlock the next level.\n\n
Could you please open up the next plan for us? We're excited to continue using DOConvert for our document processing needs.\n\n
Looking forward to your prompt response.\n\n
Best regards,\n
[Admins Name] from [Company Name]`,
    },
    upgradePlanForUsers: {
        subject: 'Upgrade Request: Reaching User Limit 🚀',
        body: `Hey DOConvert Team,\n\n
I hope you're doing well! 🌟 It looks like we've hit the user limit within our current plan, and we're ready to expand our team's access.\n\n
We're excited to provide more of our team members with access to DOConvert, and we appreciate your assistance in making this transition smooth.\n\n
Thank you for your support, and we look forward to your prompt response.\n\n
Best regards,\n
[User's Name] from [Company Name]`,
    },
    toBoss: {
        subject: 'Monthly Update for [Client Name]: Impressive Results at DOConvert 📈',
        body: `Hi [Boss's Name],\n\n
I hope you're well. Here's a quick snapshot of our achievements this month for [Client Name] at DOConvert:\n\n
📂 Documents Imported: [Number of Documents]\n
📄 Pages Imported: [Number of Pages]\n
⏱️ Time Saved: [Total Time Saved] hours\n
💰 Money Saved: $[Total Money Saved]\n
Our team's efforts continue to deliver outstanding results for [Client Name]. Let's keep up the great work!\n\n
If you'd like more details or have any questions, feel free to reach out.\n\n
Best regards,\n
[Admins Name] from DOConvert`,
    },
});

export interface EmailContent {
    subject: string;
    body: string;
}
