import { FileView } from './FileView/FileView';
import { Preview } from './Preview/Preview';
import { useTemplate } from './useTemplate.hook';

export const TemplateView = ({ file }: Props) => {
    const { fileView } = useTemplate();

    return fileView ? <FileView file={file} /> : <Preview />;
};

interface Props {
    file: Blob;
}
