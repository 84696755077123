export const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    licensePollingInterval: 60 * 60 * 1000,
    refreshSessionInterval: 30 * 60 * 1000,
    accountKey: 'default',
    infoEmail: 'info@doconvert.co',
    version: '2.0',
};

export default config;
